const img_thumb =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2Fthumb_digital_mayer_seoul.png?alt=media&token=e2d44e9f-d066-4984-9712-8a640276f207";
const img_thumb_square =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2Fthumb_digital_mayer_seoul_square.png?alt=media&token=23f925e8-29f4-4321-a753-df5c0dfa2305";
const img_title =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2Ftitle.png?alt=media&token=a8d1d96b-4967-4dc9-8275-894868bf9e67";
const img_1_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F1-1.png?alt=media&token=c913ecc5-652c-4af8-bbe0-c2eb2dbee92f";
const img_2_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F2-1.png?alt=media&token=3ac4dd14-0635-4bba-8f48-ff3153058fbb";
const img_2_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F2-2.png?alt=media&token=e9ca5293-bcef-4e01-b397-f81cf1aebc54";
const img_2_3 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F2-3.png?alt=media&token=df7dfadf-2f75-4b2a-9d35-90cea144a032";
const img_2_4 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F2-4.png?alt=media&token=a9c739fd-9d47-4732-9510-15d027866d73";
const img_3_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F3-1.jpg?alt=media&token=75ce45cd-a26e-4747-b9ba-95edb469ffe6";
const img_3_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F3-2.png?alt=media&token=da5fb7a4-91db-497b-9f09-7dc44c0d5381";
const img_3_3 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F3-3.png?alt=media&token=0f086131-c099-4df3-98fd-d257e2463c76";
const img_3_4 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F3-4.png?alt=media&token=f90b32ed-ed67-4c0a-bb4f-e91f7c6b99c6";
const img_3_5 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F3-5.png?alt=media&token=89be1659-41f1-4561-96e5-497dfd9c2825";
const img_4_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fdigital-mayor%2F4-1.png?alt=media&token=92e23b5c-0b37-4347-b3b4-d06367292a43";

export const data_digital_mayor = {
	isCompleted: true,
	id: "digital-mayor",
	summary: {
		order: 3,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: "Redesign Seoul Digital Mayor",
		tags: ["Dashboard", "Data Visualization", "Design System"],
		color: "#0b1015",
		industry: ["공공서비스"],
		outcomes: ["대시보드 웹사이트 디자인"],
		tasks: ["컨설팅", "UX.UI 디자인"],
		titleImg: img_title,
		summaryTitle: `지속적인 공공 웹 서비스가 갖춰야할 조건은 무엇인가?`,
		summaryExp: `공공기관에서 IT 서비스를 운영한다는 것은 시장에서 B2C 서비스가 성공하기 위한 여정과는 또 다른 어려움에 직면하게 됩니다. 프로젝트의 계약부터 개발 싸이클이 훨씬 길뿐만 아니라 실제 사용자들의 반응을 얻기 전에 수많은 중간 이해 관계자들의 피드백들을 더 많이 받을 수 밖에 없는 환경이기 때문입니다.
		
		이러한 공공 서비스 프로젝트의 특성은 종종 사용자 경험의 일관성 및 누적된 개발 레거시로 유지보수 비용이 높아지는 결과로 이어지곤합니다. 서울시에서 운영하는 ‘디지털 시장실’ 또한 유사한 문제를 겪고 있었으며 이를 개선하기 위한 컨설팅 및 디자인 시안작업을 진행하였습니다. 아쉽게도 내부 사정으로 최종 시안은 제품화 되지 않았지만 이번 프로젝트를 통해 공공 IT 서비스가 지속성을 갖추기 위한 조건은 무엇인지 진지하게 생각해 보는 계기가 되었으며 이를 자세히 소개해보려합니다.`,
	},
	sectionList: [
		{
			title: `서비스 레거시 및 시정 지표 확인`,
			contentList: [
				{
					type: "text",
					content: `기존 디지털 시장실을 리뷰하고 분석한 문제점은 크게 다음 3가지로 압축됩니다.
					1. 네비게이션 계층의 혼란을 주는 UI (더 큰 단위의 정보 이동을 위한 UI가 종속된 UI처럼 보이는 영역)
					2. 가독성이 떨어지고 트렌드가 지난 스피어모피즘 아이소 타입 (반사되는 대형 디스플레이 경우에 더 부각됨)
					3. 동일한 성격의 데이터에 적용된 상이한 시각화 방식 (시스템의 시각적 통일성 저해와 유저의 인지부하)
					4. 사용자 맥락과 연관성이 떨어지는 지표 카테고리
					5. 동일한 패턴에 대한 UI와 데이터 시각화 모듈화 부족`,
				},
				{
					type: "img",
					src: img_1_1,
					caption: "기존 버전의 디지털 시장실. 메인 화면에 geoJson 기반으로 렌더링된 맵이 아닌 직접 일러스트로 제작한 맵을 사용하고 있다.",
				},
				{
					type: "text",
					content: "1,2 번까지는 서비스의 내용을 잘 알지 못해서 전문가의 도움을 받으면 바로 개선될 수 있는 항목들이라면 3번 부터는 시정 활동에 서비스가 사용되는 시나리오와 30개가 넘는 지표들의 중요도 및 성격을 파악해야하는 항목들이었습니다.  패터화된 사용자 시나리오와 지표들을 쉽고 효과적으로 전달하기 위한 첫번째 작업으로 내부 인터뷰를 진행한 결과 시민들이 관심있어할 만한 8개의 지표 카테고리(환경,여론,안전, 활성 등)와 6개의 시각화 유형을 도출하였습니다."
				}
			],
		},
		{
			title: `디자인 시스템 구성: Carbon Design System + Map Box`,
			contentList: [
				{
					type: "text",
					content: `위의 리뷰 내용을 바탕으로 본격적으로 UX.UI를 구성하기 앞서 통일된 UI와 가이드를 정립하기 위해 IBM의 carbon design system(이하 CDS)을 새 시안의 디자인 시스템으로 선택하였습니다. CDS는 IBM에서 제공하는 디자인 시스템으로 복잡도가 높은 대시보드 형태의 웹 프로젝트에 최적화된 UI로 구성되어있어 디지털 시장실에 적합하다고 판단하였습니다.  추가로 각 시정 지표에 대한 아이콘들과 디지털 시장실에 더 최적화된 custom UI들을  CDS의 color scheme과 그리드 가이드라인에 기반하여 작업하였습니다. `,
				},
				{
					type: "img",
					src: img_2_1,
					caption: "Carbon Design System의 많은 요소들은 dashbaord 형태의 saas 제품에 최적화되었습니다. 컴퍼넌트들의 정렬, 그리드 룰 등이 시스템의 복잡도가 높아져도 구성 요소간에 조화도를 유지하도록 설계되었습니다.",
				},
				{
					type: "img",
					src: img_2_2,
					caption: "각 시정 지표들의 카테고리를 사용자 친화적으로 재분류, carbon design system의 color scheme을 기반으로 리디자인.",
				},
				{
					type: "img",
					src: img_2_3,
					caption: "CDS를 override한 custom UI 컴퍼넌트 및 UI 템플렛.",
				},
				{
					type: "img",
					src: img_2_4,
					caption: "MapBox의 studio를 통해 맵의 스타일링뿐 아니라 각 지표가 매핑된 geojson파일을 기반으로 다양한 시각화의 프로토타이핑을 테스트해볼 수 있다.",
				},
			],
		},
		{
			title: `지표 성격에 따른 시각화 모듈화, UX 패턴에 맞춘 템플렛 구성`,
			contentList: [
				{
					type: "text",
					content: `이전 버전에서 가장 아쉬웠던 것은 동일한 성격의 지표들이 조금씩 다른 스타일의 시각화로 적용되있었다는 점입니다.  새 버전에서는 기존 지표들의 유형을 분류하여 사용할 시각화의 타입을 Choropleth, bubble map, 라인 그래프, 트리맵으로 제한 시켰고, 범례에 의한 색상 코딩을 제외한 모드 동일한 스타일의 가이드를 정립하였습니다.`,
				},
				{
					type: "img",
					src: img_3_1,
					caption: "각 지표들에 가장 적합한 시각화 방식에 따라 크게 4개 유형의 시각화를 모듈화 하였다.",
				},
				{
					type: "text",
					content: `사용자가 데이터를 열람하는 패턴에 따라서도 레이아웃 템플렛과 card UI 컴퍼넌트들을 모듈화하였는데 이때 중점사항은 새로운 지표가 추가되거나 변경이 되어도 시스템 UI에 큰 영향을 주지 않는 방향으로 구성했다는 점입니다. 예를들면 과거 코로나의 사례처럼 사회적으로 이슈가 되는 현상의 지표들을 빠르게 추가해야하는 상황이 되더라도 기존의 시각화 패턴과 UI템플렛과의 조합으로 바로 적용될 수 있는 환경을 구축하는 것입니다.`,
				},
				{
					type: "img",
					src: img_3_2,
					caption: "지역구별 비교 수치를 시각화할때 효과 적인 Choropleth",
				},
				{
					type: "img",
					src: img_3_3,
					caption: "지역구별 다른 스케일의 동일 지표를 확인할때 효과적인 데이터 테이블",
				},
				{
					type: "img",
					src: img_3_4,
					caption: "Choropleth의 시간별 변화를 관찰할때 효과적인 분할 layout",
				},
				{
					type: "img",
					src: img_3_5,
					caption: "동일 지표의 다수 카테고리를 확인할때 효과적인 three map",
				},
			],
		},
		{
			title: "반응형 적용",
			contentList: [
				{
					type: "img",
					src: img_4_1,
					caption: "스크롤 인터랙션으로 트리거 되는 각 챕터의 메인 시각화 차트",
				},
			],
		},
	],
};
