import { PathTypeUnitDouble } from "../../../components/logoType/unit/PathUnitDouble";
import { topCirclePath, bottomCirclePath } from "../../../data/visualLangaugePathData";
import colors from "../../../designTokens/colors";

export const DoubleCirclePattern = ({delay1, delay2, scale}) => {
	return (
		<PathTypeUnitDouble
			data1={topCirclePath}
			data2={bottomCirclePath}
			width={360}
			height={576}
			strokeWidth={24}
      delay1={delay1}
      delay2={delay2}
      strokeColor={colors.gray[1]}
			scale={scale}
		/>
	);
};
