import styled from "styled-components";
import { V, I, O2, S, N } from "../../logoType/unit/UnitSet";
import { Flex } from "@mantine/core";

const LetterSpace = styled.div`
	width: ${(props) => props.space};
`;

export const Vision = ({
	startDelay = 1000,
	delay = 200,
	scale = 1 / 3,
	isReverse = false,
}) => {
	return (
		<Flex gap={4}>
			<V
				delay={0}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
			<I
				delay={delay * 1}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
			<S
				delay={delay * 2}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
			<I
				delay={delay * 3}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
			<O2
				delay={delay * 4}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
			<N
				delay={delay * 5}
				startDelay={startDelay}
				scale={scale}
				isReverse={isReverse}
			/>
		</Flex>
	);
};
