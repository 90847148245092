const img_thumb = `https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fthumb_plastic_literacy.png?alt=media&token=9dd9e76f-a4d7-4bc5-a36a-1b66c08f6c6d`;
const img_thumb_square = `https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fthumb_plastic_literacy_square.png?alt=media&token=828a9462-f351-4acd-853e-1e95f98e406b`;
const img_title =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_title.jpg?alt=media&token=b334de3b-42f9-4953-9b62-32990a81f5df";
const img1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_1_1.png?alt=media&token=085065db-8c43-4689-afae-0ab67e5072c3";
const img2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_2_1.jpg?alt=media&token=6c3f82bf-01b2-41e6-ab18-a1af5daf2f4d";
const img3 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_3_1.png?alt=media&token=2580fab9-2c75-411b-996d-67624044f68f";
const img4 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_3_2.jpg?alt=media&token=bfca0d75-c402-4593-b26b-8258f821e295";
const img5 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fgs_4_1.jpg?alt=media&token=8eba841f-f0cf-47d4-acdb-632cbd995b26";
const img6 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fc1.gif?alt=media&token=4559e4db-19fc-486d-a217-342b40bfd03a";
const img7 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fc2.gif?alt=media&token=a27fa724-1401-4818-ac64-efb16e886a58";
const img8 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgs%20caltext%20plastic%20literacy%2Fc3.gif?alt=media&token=87f1182b-968b-4134-b844-875a3d842c5e";

export const data_plastic_literacy = {
	isCompleted: true,
	id: "gs-caltext-plastic-literacy",
	summary: {
		color: "#0f1e2d",
		order: 4,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: `Plastic Literacy\nGS Caltex`,
		tags: ["MVP Project", "Research", "Design System"],
		industry: ["에너지 & 화확"],
		outcomes: ["인터랙티브웹"],
		tasks: ["리서치", "데이터 시각화", "front-end 개발"],
		titleImg: img_title,
		summaryTitle: `플라스틱을 향한 대중의 생각을 전환하기 위한 브랜드 스토리텔링`,
		summaryExp: `ESG에서 비교적 단기간 안에 가시적으로 대중들에게 강조할 수 있는 부분은 아마 친환경 이미지를 구축하기 위한 기술적.경영적 시도일 것입니다. 하지만 규제 혹은 친환경 소재로 대체하는 대부분의 방식은 실효성 측면과 기존 산업 의존도와 충돌하는 또다른 사회적 문제를 야기하기도 합니다. 대표적인 사례가 현재 전세계 누적 폐기물중 가장 많은 비중을 차지하고 있는  플라스틱 폐기물 문제입니다. 친환경 소재를 사용하자는 움직임은 이미 플라스틱의 산업 의존도가 너무 높아진 상황에서 그리 현실적으로 보이지 않습니다.

		GS caltex는 이러한 사회 필요악인 ‘플라스틱’에 대해 가장 잘 이해하고 있는 글로벌 기업중 하나일 것입니다. 오랫동안 축적한 플라스틱 생산기술을 기반으로 기존의 플라스틱 리싸이클 방식이 갖고 있었던 한계점을 극복하고있습니다. 이번 인터랙티브 웹 프로젝트를 통해 GS칼텍스의 리싸이클 기술을 기반으로 플라스틱을 이 사회가 어떻게 바라보고 이해해야하는 지에 대한 스토리를 전달하려 합니다.  
		
		*해당 프로젝트는 GS caltex에서 발주하였고 PR 컨설팅 회사인 ‘에스코토스'의 매니징을 통해 그래픽 스튜디오 ‘취그라프'와 함께 작업하였습니다.`,
		link: `https://www.gazilab.co/diagnosis`,
	},
	sectionList: [
		{
			title: `플라스틱 리터러시`,
			contentList: [
				{
					type: "text",
					content: `플라스틱은 오랫동안 무분별한 소비의 대상으로 여겨져 왔지만 이제는 사회 전체가 정말 플라스틱을 잘 이해하고 어떻게 다루어야 하는지에 대한 깊은 이해가 필요한 시대가 왔습니다. 우리는 지속가능한 환경을 만들기 위해 플라스틱을 이해하고 활용하는 능력을 ‘Plastic Literacy’라고 부르기로 했으며 여기에는 비단 기업, 정부와 같은 큰 조직뿐 아니라 개인의 이해와 노력도 많이 필요합니다.

						제조회사 입장에서 환경 측면의 ESG를 지나치게 강조하는 것은 부담이 되는일일 것입니다. 하지만 자체 리싸이클 기술력으로  생산 - 폐기 과정의 끝을 이은 순환 구조를 지속할 수 있다면 조금 얘기가 달라질 것입니다. 단순히 GS caltex의 기술력을 홍보하는 것이 아닌 플라스틱 활용에 대한 새로운 대안을 제시하는 스토리를 web이라는 매체를 통해 어떻게 효과적으로 전달할지라는 질문과 함께 프로젝트가 시작되었습니다.`,
				},
				{
					type: "img",
					src: img1,
					caption: "플라스틱 재활용 프로세스",
				},
			],
		},
		{
			title: `리서치 및 스토리 구조 잡기`,
			contentList: [
				{
					type: "text",
					content: `데스크 리서치를 통해서 얻은 포인트는 크게 3가지가 있습니다. 첫번째는 플라스틱의 산업 의존도 및 생산샹 증가의 탄력을 더이상 제어하기 힘든 단계에 도달했다는 점, 두번째는 플라스틱의 순환 여정에서 의외로 배출, 수거 과정에서 재활용률에 큰 영향을 미친다는 점, 마지막으로 기존 리싸이클 기술력의 한계점이 재활용율 및 재활용 플라스틱의 부가가치를 제한시킨다는 점입니다. 각 포인트를 임팩트 있게 설명하기 위해 다음 3개의 메인 챕터로 플라스틱의 대체 불가능한 상황과 순환 과정에서의 해결해야할 점들 그리고 GS caltex가 이를 위해 접근하는 노력들을 전달하기로 했습니다.  다양한 형태의 리서치가 있지만 제가 생각하는 리서치의 궁극적인 목적은 자기만의 관점을 수립하는 것입니다. 가지랩의 경우 웰니스라는 개념과 사람들의 건강을 어떤 관점에서 바라볼 것인가가를 스스로 정립해야 했습니다. 이에따라, 내부 헬스코칭 전문가들과 의논끝에 참가자들의 건강에 미치는 영향을 파악하기 위해 '나'에 대한 자기주도성, '환경'의 사회적, 물리적 측면, 그리고 올바른 '지식' 보유 여부를 중점으로 리서치를 설계하였습니다.`,
				},
				{
					type: "img",
					src: img2,
					caption: "스토리를 구성하는 3개의 메인 챕터",
				},
			],
		},
		{
			title: `스토리 텔링과 디자인 컨셉`,
			contentList: [
				{
					type: "text",
					content: `스토리 텔링에서 가장 중점을 두었던 부분은 일반인이 이해하기 어려움이 있는 플라스틱 처리 과정 및 재활용 기술에대한 용어를 쉽고 친근하게 설명하는 것이었습니다. 앞선 단계에서 구성한 스토리 구조에 필요한 개념과 용어들을 모두 나열해보고 유사한 개념들을 위주로 시각적인 계층을 만들어 보았습니다. 그리고 이 시각 요소들의 톤앤 매너는 GS 칼텍스의 서브 브랜드인 에너지 플러스의 가이드 라인을 기반하여 친환경적이고 대중 친화적인 느낌을 주었습니다.`,
				},
				{
					type: "img",
					src: img3,
					caption: "에너지 플러스 브랜드 가이드",
				},
				{
					type: "img",
					src: img4,
					caption: "에너지 플러스 브랜드 가이드를 확장한 아이소 타입",
				},
			],
		},
		{
			title: "프로토타이핑",
			contentList: [
				{
					type: "text",
					content: `인터랙티브 웹 환경에서 가장 많이 사용되는 스토리 텔링의 트리거는 스크롤입니다. 이는 사용자가 아무 생각없이 가장 간편하게 작동 시킬 수 있다는 장점도 있지만 스토리를 이해함에 있어 독자들을 자연스럽게 인과관계가 있는 컨텐츠들을 순차적으로 보게하는 역할도합니다.

					컨텐츠의 난이도를 고려했을때 최소한의 밀도의 정보를 몰입감있게 보여주기 위해 모든 섹션의 레이아웃과 트랜지션을 full screen 베이스로 구성하였고 각 챕터의 포인트를 가장 잘 전달할 수 있는 대표 시각화 차트를 스크롤 인터랙션으로 데이터를 업데이트 방식의 스토리텔링을 테스팅.구현 하였습니다.`,
				},
				{
					type: "img",
					src: img5,
					caption: "스크롤 인터랙션으로 트리거 되는 각 챕터의 메인 시각화 차트",
				},
			],
		},
		{
			title: "최종 디자인 및 구현",
			contentList: [
				{
					type: "img",
					src: img6,
					caption: "챕터1: 플라스틱 딜레마",
				},
				{
					type: "img",
					src: img7,
					caption: "챕터2: 플라스틱의 여정",
				},
				{
					type: "img",
					src: img8,
					caption: "챕터2: 플라스틱의 순환",
				},
			],
		},
	],
};
