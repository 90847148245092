import {
	bulbBodyPath,
	bulbBottomPath,
	bulbLight1,
	bulbLight2,
	bulbLight3,
	bulbLight4,
	bulbLight5,
} from "../../../data/visualLangaugePathData";
import colors from "../../../designTokens/colors";
import { PathUnitMultiple } from "../../../components/logoType/unit/PathUnitMultiple";

export const IdeaBulb = ({scale=2, strokeColor=colors.blue[5]}) => {
	const pathDataList = [
		bulbBodyPath,
		bulbBottomPath,
		bulbLight1,
		bulbLight2,
		bulbLight3,
		bulbLight4,
		bulbLight5,
	];
	return (
		<PathUnitMultiple
			dataList={pathDataList}
			width={156}
			height={156}
			strokeWidth={8}
			strokeColor={strokeColor}
			delay={150}
			duration={400}
      scale={scale}
		/>
	);
};
