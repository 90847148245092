import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { Accordion, Stack, Title, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ContentStack } from "../../components/layout/stack/ContentStack";
// import { HighlightedP } from "../../components/customTypo/HighlightedP";

export const AboutFnQSection = ({}) => {
	const fnQList = [
		{
			value: "introduction",
			q: `Data Driven Design은 어떤 팀인가요?`,
			a: `DDD는 데이터의 다양한 가치를 디지털 프로덕트에 투영시키는데 전문성이 있는 크리에이티브 에이전시입니다. 데이터 중심의 사고방식은 시장과 유저의 행동을 바라보는 시각을 더욱 구조화 시킬뿐 아니라 제품을 설계하는 과정에서도 중요한 역할을 합니다.

      의사결정 근거로서의 데이터, 사용자 경험의 골격으로서의 데이터 그리고 시각화 대상으로서의 데이터에 대한 깊은 전문성으로 여러분의 비지니스를 지속가능하게 만들어드립니다.`,
		},
		{
			value: "team",
			q: `Data Driven Design은 어떻게 구성되어 있나요?`,
			a: `DDD는 세명의 IT 전문 인력으로 구성된 소수 정예팀입니다. 기존의 IT 팀들과는 조금 다른점은 기획,디자인,개발이라는 전통적인 제품 개발 관점에서 인력을 구분하지 않는점이고 더욱 일관성있는 경험과 각 인력의 장점을 극대화하기 위해 다음과 같은 role로 구성되어있습니다.

      Experience Builder - 리서치, 기획, 디자인 시스템 제작 및 배포 등 사용자 경험에 영향을 미치는 모든 태스크들을 리딩합니다. 리서치 혹은 기획에서 도출된 중점 사항이 오차없이 프로덕트 레벨까지 이어지고 이를 기반으로 제품과 디자인 시스템의 일관성을 극대화 시키며 프로젝트 종료 후에도 클라이언트 팀에서 어떻게 관리할지에 대한 교육 및 가이드 까지의 업무를 담당합니다.
      
      Logic Architect - 개발 단계에서 필요한 모든 로직들의 설계와 구현을 담당합니다. Experience Builder의 산출물인 design system의 규칙안에서 프론트 로직을 구현하며 클라이언트 팀 내부의 인력과 제품 발전 단계를 고려하여 DB 설계 및 api를 구현합니다.
      
      Logic architect의 가장 큰 역할은 사용자 경험에서 발생하는 데이터의 흐름을 가장 효율적으로 관리할 수 있는 방식을 구현함과 동시에 각 로직들을 모듈화하여 스케일러블한 개발 환경을 제공하는데 있습니다.
      
      Data Scientist - 사용자 경험 개선의 단서가될 수 있는 모든 데이터들에 대한 정량 분석 및 인사이트를 제공합니다. 
      `,
		},
		{
			value: "inquiry",
			q: `프로젝트 의뢰는 어떻게 하나요?`,
			a: `가장 간편하게 이메일(contact@dddesign.io)로 주셔도 되고 의뢰와 상관없이 무료 컨설팅을 1회 제공드립니다. (이메일 혹은 zoom으로 진행) 프로젝트의 상세 내용이 구체적으로 나온 경우라면 하단의 ‘상세 가이드와 함께 문의'를 권장드리지만 이 경우도 이메일로 문의주셔도 좋습니다.`,
		},
		{
			value: "consulting",
			q: `무료 컨설팅에는 어떤 내용들을 다루나요?`,
			a: `생각보다 많은 클라이언트 분들이 현 상황에서 정말 필요한 것이 무엇인지 정의하는데 어려움을 겪고 계십니다. 사실 무엇이 필요한지 결론을 내는 것 또한 이미 기획의 일부이기도 하죠. DDD는 고객의 문의 주신 의뢰내용을 그대로 구현하기 위한 플랜을 바로 짜기보다 전후 맥락을 같이 파악해보는 것으로 프로젝트를 시작합니다. 막연한 목표가 있지만 어떤 영역을 어떻게 외부의 리소스를 활용하는 것에 대한 판단이 어려운 경우에 무료 컨설팅을 권장해드리며, 프로젝트 의뢰로 이어지지 않더라도 저희의 경험 내에서 도움을 드릴 수 있는 부분을 성실껏 답변해드립니다.`,
		},
	];
	return (
		<ContentColumn>
			<ContentStack>
				<SectionTitle title={"More Detail About Data Driven Design"} />
				<Accordion defaultValue={fnQList[0].value} style={{borderStyle: 'solid', borderWidth:0.5, borderColor:'black', borderRadius:'8px'}} chevronSize={24} p={{base:8,md:24}} chevron={<IconChevronDown size={24} />}>
					<Stack align="left" gap={0}>
						{fnQList.map((item, i, arr) => (
							<Accordion.Item key={i} value={item.value} style={{borderWidth: i===arr.length - 1 ? 0 : 1}} p={'16 0'}>
								<Accordion.Control>
									<Title order={3}>{item.q}</Title>
								</Accordion.Control>
								<Accordion.Panel pr={{base:16,md:48}}>
									<Text size={"lg"}>{item.a}</Text>
								</Accordion.Panel>
							</Accordion.Item>
						))}
					</Stack>
				</Accordion>
			</ContentStack>
		</ContentColumn>
	);
};
