import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@mantine/core/styles.css";
import { ContainerMain } from "./page/Main/ContainerMain";
import { ContainerProject } from "./page/Project/ContainerProject";
import { ContainerProjectDetail } from "./page/ProjectDetail/ContainerProjectDetail";
import { ContainerAbout } from "./page/About/ContainerAbout";
import { ContainerIdeas } from "./page/Idea/ContainerIdeas";
import { PageProjectUpload } from './page/Admin/PageProjectUpload';
import { PageStoryUpload } from "./page/Admin/PageStoryUpload";
import { PageForVideoCapture } from "./page/ForVideoCapture/PageForVideoCapture";
import { Header } from "./components/navigation/Header";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { AppShell } from "@mantine/core";
import { Stack, Flex, Button } from "@mantine/core";
import { NavLinkGNB } from "./components/navigation/NavLinkGNB";
import colorsVariants from "./designTokens/colorsVariants";
import { IconWorld } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useHeaderState } from "./context/headerState/headerContext";
import { NavBar } from "./components/navigation/NavBar";
import disableScroll from "disable-scroll";
import { SET_IS_HIDE } from "./context/headerState/action";
import { useHeaderDispatch } from "./context/headerState/headerContext";

const ScrollTop = () => {
	const { pathname } = useLocation();
	const headerDispatch = useHeaderDispatch();

	// Scroll & Header Position setting
	useEffect(() => {
		window.scrollTo(0, 0);
		let body = {
			type: SET_IS_HIDE,
			value: false,
		};
		headerDispatch(body);
	}, [pathname]);

	return null;
};

function App() {
	const [opened, { toggle }] = useDisclosure();
	const { themeType } = useHeaderState();

	const toggleNavbar = () => {
		toggle();
		if (opened) {
			disableScroll.off();
		} else {
			disableScroll.on();
		}
	};

	return (
		<BrowserRouter>
			<ScrollTop />
			<AppShell
				header={{ height: 60 }}
				navbar={{
					width: 300,
					breakpoint: "sm",
					collapsed: { mobile: !opened },
				}}
			>
				<AppShell.Header
					style={{
						height: "80px",
						border: "none",
						backgroundColor: "#00000000",
						zIndex: 999,
					}}
				>
					<Header onClickMenu={toggleNavbar} isMenuOpen={opened} />
				</AppShell.Header>
				<AppShell.Navbar
					hiddenFrom="sm"
					p="0"
					style={{
						border: "none",
						top: 0,
						backgroundColor: colorsVariants.datumWhite[0],
						height: "100%",
					}}
				>
					<NavBar onClickLink={toggleNavbar} />
				</AppShell.Navbar>
				<AppShell.Main style={{ padding: 0, margin: 0 }}>
					<Routes>
						<Route index path={"/"} element={<ContainerMain />} />
						<Route path={"/about"} element={<ContainerAbout />} />
						<Route path={"/projects"} element={<ContainerProject />} />
						<Route path={"/project/:id"} element={<ContainerProjectDetail />} />
						<Route path={"/ideas"} element={<ContainerIdeas />} />
						<Route path={"/admin/upload-project"} element={<PageProjectUpload />} />
						<Route path={"/admin/upload-story"} element={<PageStoryUpload />} />
						<Route path={"/video-capture"} element={<PageForVideoCapture />} />
					</Routes>
				</AppShell.Main>
			</AppShell>
		</BrowserRouter>
	);
}

export default App;
