import { Flex, Stack, Button } from "@mantine/core";
import { NavLinkGNB } from "./NavLinkGNB";
import { IconWorld } from "@tabler/icons-react";
import { Title } from "@mantine/core";
import { Display } from "../customTypo/Display";
import { useHeaderState } from "../../context/headerState/headerContext";

export const NavBar = ({onClickLink}) => {
  const {themeType} = useHeaderState();
	return (
		<Flex
			direction={"column"}
			gap={24}
			align={"start"}
			justify={"space-between"}
			style={{ padding: "144px 24px 24px 24px", height: "100%" }}
      bg={themeType==='dark'?'gray.9':'white'}
      c={themeType==='dark'?'white':'gray.9'}
		>
			<Stack gap={8}>
				<NavLinkGNB to={"/"} onClickLink={onClickLink}>
					<Display fw={900} order={2}>
						Home
					</Display>
				</NavLinkGNB>
				<NavLinkGNB to={"/projects"} onClickLink={onClickLink}>
					<Display fw={900} order={2}>
						Project
					</Display>
				</NavLinkGNB>
				<NavLinkGNB to={"/about"} onClickLink={onClickLink}>
					<Display fw={900} order={2}>
						About
					</Display>
				</NavLinkGNB>
				<NavLinkGNB to={"/ideas"} onClickLink={onClickLink}>
					<Display fw={900} order={2}>
						Ideas
					</Display>
				</NavLinkGNB>
			</Stack>
			<Button
				rightSection={<IconWorld size={24} />}
				size="md"
				variant="outline"
				color={"inform"}
				radius={"xl"}
			>
				English
			</Button>
		</Flex>
	);
};
