import * as d3 from "d3";
import { GradientUnit } from "../unit/GradientUnit";
import { PathUnitSingle } from "../unit/PathUnitSingle";
import { path_D_reverse, path_s } from "../../../data/logoTypePathData";
import { Flex } from "@mantine/core";
import { D, S } from "../unit/UnitSet";

export const LogoSymbol = ({ delay = 240, scale = 1 / 3, isReverse, color, color2 }) => {
	return (
		<Flex gap={2} align={"center"}>
			<D color={color} delay={0} scale={scale} isReverse={isReverse} />
			<S color={color} delay={0} scale={scale} isReverse={isReverse} />
			{/* <GradientUnit
				data={path_D_reverse}
				delay={delay * 0 + 1000}
				scale={scale}
				strokeWidth={72}
				isReverse={isReverse}
				color1={color1}
				color2={color2}
			/>
			<GradientUnit
				data={path_s}
				delay={delay * 1 + 1000}
				scale={scale}
				strokeWidth={48}
				color1={color1}
				color2={color2}
			/> */}
		</Flex>
	);
};
