import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const PathUnitSingle = ({
	data,
	width,
	height,
	strokeWidth,
	strokeColor,
	delay = 0,
	scale = 1,
	pathClassName = "path",
	isReverse = false,
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// initialize SVG @ 1st Load
		let length;
		let path;
		const svg = d3.select(svgRef.current);
		if (!isLoaded) {
			const g = svg
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g")
				.attr("class", `g_${pathClassName}`)
				.attr("transform", `translate(0,0) scale(${scale})`);

			path = g
				.append("path")
				.attr("class", `path_${pathClassName}`)
				.attr("d", data)
				.attr("fill", "none")
				.attr("stroke-width", strokeWidth)
				.attr("stroke", strokeColor);

			length = path.node().getTotalLength();
			setIsLoaded(true);

			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", length);
		}
		// initialize SVG @ UseEffect Cycle
		else {
			path = svg.select("g").select("path");
			length = path.node().getTotalLength();
		}
		// forwad direction transition
		if (!isReverse) {
			// forward();
		}
		// reverse direction transition
		else {
			rewind();
		}

		function forward() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", length)
				.transition()
				.delay(delay)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", 0)
				.duration(600);
		}

		function rewind() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", 0)
				.transition()
				// .delay(delay)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", length)
				.duration(600);
		}
	}, [isReverse]);

	return (
		<div>
			<svg ref={svgRef} />
		</div>
	);
};
