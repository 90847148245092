import { useRef, useState } from "react";
import { Container } from "@mantine/core";
import ChartAxis from "../../assets/bg/axis_track_chart.svg";
import ChartAxis_mobile from "../../assets/bg/axis_track_chart_mobile.svg";
import { Flex } from "@mantine/core";
import { CardTrack } from "../../components/card/contentsExp/CardTrack";
import { Image } from "@mantine/core";
import { useBreakPoint } from "../../hook/useBreakPoint";

const trackList = [
	<CardTrack order={0} />,
	<CardTrack order={1} />,
	<CardTrack order={2} />,
	<CardTrack order={3} />,
];

export const TrackChart = ({}) => {
	const [height, setHeight] = useState(0);
	const imgRef = useRef(null);

	const handleImageLoad = () => {
		setHeight(imgRef.current.offsetHeight);
	};

	const { breakPoint, isLoaded, getResponsiveValue } = useBreakPoint();
	const chartPadding = {
		xs: 0,
		sm: 0,
		md: 48,
		lg: 48,
		xl: 48
	};

	return (
		<Container
			p={{ base: 12, md: 48 }}
			bg={"gray.1"}
			w={"100%"}
			style={{ position: "relative" }}
		>
			{isLoaded && (
				<>
					{(breakPoint === "xs" || breakPoint === "sm") && (
						<Image
							hiddenFrom="md"
							ref={imgRef}
							src={ChartAxis_mobile}
							alt={""}
							width={"100%"}
							onLoad={handleImageLoad}
						/>
					)}
					{(breakPoint === "md" || breakPoint === "lg" || breakPoint === "xl") && (
						<Image
							visibleFrom="md"
							ref={imgRef}
							src={ChartAxis}
							alt={""}
							width={"100%"}
							onLoad={handleImageLoad}
						/>
					)}

					<Flex
						w={"100%"}
						style={{ position: "absolute", top: getResponsiveValue(chartPadding, breakPoint), left: getResponsiveValue(chartPadding, breakPoint) }}
						wrap={"wrap"}
					>
						{trackList.map((track, i) => (
							<Flex
								direction={"column"}
								align={"center"}
								justify={"center"}
								w={`calc((100% - ${getResponsiveValue(chartPadding, breakPoint)*2}px)/2)`}
								h={`${height / 2}px`}
								key={i}
							>
								{track}
							</Flex>
						))}
					</Flex>
				</>
			)}
		</Container>
	);
};
