import { useState } from "react";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { useViewportSize } from "@mantine/hooks";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { Flex, Box, Stack, Title, Text, Button } from "@mantine/core";
import { Display } from "../../components/customTypo/Display";
import { LogoHorizon } from "../../components/logoType/logo/LogoHorizon";
import { CardDataValue } from "../../components/card/contentsExp/CardDataValue";
import { MainTopParticleLoop } from "../../visualization/topSectionBG/MainTopParticleLoop";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mantine/hooks";
import other from "../../designTokens/other";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { TypeAnimation } from "react-type-animation";
import { Transition } from "@mantine/core";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";
import { IconArrowNarrowRight } from "@tabler/icons-react";

const Column = styled(Flex)`
	width: 100%;
	position: relative;
	margin-top: -204px;
`;

const LogoBox = styled(Box)`
	position: absolute;
	top: 82px;
	left: 156px;
	background-color: #ffffffa0;
	padding: 8px;
`;

const LogoBoxMobile = styled(Box)`
	position: absolute;
	top: 232px;
	left: 0px;
`;

const BgLineLeft = styled.div`
	position: absolute;
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	background-image: ${(props) =>
		`linear-gradient(90deg, #F9FAFB00 0%, #F9FAFBFF 25%, #F9FAFBFF 50%, #F9FAFB00 100%)`};
	height: 120px;
	width: 320px;
	z-index: 9;
`;

const BgLineRight = styled.div`
	position: absolute;
	right: ${(props) => props.right};
	top: ${(props) => props.top};
	background-image: ${(props) =>
		`linear-gradient(-90deg,#F9FAFB00 0%, #F9FAFBFF 25%, #F9FAFBFF 50%, #F9FAFB00 100%)`};
	height: 120px;
	width: 320px;
	z-index: 9;
`;

export const TopSectionMainVer2 = ({ onClickInquiry }) => {
	const isMobile = useMediaQuery(`(max-width: ${other.size.md})`);
	const { height, width } = useViewportSize();
	const [isStop, setIsStop] = useState(false);
	const logoStartDelay = 500;
	const logoDelay = 80;

	return (
		<FullPageContainer
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				position: "relative",
			}}
		>
			<ViewportWrapper
				onEnterViewport={() => {
					setIsStop(false);
				}}
				onLeaveViewport={() => {
					setIsStop(true);
				}}
			>
				<ContentColumn>
					{/* // PC */}
					<Flex
						direction={"column"}
						justify={"center"}
						align={"center"}
						w={"100%"}
						visibleFrom="md"
						h={height}
						style={{ position: "relative" }}
						pt={120}
					>
						<Column
							direction={"column"}
							align={"center"}
							gap={320}
							justify={"center"}
							style={{ position: "relative" }}
						>
							<Flex
								direction={"column"}
								align={"center"}
								justify={"center"}
								gap={16}
							>
								<Title
									order={5}
									c={"white"}
									align={"center"}
									w={"fit-content"}
									bg={"gray.9"}
									m={"auto"}
									p={8}
									pl={12}
									pr={12}
									lts={0.8}
									style={{ borderRadius: 4 }}
								>
									<Text span c={"blue.4"} inherit>
										데이터 드리븐 디자인
									</Text>
									{` - `}
									<Text span c={"highlight"} inherit>
										{`초기 스타트업`}
									</Text>
									{`을 위한 최적의 파트너`}
								</Title>
								<LogoHorizon
									duration={800}
									startDelay={logoStartDelay}
									delay={logoDelay}
									scale={1 / 3}
								/>
								<TransitionWrapper delay={"1s"}>
									<Display
										order={3}
										align={"center"}
										bg={"#ffffffa0"}
										fw={1000}
										style={{marginTop: '24px', letterSpacing: '3px'}}
									>
										지속 가능한 서비스를 함께 만들어 나갑니다
									</Display>
								</TransitionWrapper>
								<TransitionWrapper
									delay={"1.3s"}
									style={{ textAlign: "center" }}
								></TransitionWrapper>
							</Flex>
							<Box
								w={"100%"}
								style={{
									position: "absolute",
									top: -136,
									left: 0,
									zIndex: -1,
									overflow: "hidden",
								}}
							>
								<BgLineLeft
									top={"8px"}
									left={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineLeft
									top={"368px"}
									left={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineRight
									top={"8px"}
									right={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineRight
									top={"368px"}
									right={"calc((100% - 1440px)/2 - 160px)"}
								/>
								{!isMobile && (
									<MainTopParticleLoop numParticle={50} isStop={isStop} />
								)}
							</Box>
						</Column>
						<TransitionWrapper delay={"2s"} style={{ textAlign: "center" }}>
							<Button
								onClick={onClickInquiry}
								w={"fit-content"}
								size="xl"
								h={72}
								radius={100}
								mt={240}
								rightSection={<IconArrowNarrowRight />}
							>
								프로젝트 문의.무료 상담하기
							</Button>
						</TransitionWrapper>

						<Flex
							gap={80}
							w={"100%"}
							style={{ position: "absolute", left: 0, bottom: 120 }}
						>
							<TransitionWrapper delay={"2.3s"}>
								<CardDataValue order={0} />
							</TransitionWrapper>
							<TransitionWrapper delay={"2.4s"}>
								<CardDataValue order={1} />
							</TransitionWrapper>
							<TransitionWrapper delay={"2.5s"}>
								<CardDataValue order={2} />
							</TransitionWrapper>
						</Flex>
					</Flex>
					{/* Mobile Tabelt */}
					<Box hiddenFrom="md" h={height} style={{ position: "relative" }}>
						<Stack pt={96} gap={4} align="center">
							<Title
								order={6}
								c={"white"}
								align={"center"}
								w={"fit-content"}
								bg={"gray.9"}
								m={"auto"}
								p={8}
								pl={8}
								pr={8}
								lts={0.8}
								style={{ borderRadius: 4 }}
							>
								<Text span c={"highlight"} inherit>
									{`초기 스타트업`}
								</Text>
								{`을 위한 최적의 파트너`}
							</Title>
							<Box bg={"#ffffffa0"} p={"12 6 12 6"}>
								<LogoHorizon delay={logoDelay} scale={0.145} />
							</Box>
							<Display
								order={5}
								align={"left"}
								bg={"#ffffffa0"}
								p={4}
								fw={1000}
							>
								지속 가능한 서비스
							</Display>
							<Display
								order={5}
								align={"left"}
								bg={"#ffffffa0"}
								p={4}
								fw={1000}
							>
								함께 만들어 나갑니다
							</Display>
							<TransitionWrapper delay={"1.4s"} style={{ textAlign: "center" }}>
								<Button
									onClick={onClickInquiry}
									w={"fit-content"}
									size="xl"
									radius={100}
									mt={120}
									rightSection={<IconArrowNarrowRight />}
								>
									프로젝트 문의.무료 상담하기
								</Button>
							</TransitionWrapper>
						</Stack>
						<TransitionWrapper delay={"2.1s"}>
							<Flex
								direction={{ base: "column", md: "row" }}
								w={"calc(100%)"}
								p={16}
								gap={16}
								bg={"#ffffffe0"}
								style={{
									position: "absolute",
									bottom: 24,
									left: 0,
									zIndex: 0,
									borderRadius: "4px",
								}}
							>
								<TransitionWrapper delay={"2.3s"}>
									<CardDataValue order={0} />
								</TransitionWrapper>
								<TransitionWrapper delay={"2.4s"}>
									<CardDataValue order={1} />
								</TransitionWrapper>
								<TransitionWrapper delay={"2.5s"}>
									<CardDataValue order={2} />
								</TransitionWrapper>
							</Flex>
						</TransitionWrapper>
					</Box>
					<Box
						w={"100%"}
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -9,
						}}
					>
						{isMobile && (
							<MainTopParticleLoop
								scale={0.4}
								numParticle={50}
								isStop={isStop}
							/>
						)}
					</Box>
				</ContentColumn>
			</ViewportWrapper>
		</FullPageContainer>
	);
};
