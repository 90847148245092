import { createContext, useContext, useReducer } from "react";
import headerReducer, { initialState } from "./headerReducer";

const HeaderContext = createContext(null);

const HeaderContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(headerReducer, initialState);
	return (
		<HeaderContext.Provider value={{ state, dispatch }}>
			{children}
		</HeaderContext.Provider>
	);
};

export const useHeaderDispatch = () => {
	const { dispatch } = useContext(HeaderContext);
	return dispatch;
};

export const useHeaderState = () => {
	const { state } = useContext(HeaderContext);
	return state;
};

export default HeaderContextProvider;
