import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const GridBG = ({ unit, x, y, fill }) => {
	let svgRef = useRef(null);

	useEffect(() => {
		const svg = d3.select(svgRef.current);
		svg.attr("width", unit * x).attr("height", unit * y);
		const g = svg
			.append("g")
			.attr("transform", `translate(${unit / 2},${unit / 2})`);
		const data = [];

		// Set Position Data
		for (let i = 0; i < y; i++) {
			for (let j = 0; j < x; j++) {
				let position = [j * unit, i * unit]; //(x,y);
				data.push(position);
			}
		}

		g.selectAll(".grid")
			.data(data)
			.enter()
			.append("rect")
			.attr("width", 0)
			.attr("height", 0)
			.attr("x", (d) => d[0])
			.attr("y", (d) => d[1])
			.attr("fill", fill)
			.transition()
			.delay((d) => d[0]/4 * 2 + d[1]/4 * 10)
			.duration(40)
			.attr("width", 4)
			.attr("height", 4);
	}, []);
	return <svg ref={svgRef} />;
};
