import styled from "styled-components";
import { Flex } from "@mantine/core";
import { LogoSymbol } from "./LogoSymbol";
import {
	D,
	A,
	// T,
	TS,
	R,
	I,
	V,
	E,
	N,
	G,
	S,
} from "../unit/UnitSet";

const gap = {
	r: 1, // regular, D,A,V,E,N,G,S
	s: 5 / 9, //small, T,R
	t: 1 / 3, //Tiny, I
	g: 1 / 4, // Gap between word
};

const positionData = [
	gap.r, //D 1:1
	gap.r, //D 1:1
	gap.r, //D 1:1
	gap.r, //E 1:1
	gap.r, //S 1:1
	gap.t, //I 1:3
	gap.r, //G 1:1
	gap.r, //N 1:1
];

const TypeContainer = styled(Flex)`
	position: relative;
	height: ${(props) => `${props.height}px`};
`;

const SymbolContainer = styled(Flex)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
`;

const UnitContainer = styled(Flex)`
	position: absolute;
	top: 0;
	left: ${(props) => `${props.x}px`};
	transition-timing-function: cubic-bezier(.47,1.64,.41,.8);
`;

const DContainer = styled(Flex)`
	position: absolute;
	top: 0;
	left: ${(props) => (props.$isReverse ? "0px" : `${props.x}px`)};
	transition: left 0.3s;
	transition-timing-function: cubic-bezier(.47,1.64,.41,.8);
	transition-delay: ${(props) => (props.$isReverse ? "0.3s" : "0.0s")};
`;

const SContainer = styled.div`
	position: absolute;
	top: 0;
	left: ${(props) => (props.$isReverse ? `${props.x2}px` : `${props.x}px`)};
	transition: left 0.3s;
	transition-timing-function: cubic-bezier(.47,1.64,.41,.8);
	transition-delay: 0.7s;
	transition-delay: ${(props) => (props.$isReverse ? "0.3s" : "0.0s")};
`;

export const LogoHorizonSimpleInteractive = ({
	delay = 50,
	scale = 1 / 3,
	isReverse = true,
	color,
	startWithReserve,
	ease='cubic-bezier(.47,1.64,.41,.8)'
}) => {
	const unit = 144 * scale;
	const getPosition = (i) => {
		let x = 0;
		for (let j = 0; j < i; j++) {
			x += positionData[j] * unit + 14*scale;
		}
		return x;
	};
	return (
		<TypeContainer height={unit}>
			{/* Symbol */}
			{/* DATA */}
			<DContainer $isReverse={isReverse} x={getPosition(0)}>
				<D
					startWithReserve
					color={color}
					delay={0}
					scale={scale}
					isReverse={false}
				/>
			</DContainer>
			{/* DRIVEN */}
			<DContainer $isReverse={isReverse} x={getPosition(1)}>
				<D
					startWithReserve
					color={color}
					delay={delay * 3}
					isReverse={false}
					scale={scale}
				/>
			</DContainer>
			{/* DESIGN */}
			<DContainer $isReverse={isReverse} x={getPosition(2)}>
				<D
					startWithReserve
					color={color}
					delay={delay * 8}
					isReverse={false}
					scale={scale}
				/>
			</DContainer>
			<UnitContainer $isReverse={isReverse} x={getPosition(3)}>
				<E
					startWithReserve
					color={color}
					delay={delay * 9}
					isReverse={isReverse}
					scale={scale}
				/>
			</UnitContainer>
			<SContainer $isReverse={isReverse} x={getPosition(4)} x2={getPosition(1)}>
				<S
					startWithReserve
					color={color}
					delay={delay * 10}
					isReverse={false}
					scale={scale}
				/>
			</SContainer>
			<UnitContainer $isReverse={isReverse} x={getPosition(5)}>
				<I
					startWithReserve
					color={color}
					delay={delay * 11}
					isReverse={isReverse}
					scale={scale}
				/>
			</UnitContainer>
			<UnitContainer $isReverse={isReverse} x={getPosition(6)}>
				<G
					startWithReserve
					color={color}
					delay={delay * 12}
					isReverse={isReverse}
					scale={scale}
				/>
			</UnitContainer>
			<UnitContainer $isReverse={isReverse} x={getPosition(7)}>
				<N
					startWithReserve
					color={color}
					delay={delay * 13}
					isReverse={isReverse}
					scale={scale}
				/>
			</UnitContainer>
		</TypeContainer>
	);
};
