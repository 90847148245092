import styled from "styled-components";
import { D, A, TS, T, R, I, V, E, N, G, S } from "../unit/UnitSet";
import { Flex, Stack } from "@mantine/core";
import * as d3 from "d3";

const GridUnit = styled.div`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	text-align: center;
`;

const LineLong = styled.div`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	position: relative;
	&:before {
		content: " ";
		position: absolute;
		top: 0;
		left: ${(props) => (props.isTrigger ? 0 : `${props.width / 2}px`)};
		background-color: ${(props) => props.color};
		transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${(props) => `${props.delay / 1000}s`};
		width: ${(props) => (props.isTrigger ? `${props.width}px` : `0px`)};
		height: ${(props) => `${props.height}px`};
	}
`;

const LineShort = styled.div`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	position: relative;
	&:before {
		content: " ";
		position: absolute;
		top: 0;
		left: ${(props) => (props.isTrigger ? 0 : `${props.width / 2}px`)};
		background-color: ${(props) => props.color};
		transition: all 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${(props) => `${props.delay / 1000}s`};
		width: ${(props) => (props.isTrigger ? `${props.width}px` : `0px`)};
		height: ${(props) => `${props.height}px`};
	}
`;

export const LogoVerticalGrid = ({
	delay = 800,
	scale = 1 / 3,
	color = "#002AFF",
	isReverse = false,
	isTrigger = true,
	ease = d3.easeBackOut,
}) => {
	const size = {
		unitWidth: 144,
		unitPadding: 16,
		lineShort: 48,
		lineHeight: 16,
	};

	const gridWidth = size.unitWidth * scale + size.unitPadding;
	const gridHeight = size.unitWidth * scale;
	const lineShortWidth = size.lineShort * scale;
	const lineLongWidth = gridWidth + lineShortWidth * 2;
	const lineHeight = size.lineHeight * scale;

	const setDelayUnit = (num) => {
		let delay = Math.random() * num + num;
		return delay;
	};

	const setDelayLine = (num) => {
		let delay = Math.random() * num;
		return delay;
	};

	return (
		<Stack gap={gridHeight * 0.6}>
			<Flex gap={0} align={"center"}>
				<GridUnit width={gridWidth} height={gridHeight}>
					<D
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<A
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineLong
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineLongWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<TS
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineLong
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineLongWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<A
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
			</Flex>
			<Flex gap={0} align={"center"}>
				<GridUnit width={gridWidth} height={gridHeight}>
					<D
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<R
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<I
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<V
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<E
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<N
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
			</Flex>
			<Flex gap={0} align={"center"}>
				<GridUnit width={gridWidth} height={gridHeight}>
					<D
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<E
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<S
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<I
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<G
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
				<LineShort
					delay={setDelayLine(delay)}
					isTrigger={isTrigger}
					color={color}
					width={lineShortWidth}
					height={lineHeight}
				/>
				<GridUnit width={gridWidth} height={gridHeight}>
					<N
						isTrigger={isTrigger}
						scale={scale}
						color={color}
						isReverse={isReverse}
						ease={ease}
						delay={setDelayUnit(delay)}
					/>
				</GridUnit>
			</Flex>
		</Stack>
	);
};
