import { Container, Box } from "@mantine/core";
import { BrandAd } from "./BrandAd";
import { BrandAdVertical } from "./BrandAdVertical";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { LogoHorizonSimpleInteractive } from "../../components/logoType/logo/LogoHorizonSimpleInteractive";
import colorsVariants from "../../designTokens/colorsVariants";
import { MainTopParticleLoop } from "../../visualization/topSectionBG/MainTopParticleLoop";
export const PageForVideoCapture = ({}) => {
	return (
		<Container>
			<ContentColumn>
				<Box mt={400}>
					<Box style={{transform:'scale(1.4)'}}>
					<MainTopParticleLoop numParticle={50} isStop={false} />
					</Box>						
				</Box>
			</ContentColumn>
			{/* <BrandAd /> */}
			{/* <BrandAdVertical /> */}
			{/* <ContentColumn>
				<Box pt={400}>
					<LogoHorizonSimpleInteractive
						startWithReserve={true}
						color={colorsVariants.primary[5]}
						scale={1}
						isReverse={false}
					/>
				</Box>
			</ContentColumn> */}
		</Container>
	);
};
