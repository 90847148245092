import { useEffect, useState } from "react";
import { projectDataList } from "../../data/dummy/projectList";
import { dbService } from "../../firebaseInstance";
import { Container, Button } from "@mantine/core";
import { SectionStack } from "../../components/layout/stack/SectionStack";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { Select } from "@mantine/core";

export const PageProjectUpload = ({}) => {
	const list = [];
	const [projectList, setProjectList] = useState([]);
	const [selectedProject, setSelectedProject] = useState({});
	useEffect(() => {
		projectDataList.forEach((data, i) => {
			list.push(data.id);
		});

		setProjectList(list);
	}, []);

	const onSelectProject = (id) => {
		let project = projectDataList.filter((p) => {
			return p.id === id;
		})[0];

		setSelectedProject(project);
    console.log(project);
	};

	const submitProject = (body) => {
		dbService
			.collection("projectList")
			.doc(body.id)
			.set(body)
			.then(async () => {
				window.alert("프로젝트가 업로드 되었습니다");
			});
	};

	return (
		<Container w={"100%"} pt={200}>
			<ContentColumn>
				<SectionStack>
					<Select
						label="project list"
						data={projectList}
						onSelect={(e) => {
							onSelectProject(e.target.value);
						}}
					/>
					<Button
						size={"xl"}
						onClick={() => {
							submitProject(selectedProject);
						}}
					>
						프로젝트 업로드
					</Button>
					{selectedProject?.status?.id}
				</SectionStack>
			</ContentColumn>
		</Container>
	);
};
