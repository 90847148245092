import { PathUnitSingleEndMarker } from "../../../components/logoType/unit/PathUnitSingleEndMarker";
import { cycleLinePath } from "../../../data/visualLangaugePathData";
import colors from "../../../designTokens/colors";
import { PathTweenSingle } from "../../../components/logoType/unit/PathTweenSingle";
import { path_t } from "../../../data/visualLangaugePathData";
import { Box } from "@mantine/core";

export const CycleLine = ({scale=1, id, color, isTrigger=true, delay=300}) => {
	const duration = 1600;
	return (
		<Box style={{ position: "relative", overflow: 'hidden'}}>
			<PathUnitSingleEndMarker
				data={cycleLinePath}
				width={667}
				height={183}
				strokeWidth={4}
				strokeColor={color}
				delay={delay}
				duration={duration}
				scale={scale}
				pathClassName={id}
				isTrigger={isTrigger}
			/>
			<Box 
      style={{ position: "absolute", top: 0, left: 0 }}
      >
				<PathTweenSingle
					data={cycleLinePath}
					data2={path_t}
					width={667}
					height={183}
					delay={delay}
					duration={duration}
					scale={scale}
					pathClassName={id}
					color={color}
					isTrigger={isTrigger}
				/>
			</Box>
		</Box>
	);
};
