import {
	Flex,
	Stack,
	Text,
	Title,
	Button,
	Container,
	Box,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FullPageContainer } from "../layout/container/FullPageContainer";
import { NavLinkGNB } from "./NavLinkGNB";
import { LogoVerticalGrid } from "../logoType/logo/LogoVerticalGrid";
import { gray } from "../../designTokens/colors";
import { IconWorld } from "@tabler/icons-react";
import icon_fb from "../../assets/icon/social/icon_social_facebook.svg";
import icon_brunch from "../../assets/icon/social/icon_social_brunch.svg";
import icon_instagram from "../../assets/icon/social/icon_social_IG.svg";
import icon_medium from "../../assets/icon/social/icon_social_medium.svg";
import { ContentColumn } from "../layout/column/ContentColumn";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { SET_IS_HIDE } from "../../context/headerState/action";
import { useHeaderDispatch } from "../../context/headerState/headerContext";
import { useInViewport } from "@mantine/hooks";
import ViewportWrapper from "../triggerWrapper/ViewportWrapper";
import { SetHeaderContext } from "../../context/headerState/SetHeaderContext";
import { Link } from "react-router-dom";

export const Footer = ({ isReverse }) => {
	const { breakPoint, getResponsiveValue, isLoaded } = useBreakPoint();
	const linkOrder = {
		xs: 1,
		sm: 1,
		md: 0,
		lg: 0,
		xl: 0,
	};

	const copyrightOrder = {
		xs: 2,
		sm: 2,
		md: 1,
		lg: 1,
		xl: 1,
	};

	const logoScale = {
		xs: 0.2,
		sm: 0.2,
		md: 0.3,
		lg: 0.6,
		xl: 0.6,
	};

	const { setHeaderPosition } = SetHeaderContext();
	const [isTrigger, setIsTrigger] = useState(false);

	return (
		<Container w={"100%"} style={{ position: "relative", zIndex: 999 }}>
			<FullPageContainer bg={"primary"} c={"gray.0"}>
				<Box style={{ position: "absolute", top: "75%", left: 0 }}>
					<ViewportWrapper
						onEnterViewport={() => {
							setHeaderPosition(true);
							setIsTrigger(true);
						}}
						onLeaveViewport={() => {
							setHeaderPosition(false);
						}}
					></ViewportWrapper>
				</Box>
				{isLoaded && (
					<ContentColumn h={"100%"}>
						<Flex
							style={{
								width: "100%",
								height: `100%`,
							}}
							direction={"column"}
							align={"center"}
							gap={48}
							justify={"space-between"}
							pt={24}
							pb={24}
						>
							{/* Links */}
							<Flex
								style={{ order: getResponsiveValue(linkOrder, breakPoint) }}
								w={"100%"}
								justify={"space-between"}
								direction={{ base: "column", md: "row" }}
								gap={{ base: 48, md: 24 }}
							>
								<Stack gap={0}>
									<Title size={"h4"} mb={4}>Site</Title>
									<Flex gap={24} align={"center"}>
										<NavLinkGNB to={"/"}>Home</NavLinkGNB>
										<NavLinkGNB to={"/projects"}>Projects</NavLinkGNB>
										<NavLinkGNB to={"/about"}>About</NavLinkGNB>
										<NavLinkGNB to={"/ideas"}>Ideas</NavLinkGNB>
									</Flex>
								</Stack>
								<Stack gap={"xs"}>
									<Title size={"h4"}>Contact</Title>

									{/* <Text size={"lg"}>
									Please contact us for project inquiry or any questions
								</Text> */}
									<Link to={"mailto:contact@dddesign.io"} target="_blank">
										<Text size={"xl"} fw={700} mt={-4} td="underline">
											contact@dddesign.io
										</Text>
									</Link>
								</Stack>
								<Stack gap={"sm"}>
									<Title size={"h4"}>Social</Title>
									<Flex gap={24} align={"center"}>
										<Link to="https://brunch.co.kr/@dddesign" target="_blank">
											<img src={icon_brunch} alt="brunch" />
										</Link>
										<Link
											to="https://www.facebook.com/dddesign.io"
											target="_blank"
										>
											<img src={icon_fb} alt="fb" />
										</Link>
										<Link
											to="https://www.instagram.com/data.driven.design/"
											target="_blank"
										>
											<img src={icon_instagram} alt="ig" />
										</Link>
										{/* <img src={icon_medium} alt="medium" /> */}
									</Flex>
								</Stack>
							</Flex>
							{/* Grid Logo */}
							<Box style={{ display: "block" }} mb={{ base: 64, md: 0 }}>
								<LogoVerticalGrid
									color={gray[0]}
									scale={getResponsiveValue(logoScale, breakPoint)}
									delay={1200}
									isReverse={isReverse}
									isTrigger={isTrigger}
								/>
							</Box>
							<Flex
								w={"100%"}
								justify={"space-between"}
								style={{
									order: getResponsiveValue(copyrightOrder, breakPoint),
								}}
							>
								<Text size={"lg"}>
									Data Driven Design © 2024
								</Text>
								<Button
									rightSection={<IconWorld size={24} />}
									size="md"
									variant="outline"
									color="white"
									radius={"xl"}
									visibleFrom="sm"
								>
									English
								</Button>
							</Flex>
						</Flex>
					</ContentColumn>
				)}
			</FullPageContainer>
		</Container>
	);
};
