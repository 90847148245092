import styled from "@emotion/styled";
import { ContentColumn } from "../column/ContentColumn";
import { Box } from "@mantine/core";
import other from "../../../designTokens/other";
import { FullPageContainer } from "./FullPageContainer";

const Container = styled(Box)`
	/* height: ${(props) => props.theme.other.size.topSectionHeight}; */
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const TopSectionContainer = ({ children, bg }) => {
	return (
		<FullPageContainer
			// h={{ base: height, md: height }}
			// style={{ position: "relative" }}
			// bg={bg}
		>
			<ContentColumn>{children}</ContentColumn>
		</FullPageContainer>
	);
};
