import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";
import { loopCycle1, loopCycle2 } from "../../data/visualLangaugePathData";
import colors from "../../designTokens/colors";
import {
	path_t,
	path_c,
	path_s,
	path_d,
} from "../../data/visualLangaugePathData";
import { useMediaQuery } from "@mantine/hooks";
import { useViewportSize } from "@mantine/hooks";
import other from "../../designTokens/other";
import { Box } from "@mantine/core";
import { useAnimationFrameLoop } from "react-timing-hooks";

let svg;

export const MainTopParticleLoop = ({
	numParticle = 75,
	scale = 0.4,
	isStop,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${other.size.md})`);
	const { height, width } = useViewportSize();

	const colorList = [
		colors.blue[3],
		colors.blue[4],
		colors.blue[5],
		colors.blue[6],
		colors.yellow[5],
	];
	const pathList = [path_t, path_c, path_s, path_d];
	const strokeWidth = 120;
	const strokeWidth2 = 132;
	const duration = 5200;
	const startDelay = 0;
	const delay = 6400;

	let svgRef = useRef(null);

	const [count, setCount] = useState(0);
	const [isFirstCycle, setIsFirstCycle] = useState(true);

	const { start, stop, isStopped } = useAnimationFrameLoop(() => {
		setCount(count + 1);
		if (count === 0) {
			renderParticle(numParticle, 1, count);
			renderParticle(numParticle, 2, count);
		} else if (count % (delay / 10) === 0) {
			renderParticle(numParticle, 1, count);
			renderParticle(numParticle, 2, count);
		}
	});

	useEffect(() => {
		svg = d3.select(svgRef.current);
		svg.attr("width", "1440").attr("height", "496");

		const gBG = svg
			.append("g")
			.attr("class", "pathBG")
			.attr("transform", "translate(0,8)");

		const bgPath1 = gBG
			.append("path")
			.attr("d", loopCycle1)
			.attr("fill", "none")
			.attr("stroke-width", strokeWidth)
			.attr("stroke", `#E4EEFF90`);

		const bgPath2 = gBG
			.append("path")
			.attr("d", loopCycle2)
			.attr("fill", "none")
			.attr("stroke-width", strokeWidth)
			.attr("stroke", `#E4EEFF90`);

		const length1 = bgPath1.node().getTotalLength();
		const length2 = bgPath2.node().getTotalLength();

		bgPath1
			.attr("stroke-dasharray", length1 + " " + length1)
			.attr("stroke-dashoffset", length1);

		bgPath2
			.attr("stroke-dasharray", length2 + " " + length2)
			.attr("stroke-dashoffset", length2);

		forward(bgPath1, length1, 0);
		forward(bgPath2, length2, 400);

		const g1 = svg
			.append("g")
			.attr("class", "pathGroup_1")
			.attr("transform", "translate(0,8)");

		const g2 = svg
			.append("g")
			.attr("class", "pathGroup_2")
			.attr("transform", "translate(0,8)");

		const path1 = g1
			.append("path")
			.attr("class", "path_1")
			.attr("d", loopCycle1)
			.attr("fill", "none")
			.attr("stroke-width", 0);

		const path2 = g2
			.append("path")
			.attr("class", "path_2")
			.attr("d", loopCycle2)
			.attr("fill", "none")
			.attr("stroke-width", 0);
	}, []);

	useEffect(() => {
		if (!isStop) {
			start();
		} else {
			stop();
			setIsFirstCycle(false);
		}
	}, [isStop]);

	function renderParticle(num, path_num, index) {
		// Particle Setting With Y position inside the path
		let particleGroup = d3
			.select(`.pathGroup_${path_num}`)
			.selectAll(`.particle_${index}`)
			.data(() => {
				let data = [];
				for (let i = 0; i < num; i++) {
					let datum = parseInt(Math.random() * strokeWidth2 - strokeWidth2 / 2);
					data.push(datum);
				}
				return data;
			})
			.enter()
			.append("g")
			.attr("class", "particle")
			.attr("opacity", "0")
			.attr("transform", "translate(0,0)");

		particleGroup
			.append("path")
			.attr("d", () => {
				let i = parseInt(Math.random() * 10) % 4;
				return pathList[i];
			})
			.attr("fill", () => {
				let i = parseInt(Math.random() * 10) % 5;
				return colorList[i];
			})
			.attr("transform", `translate(-8,-8) scale(${scale})`);

		// Call Tween Animation Function
		particleGroup
			.transition()
			.duration(duration)
			.delay(() => {
				let delayTotal = isFirstCycle
					? Math.random() * 1.3 * delay + startDelay * path_num
					: Math.random() * 1.3 * delay;
				return delayTotal;
			})
			.ease(d3.easeQuadInOut)
			.attr("opacity", "1")
			.tween("pathTween", (d) => {
				if (svg !== null) {
					let path = svg.select(`.path_${path_num}`);
					return pathTweenWithGroup(path, d);
				}
			})
			// .on("end", (d, i) => {
			// 	if (i === numParticle-1) {
			// 		// console.log("end");
			// 		if(!isStop) renderParticle(num, path_num, index + 1);
			// 	}
			// })
			.remove();
	}

	function pathTweenWithGroup(path, offset) {
		var length = path.node().getTotalLength(); // Get the length of the path
		var r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
		return function (t) {
			var point = path.node().getPointAtLength(r(t)); // Get the next point along the path

			d3.select(this) // Select the circle
				// .attr("x", point.x + offset.x) // Set the cx
				// .attr("y", point.y + offset.y) // Set the cy
				.attr("transform", `translate(${point.x},${point.y + offset})`);
		};
	}

	function forward(path, length, delay) {
		// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
		path
			.attr("stroke-dasharray", length + " " + length)
			.attr("stroke-dashoffset", length)
			.transition()
			.delay(delay)
			.ease(d3.easeQuadOut)
			.attr("stroke-dashoffset", 0)
			.duration(2400);
	}

	return (
		<Box w={"100%"} h={height} style={{ overflow: "hidden" }}>
			<svg
				style={{
					pointerEvent: "none",
					transform: isMobile
						? `rotate(90deg) scale(${width / 496}) translate(-160px, ${-496}px)`
						: "scale(1)",
					transformOrigin: "top left",
				}}
				ref={svgRef}
			/>
		</Box>
	);
};
