import { useEffect, useRef, useState } from "react";
import { useWindowScroll } from "@mantine/hooks";
import {
	Container,
	Grid,
	Stack,
	Title,
	Text,
	Image,
	Button,
} from "@mantine/core";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import other from "../../designTokens/other";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { Link } from "react-router-dom";

const SectionContent = ({
	section,
	i,
	setCurrentSection,
	setCurrentSection2,
	currentSection,
}) => {
	const ref = useRef(null);
	const [scroll] = useWindowScroll();

	useEffect(() => {
		if (ref.current.offsetTop - scroll.y <= 360) {
			setCurrentSection2(i);
		}
	}, [scroll]);

	useEffect(() => {
		if (currentSection === i) {
			ref.current.scrollIntoView({ behavior: "smooth" });
			setCurrentSection(i);
		}
	}, [currentSection]);

	return (
		<Stack gap={0} ref={ref} style={{ scrollMargin: "120px" }}>
			<Title mb={"xl"} order={2} key={i}>
				{`${i}. ${section.title}`}
			</Title>
			<Stack gap={64}>
				{section.contentList?.map((content, i) => {
					if (content.type === "text") {
						return (
							<Text fw={500} c={"gray.2"} size={"xl"}>
								{content.content}
							</Text>
						);
					} else if (content.type === "img") {
						return (
							<Stack gap={4}>
								<Image
									src={content.src}
									mb={8}
									style={{ borderRadius: "0px" }}
								/>
								<Text size="lg" fw={600} c={"gray.3"}>
									*{content.caption}
								</Text>
							</Stack>
						);
					}
				})}
			</Stack>
		</Stack>
	);
};
export const ProjectContent = ({ projectDetail }) => {
	const sectionListRef = useRef(null);
	const [scroll] = useWindowScroll();
	const [isFixed, setIsFixed] = useState(false);
	// for scroll
	const [currentSection, setCurrentSection] = useState(0);
	// for list
	const [currentSection2, setCurrentSection2] = useState(0);

	useEffect(() => {
		if (sectionListRef.current.offsetTop - scroll.y <= 160) {
			setIsFixed(true);
		} else {
			setIsFixed(false);
		}
	}, [scroll]);

	return (
		<Container w={"100%"} p={"120px 0px 240px 0px"}>
			<Grid gutter={{ base: 24, md: 48 }}>
				<Grid.Col
					span={{ base: 12, md: 3 }}
					pt={0}
					pb={0}
					h={"fit-content"}
					ref={sectionListRef}
				>
					<Stack
						gap={16}
						w={"248px"}
						style={{
							position: "fixed",
							top: "120px",
							left: `calc((100% - ${other.size.lg})/2 + 22px)`,
							opacity: isFixed ? 1 : 0,
							transition: "all 0.1s",
						}}
					>
						<Title mb={8} order={4}>
							{projectDetail.summary.title}
						</Title>
						{projectDetail.sectionList.map((section, i) => (
							<Title
								order={5}
								fw={400}
								key={i}
								onClick={() => {
									setCurrentSection(i + 1);
								}}
								style={{
									opacity: currentSection2 === i + 1 ? 1 : 0.5,
									cursor: "pointer",
								}}
							>
								{`${i + 1}. ${section.title}`}
							</Title>
						))}
						<Link to={projectDetail.link} target="_blank">
						{projectDetail.summary.link && <Button
								mt={12}
								size={"sm"}
								variant="outline"
								color={"contrast"}
								radius={"xl"}
								fullWidth
								rightSection={<IconArrowNarrowRight size={24} />}
							>
								Link To Project
							</Button>}
							
						</Link>
					</Stack>
				</Grid.Col>
				<Grid.Col span={{ base: 12, md: 9 }}>
					<Stack gap={256}>
						{projectDetail.sectionList.map((section, i) => (
							<ViewportWrapper key={i}>
								<SectionContent
									section={section}
									setCurrentSection={setCurrentSection}
									setCurrentSection2={setCurrentSection2}
									currentSection={currentSection}
									i={i + 1}
								/>
							</ViewportWrapper>
						))}
					</Stack>
					<>
						{projectDetail.link && (
							<Link to={projectDetail.link} target="_blank">
								<Button
									mt={80}
									size={"xl"}
									variant="white"
									color={"gray.9"}
									radius={"xl"}
									fullWidth
									rightSection={<IconArrowNarrowRight size={24} />}
								>
									Link To Project
								</Button>
							</Link>
						)}
					</>
				</Grid.Col>
			</Grid>
		</Container>
	);
};
