import { useEffect, useState } from "react";
import { Container, Flex } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useBreakPoint } from "../../../hook/useBreakPoint";

export const FullPageContainer = ({
	children,
	bg,
	c,
	style,
	scale = 1,
	ref,
}) => {
	const { height } = useViewportSize();
	const initHeight = window.screen.availHeight;
	const { breakPoint, getResponsiveValue } = useBreakPoint();
	const [viewportHeight, setViewportHeight] = useState(initHeight);
	const containerHeight = {
		xs: "auto",
		sm: "auto",
		md: height===0?initHeight:height * scale,
		lg: height===0?initHeight:height * scale,
		xl: height===0?initHeight:height * scale,
	};

	useEffect(() => {
		setViewportHeight(height);
	},[height])



	return (
		<Flex
			ref={ref}
			w={"100%"}
			style={{
				// width: "100%",
				height: getResponsiveValue(containerHeight, breakPoint),
				minHeight: `${viewportHeight}px`,
				...style,
			}}
			bg={bg}
			c={c}
			direction={"column"}
			justify={"center"}
		>
			{children}
		</Flex>
	);
};