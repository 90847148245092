import { useState } from "react";
import { Flex, Box } from "@mantine/core";
import { Title } from "@mantine/core";
import { Badge } from "@mantine/core";
import { Image } from "@mantine/core";
import { useRef } from "react";
import { DatumTransitionSpace } from "../../space/DatumTransitionSpace";
import ViewportWrapper from "../../triggerWrapper/ViewportWrapper";
import { FadeIn } from "../../triggerWrapper/FadeIn";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";

export const CardProject = ({
	title,
	tags,
	img,
	isDark = false,
	id,
	delay,
	color,
	to,
	isCompleted,
}) => {
	let imgRef = useRef(null);
	const [isTrigger, setIsTrigger] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	return (
		<ViewportWrapper
			onEnterViewport={() => {
				setTimeout(() => {
					setIsTrigger(true);
				}, delay);
			}}
		>
			<Link to={to}>
				<Flex direction={"column"} align={"start"} gap={8}>
					<Box style={{ position: "relative", zIndex: 9 }}>
						<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.3}s`}>
						{!isCompleted && (
							<Box
								bg={color}
								c={"white"}
								p={8}
								style={{ position: "absolute", bottom: 0, left: 0, backgroundColor: color, opacity:0.75 }}
							>
								<Text size={'lg'} style={{letterSpacing:1}} fw={700}>comming soon</Text>
							</Box>
						)}
							<Image
								ref={imgRef}
								w={"100%"}
								h={"fit-content"}
								src={img}
								alt={""}
								onLoad={() => {
									setIsLoaded(true);
								}}
							/>
						</FadeIn>
						<Box
							w={imgRef.current?.offsetWidth}
							h={imgRef.current?.offsetHeight}
							style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
							bg={isDark ? "gray.9" : "gray.1"}
						>
							{isLoaded && (
								<DatumTransitionSpace
									width={imgRef.current?.offsetWidth}
									height={imgRef.current?.offsetHeight}
									x={3}
									y={40}
									isTrigger={isTrigger}
									color={color}
									id={id}
									delay={200}
								/>
							)}
						</Box>
					</Box>
					<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.3}s`}>
						<Title c={isDark ? "white" : "gray.9"} align={"left"} order={3}>
							{title}
						</Title>
					</FadeIn>
					{/* <FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.4}s`}> */}
					<Flex direction={"row"} wrap={"wrap"} gap={4}>
						{tags.map((tag, i) => (
							<FadeIn
								isTrigger={isTrigger}
								delay={`${delay / 1000 + 0.3 + i * 0.2}s`}
							>
								<Badge
									variant="outline"
									size="md"
									key={i}
									color={isDark ? "white" : "gray.9"}
								>
									{tag}
								</Badge>
							</FadeIn>
						))}
					</Flex>
					{/* </FadeIn> */}
				</Flex>
			</Link>
		</ViewportWrapper>
	);
};
