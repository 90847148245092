import { DoubleCirclePattern } from "../../stories/components/visualLangauge/DoubleCirclePattern";
import { VisionPattern } from "../../stories/components/visualLangauge/VisionPattern";
import styled from "@emotion/styled";

const ShifLeft = styled.div`
	margin-left: -90px;
`;

const VisionPathContainer = styled.div`
	position: absolute;
	top: 0;
	left: 1080px;
`;


export const AboutTopCircles = ({delay}) => {
	return (
		<>
			<DoubleCirclePattern delay1={delay * 0} delay2={delay} />
			<ShifLeft />
			<DoubleCirclePattern delay1={delay * 1} delay2={delay} />
			<ShifLeft />
			<DoubleCirclePattern delay1={delay * 2} delay2={delay} />
			<ShifLeft />
			<DoubleCirclePattern delay1={delay * 3} delay2={delay} />
			<ShifLeft />
			<DoubleCirclePattern delay1={delay * 4} delay2={delay} />
			<VisionPathContainer>
				<VisionPattern delay1={delay * 5} delay2={delay} />
			</VisionPathContainer>
		</>
	);
};
