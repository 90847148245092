import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const LogoTypeUnitSingle = ({
	data,
	width = 144,
	strokeWidth,
	startDelay = 0,
	delay = 0,
	scale = 1,
	pathClassName = "path",
	isReverse = false,
	color = "#002AFF",
	duration = 600,
	isTrigger = true,
	startWithReserve = false,
	ease = d3.easeQuadOut
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFirstCycle, setIsFirstCycle] = useState(true);
	let path;
	let length;

	useEffect(() => {
		// initialize SVG @ 1st Load
		if (!isLoaded) {
			const svg = d3.select(svgRef.current);
			const g = svg
				.attr("width", width * scale)
				.attr("height", 144 * scale)
				.append("g")
				.attr("class", `g_${pathClassName}`)
				.attr("transform", `translate(0,0) scale(${scale})`);

			path = g
				.append("path")
				.attr("class", `path_${pathClassName}`)
				.attr("d", data)
				.attr("fill", "none")
				.attr("stroke-width", strokeWidth)
				.attr("stroke", color);

			length = path.node().getTotalLength();
			if (startWithReserve) {
				path
					.attr("stroke-dasharray", length + " " + length)
					.attr("stroke-dashoffset", length);
			} else {
				path
					.attr("stroke-dasharray", length + " " + length)
					.attr("stroke-dashoffset", 0);
			}
		}
		setIsLoaded(true);
	}, [isLoaded]);

	useEffect(() => {
		const svg = d3.select(svgRef.current);
		path = svg.select("g").select("path");
		length = path.node().getTotalLength();

		if (isTrigger && isLoaded) {
			// forwad direction transition
			if (!isReverse) {
				forward();
			}
			// reverse direction transition
			else {
				if(!(isFirstCycle && startWithReserve)){
					rewind();
				}
			}
			setIsFirstCycle(false);
		}
	}, [isReverse, isTrigger, isLoaded]);

	useEffect(() => {
		const svg = d3.select(svgRef.current);
		path = svg.select("g").select("path");
		path
			// .transition()
			// .duration(600)
			.attr("stroke",color);
	}, [color]);

	function forward() {
		// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
		path
			.attr("stroke-dasharray", length + " " + length)
			.attr("stroke-dashoffset", length)
			.transition()
			.delay(delay + startDelay)
			.ease(ease)
			.attr("stroke-dashoffset", 0)
			.duration(duration);
	}

	function rewind() {
		// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
		path
			.attr("stroke-dasharray", length + " " + length)
			.attr("stroke-dashoffset", 0)
			.transition()
			// .delay(delay)
			.ease(ease)
			.attr("stroke-dashoffset", length)
			.duration(duration);
	}

	return <svg ref={svgRef} />;
};
