const thumb_story1 = "https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/story_assets%2Fthumb_story_1.jpg?alt=media&token=aa53314c-2461-4f96-909f-5d454f2606ab";
const thumb_story2 = "https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/story_assets%2Fthumb_story_2.jpg?alt=media&token=176a82d4-112c-4ee4-b69b-3969456f5199";
const thumb_story3 = "https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/story_assets%2Fthumb_story_3.jpg?alt=media&token=b268875c-cedf-4a16-97f9-150284112b8b";

export const storyDataList = [
  {
    order: 0,
    isCompleted: true,
    id:'design-system-01',
    title: '디자인 시스템의 육하원칙-1,왜?',
    summary: '디자인 시스템을 도입해야하는 본질적인 이유는 프로덕트 팀이 단선적 사고에서 벗어나 시스템적인 사고를 통해 서비스를 빌딩함으로 보장받을 수 있는 장점이 많기때문입니다. 시스템 사고의 특성을 살펴보며 디자인 시스템 도입의 이유와 기대효과에 대해 얘기해봅니다.',
    tags: ['디자인 시스템' ,'시스템 씽킹'],
    img: thumb_story1,
    link: 'https://brunch.co.kr/@groovelb/1'
  },
  {
    order: 1,
    isCompleted: true,
    id:'design-system-02',
    title: '디자인 시스템의 육하원칙-2, 누가, 언제?',
    summary: '디자인 시스템을 도입해야하는 본질적인 이유는 프로덕트 팀이 단선적 사고에서 벗어나 시스템적인 사고를 통해 서비스를 빌딩함으로 보장받을 수 있는 장점이 많기때문입니다. 시스템 사고의 특성을 살펴보며 디자인 시스템 도입의 이유와 기대효과에 대해 얘기해봅니다.',
    tags: ['디자인 시스템' ,'협업'],
    img: thumb_story2,
    link: 'https://brunch.co.kr/@groovelb/2'
  },
  {
    order: 2,
    isCompleted: false,
    id:'design-system-03',
    title: '디자인 시스템의 육하원칙-3, 어떻게?',
    summary: '디자인 시스템을 도입해야하는 본질적인 이유는 프로덕트 팀이 단선적 사고에서 벗어나 시스템적인 사고를 통해 서비스를 빌딩함으로 보장받을 수 있는 장점이 많기때문입니다. 시스템 사고의 특성을 살펴보며 디자인 시스템 도입의 이유와 기대효과에 대해 얘기해봅니다.',
    tags: ['디자인 시스템' ,'디자인 토큰'],
    img: thumb_story3,
    link: ''
  }
];

