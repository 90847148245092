import { SET_IS_SCROLLED, SET_THEME_TYPE, SET_IS_HIDE } from "./action";

export const initialState = {
  isScrolled: false,
  themeType: 'light',
  isHide: false,
};

const headerReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_SCROLLED: {
      return {
        ...state,
        isScrolled: action.value,
      }
    }
    case SET_THEME_TYPE: {
      return {
        ...state,
        themeType: action.value,
      }
    }
    case SET_IS_HIDE: {
      return {
        ...state,
        isHide: action.value,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export default headerReducer;