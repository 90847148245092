import styled from "styled-components";
import {
	D,
	A,
	T,
	TS,
	R,
	I,
	V,
	E,
	N,
	G,
	S,
} from "../../../components/logoType/unit/UnitSet";
import { Flex } from "@mantine/core";

const LeftShift = styled.div`
	margin-left: -6px;
`;

const LeftShiftBig = styled.div`
	margin-left: -8px;
`;

export const LogoHorizon = ({
	startDelay,
	delay = 200,
	scale = 1 / 3,
	isReverse = false,
	duration = 600,
}) => {
	return (
		<Flex gap={24 * scale * 3}>
			<Flex gap={2 * scale * 5}>
				<D
					duration={duration}
					startDelay={startDelay}
					delay={0}
					scale={scale}
					isReverse={isReverse}
				/>
				<A
					duration={duration}
					startDelay={startDelay}
					delay={delay * 1}
					isReverse={isReverse}
					scale={scale}
				/>
				<TS
					duration={duration}
					startDelay={startDelay}
					delay1={delay * 2}
					isReverse={isReverse}
					scale={scale}
				/>
				<A
					duration={duration}
					startDelay={startDelay}
					delay={delay * 3}
					isReverse={isReverse}
					scale={scale}
				/>
			</Flex>
			<Flex gap={2 * scale * 5}>
				<D
					duration={duration}
					startDelay={startDelay}
					delay={delay * 3}
					isReverse={isReverse}
					scale={scale}
				/>
				<R
					duration={duration}
					startDelay={startDelay}
					delay={delay * 4}
					isReverse={isReverse}
					scale={scale}
				/>
				<I
					duration={duration}
					startDelay={startDelay}
					delay={delay * 5}
					isReverse={isReverse}
					scale={scale}
				/>
				<V
					duration={duration}
					startDelay={startDelay}
					delay={delay * 6}
					isReverse={isReverse}
					scale={scale}
				/>
				<LeftShift />
				<E
					duration={duration}
					startDelay={startDelay}
					delay={delay * 7}
					isReverse={isReverse}
					scale={scale}
				/>
				<N
					duration={duration}
					startDelay={startDelay}
					delay={delay * 8}
					isReverse={isReverse}
					scale={scale}
				/>
			</Flex>
			<Flex gap={2 * scale * 5}>
				<D
					duration={duration}
					startDelay={startDelay}
					delay={delay * 8}
					isReverse={isReverse}
					scale={scale}
				/>
				<E
					duration={duration}
					startDelay={startDelay}
					delay={delay * 9}
					isReverse={isReverse}
					scale={scale}
				/>
				<S
					duration={duration}
					startDelay={startDelay}
					delay={delay * 10}
					isReverse={isReverse}
					scale={scale}
				/>
				<I
					duration={duration}
					startDelay={startDelay}
					delay={delay * 11}
					isReverse={isReverse}
					scale={scale}
				/>
				<G
					duration={duration}
					startDelay={startDelay}
					delay={delay * 12}
					isReverse={isReverse}
					scale={scale}
				/>
				<N
					duration={duration}
					startDelay={startDelay}
					delay={delay * 13}
					isReverse={isReverse}
					scale={scale}
				/>
			</Flex>
		</Flex>
	);
};
