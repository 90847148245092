const img_thumb =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fproject%20syria%2Fthumb_project_syria.png?alt=media&token=21c2d1bf-1b63-49ab-874b-b93822db5415";
const img_thumb_square =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fproject%20syria%2Fthumb_project_syria_square.png?alt=media&token=747ca32f-6cbd-4409-81e7-c6762c80650f";
const img_title = "";
const img_ = "";

export const data_project_syria = {
	isCompleted: false,
	id: "project-syria",
	summary: {
		color: "#041418",
		order: 0,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: "Project Syria  - Interactive Data Journailsm",
		tags: ["Interactive Web", "Data Visualization", "Data Journalism"],
		industry: [""],
		outcomes: [""],
		tasks: [""],
		titleImg: img_title,
		summaryTitle: ``,
		summaryExp: ` `,
		link: ``,
	},
	sectionList: [
		{
			title: "",
			contentList: [
				{
					type: "text",
					content: "",
				},
			],
		},
	],
};
