import { Container } from "@mantine/core";

export const ContentColumn = ({ children, size='xl', h, bg = "", style, p=24 }) => {
	return (
		<Container
			w={"100%"}
			style={{ textAlign: "left", ...style }}
			bg={bg}
			h={h}
		>
			<Container size={size} h={'100%'} p={{ base: `0 ${p}`, sm: '0 0' }}>{children}</Container>
		</Container>
	);
};
