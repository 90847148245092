import { Container, Stack } from "@mantine/core";
import { TopSectionIdeas } from "../../template/pageTopSection/TopSectionIdeas";
import { StoryListSection } from "../../template/story/StoryListSection";
import { ProjectInquirySection } from "../../template/contact/ProjectInquirySection";
import { SectionStack } from "../../components/layout/stack/SectionStack";
import { BannerBrunch } from "../../components/banner/BannerBrunch";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { ContentStack } from "../../components/layout/stack/ContentStack";
import { Footer } from "../../components/navigation/Footer";

export const PageIdeas = ({ storyList }) => {
	return (
		<Container w={"100%"}>
			<SectionStack>
				<TopSectionIdeas />
				<StoryListSection storyList={storyList} bg={""} isBorder={true} />
				<ContentColumn>
					<ContentStack>
						<BannerBrunch />
					</ContentStack>
				</ContentColumn>
				<ProjectInquirySection />
			</SectionStack>
			<Footer/>
		</Container>
	);
};
