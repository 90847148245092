import { Stack, TextInput, Textarea, Text, Flex, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRef, useState } from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconAlertCircle } from "@tabler/icons-react";
import { useClipboard } from "@mantine/hooks";

// Email
import emailjs from "@emailjs/browser";

export const InquiryForm = ({ themeType }) => {
	const formRef = useRef(null);
	const form = useForm({
		initialValues: {
			email: "",
			company: "",
			projectDetail: "",
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const clipboard = useClipboard({ timeout: 500 });

	const onSendEmail = (values) => {
		setIsLoading(true);
		emailjs
			.sendForm("service_5bpurrd", "template_k7r1y6s", formRef.current, {
				publicKey: "V8RfYeEzlT15k5atf",
			})
			.then(
				() => {
					console.log("SUCCESS!");
					setIsLoading(false);
					notifications.show({
						color: "primary",
						title: "메일 전송 완료",
						message: `${values.email}에서 문의내용 확인이 가능합니다.\n답변은 48시간 내로 받으실 수 있습니다.`,
						icon: <IconCheck />,
					});
				},
				(error) => {
					console.log("FAILED...", error.text);
					setIsLoading(false);
					notifications.show({
						color: "red",
						title: "메일 전송 실패",
						message:
							"메일 전송이 실패했습니다. 인터넷 연결상태를 확인해주세요.",
						icon: <IconAlertCircle />,
					});
				}
			);
	};
	return (
		<form
			ref={formRef}
			onSubmit={form.onSubmit((values) => onSendEmail(values))}
		>
			{/* <Button
				onClick={() => {
					notifications.show({
						color: "primary",
						title: "메일 전송 완료",
						message: `groovelb@gmail.com 에서 문의내용 확인이 가능합니다.\n답변은 48시간 내로 받으실 수 있습니다.`,
						icon: <IconCheck />,
            autoClose: 999999999,

					});
				}}
			>
				test{" "}
			</Button> */}
			<Stack align="left" p={"32 0"} gap={24}>
				<TextInput
					{...form.getInputProps("email")}
					name="email"
					size="lg"
					label={"이메일"}
					placeholder="신청자분의 이메일 주소를 입력해주세요."
					variant={themeType === "dark" ? "dark" : "default"}
					required
				/>
				<TextInput
					{...form.getInputProps("company")}
					size="lg"
					label={"회사명"}
					name={"company"}
					placeholder="회사명을 입력해주세요."
					variant={themeType === "dark" ? "dark" : "default"}
					required
				/>
				<Textarea
					size="lg"
					label={"프로젝트 상세"}
					name={"projectDetail"}
					autosize
					minRows={4}
					maxRows={4}
					placeholder="의뢰하실 프로젝트의 내용을 적어주세요."
					variant={themeType === "dark" ? "dark" : "default"}
					required
				/>
				<Stack align="left" gap={16}>
					<Text>
						가이드 라인을 먼저 읽어보시면 더욱 구체적인 문의에 도움이됩니다.
					</Text>
					<Flex
						gap={8}
						direction={{ base: "column", md: "column" }}
						align={"center"}
					>
						<Button
							size="xl"
							color="secondary"
							style={{ flexGrow: 1 }}
							fullWidth
							type={"submit"}
							loading={isLoading}
						>
							프로젝트 문의메일 전송
						</Button>
						또는
						<Button
							size="xl"
							variant="filled"
							color="gray.1"
							c="gray.9"
							p={0}
							fullWidth
							onClick={()=> {
								clipboard.copy('contact@dddesign.io');
								notifications.show({
									color: "primary",
									title: "메일 주소 복사",
									message: `메일 주소(contact@dddesign.io)가 복사 되었습니다.`,
									icon: <IconCheck />,
								});
							}}
						>
							이메일 복사: contact@dddesign.io
						</Button>
					</Flex>
				</Stack>
			</Stack>
		</form>
	);
};
