import { useState } from "react";
import { Display } from "../../components/customTypo/Display";
import { Box, Flex, Space } from "@mantine/core";
import colors from "../../designTokens/colors";
import { CycleLine } from "../../stories/components/visualLangauge/CycleLine";
import styled from "@emotion/styled";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";

const LogoBox = styled(Box)`
	position: absolute;
	top: 24px;
	left: 483px;
`;

const Column = styled(Flex)`
	flex-direction: column;
	position: relative;
`;

const LogoBox2 = styled(Box)`
	position: absolute;
	top: 56px;
	left: -20px;
`;

export const PageTitleProject = ({ themeType }) => {
	const [isTrigger, setIsTrigger] = useState(false);
	return (
		<>
			<Column visibleFrom="md" c={themeType === "dark" ? "white" : "gray.9"}>
				<ViewportWrapper
					onEnterViewport={() => {
						console.log("enter project");
						setIsTrigger(true);
					}}
				>
					<TransitionWrapper delay={"0.2s"}>
						<Display
							order={2}
							align={"left"}
							bg={themeType === "dark" ? "gray.9" : "none"}
						>
							We&nbsp;&nbsp;Don't&nbsp;&nbsp;Design&nbsp;&nbsp;&nbsp;&nbsp;Just
							Product
						</Display>
					</TransitionWrapper>
					<TransitionWrapper delay={"0.8s"}>
						<Display
							order={2}
							align={"left"}
							bg={themeType === "dark" ? "gray.9" : "none"}
						>
							We&nbsp;&nbsp;Design&nbsp;&nbsp;Your
							<strong style={{ color: colors.blue[5] }}>
								&nbsp;&nbsp;&nbsp;&nbsp;Product Life Cycle
							</strong>
						</Display>
					</TransitionWrapper>
					<LogoBox>
						<CycleLine
							delay={1200}
							id={"pc"}
							color={themeType === "dark" ? colors.gray[0] : colors.gray[9]}
							isTrigger={isTrigger}
						/>
					</LogoBox>
				</ViewportWrapper>
			</Column>
			<Column hiddenFrom="md" c={themeType === "dark" ? "white" : "gray.9"}>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					We Don't Design
				</Display>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					<Space w={48} />
					Just Product,
					<Space w={12} />
					But
				</Display>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					<strong style={{ color: colors.blue[5] }}>Product Life Cycle</strong>
				</Display>
				<LogoBox2>
					<CycleLine
						color={themeType === "dark" ? colors.gray[0] : colors.gray[9]}
						scale={0.5}
						id={"mobile"}
						isTrigger={isTrigger}
					/>
				</LogoBox2>
			</Column>
		</>
	);
};
