import { useScrollIntoView } from "@mantine/hooks";
import { Container } from "@mantine/core";
import { SectionStack } from "../../components/layout/stack/SectionStack";
import { TopSectionMain } from "../../template/pageTopSection/TopSectionMain";
import { ProjectListSection } from "../../template/project/ProjectListSection";
import { StoryListSection } from "../../template/story/StoryListSection";
import { ProjectInquirySection } from "../../template/contact/ProjectInquirySection";
import { Footer } from "../../components/navigation/Footer";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { useViewPortLoaded } from "../../hook/useViewPortLoaded";
import { TopSectionMainVer2 } from "../../template/pageTopSection/TopSectionMainVer2";

export const PageMain = ({ projectList, storyList }) => {
	const { isLoaded } = useViewPortLoaded();
	let height = window.screen.height;
	const { scrollIntoView, targetRef } = useScrollIntoView({
		offset: 0,
	});

	const toInquiry = () => {
		scrollIntoView({
			alignment: 'center',
		})
	}

	return (
		<Container w={"100%"}>
			{isLoaded && (
				<>
					<SectionStack>
						<TopSectionMainVer2 onClickInquiry={toInquiry}/>
						<ProjectListSection projectList={projectList} />
						<StoryListSection storyList={storyList} />
					</SectionStack>
					<Container ref={targetRef}>
						<ProjectInquirySection />
					</Container>
					<Footer />
				</>
			)}
		</Container>
	);
};
