import { useEffect } from "react";
import { SetHeaderContext } from "../../context/headerState/SetHeaderContext";
import { Container } from "@mantine/core";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { ProjectSummary } from "../../template/project/ProjectSummary";
import { ProjectContent } from "../../template/project/ProjectContent";
import { SectionStack } from "../../components/layout/stack/SectionStack";
import { Footer } from '../../components/navigation/Footer';

export const PageProjectDetail = ({ projectDetail }) => {
	const { setHeaderTheme } = SetHeaderContext();

	useEffect(() => {
		setHeaderTheme("dark");
		return () => setHeaderTheme("light");
	}, []);

	return (
		<Container
			w={"100%"}
			c={"white"}
			bg={"gray.9"}
			style={{ position: "relative" }}
		>
			<ContentColumn size={'lg'}>
				<SectionStack>
					<ProjectSummary projectDetail={projectDetail} />
					<ProjectContent projectDetail={projectDetail} />
				</SectionStack>
			</ContentColumn>
			<Footer />
		</Container>
	);
};
