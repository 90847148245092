import {
	Flex,
	Paper,
	Stack,
	Badge,
	Title,
	Text,
	Box,
	List,
} from "@mantine/core";
import { CardProjectPreview } from "../project/CardProjectPreview.js";
// import { projectList } from "../../../data/dummy/projectList.js";
import { Image } from "@mantine/core";
import { useBreakPoint } from "../../../hook/useBreakPoint.js";
import { IconCircleCheckFilled } from "@tabler/icons-react";

import illust_track1 from "../../../assets/illust/track/illust_track-1.svg";
import illust_track2 from "../../../assets/illust/track/illust_track-2.svg";
import illust_track3 from "../../../assets/illust/track/illust_track-3.svg";
import illust_track4 from "../../../assets/illust/track/illust_track-4.svg";

export const CardTrackDetail = ({ order }) => {
	const contentList = [
		{
			title: `MVP Proposition & Develop`,
			tags: ["User Research", "Product Design", "Develop"],
			img: illust_track1,
			summary:
				"사업의 도메인은 정했지만 MVP방향성을 구체화하는데 어려움을 겪고있는초기 스타트업, 혹은 피보팅을 원하는 스타트업의 MVP 제품 기획.개발에 최적화된 트랙입니다.",
			customerList: [
				"MVP에서 검증하고자 하는 가설 설정과 설계에 어려움을 겪고 있는 팀.",
				"팀 빌딩이 완성되지 않은 초기 스타트업 프로덕트 팀.",
				"제품 개발과 리서치를 위한 리소스를 병행하기 힘든 프로덕트 팀.",
			],
			outcomeList: ["research insight", "user journeymap", "MPV product"],
		},
		{
			title: `UX Improvement & Redesign`,
			tags: ["User Research", "UX.UI Design", "Design System"],
			img: illust_track2,
			summary:
				"이미 출시된 제품의 사용성, KPI 개선을 위한 리디자인을 진행합니다. 기존의 사용자 데이터 분석 또는 리서치를 걸쳐 UX 업데이트 혹은 디자인 전반의 리뉴얼을 진행합니다.",
			customerList: [
				"기존 user의 행동을 정성,정량적으로 분석하고 싶은 팀.",
				"제품 개발과 리서치를 위한 리소스를 병행하기 힘든 프로덕트 팀.",
				"소수의 인력으로 체계화된 프로세스를 정착시키고 싶은 팀.",
			],
			outcomeList: ["research insight", "design system", "product"],
		},
		{
			title: `R&D & Interactive Content`,
			tags: ["Technical Research", "Prototyping", "Develop"],
			img: illust_track3,
			summary:
				"레퍼런스가 상대적으로 부족하거나 정형화 되지 않은 제품의 기획.개발에 적합합니다. R&D 및 프로토타이핑 과정을 통해 기획.디자인을 구체화하며 최종 개발까지 완료합니다.",
			customerList: [
				"만들고자 하는 제품의 기획.개발 프로세스에 대한 R&D가 필요한 경우.",
				"팀 빌딩이 완성되지 않은 초기 스타트업 프로덕트 팀.",
				"제품 개발과 리서치를 위한 리소스를 병행하기 힘든 프로덕트 팀.",
			],
			outcomeList: ["prototyping", "MVP product", "technical guideline"],
		},
		{
			title: `Product With Design System`,
			tags: ["UX.UI Design", "Design System", "Develop"],
			img: illust_track4,
			summary:
				"제품 기획은 어느정도 구체화 되었지만 초기 제품을 개발하는 과정에서 자연스럽게 효율적인 리팩토링, 업데이트 싸이클 환경을 구축하기 위한 목적에 최적화된 트랙입니다.",
			customerList: [
				"팀 빌딩이 완성되지 않은 초기 스타트업 프로덕트 팀.",
				"디자인.개발 레거시로 인한 업데이트 비효율성을 개선시키고 싶은 팀.",
				"소수의 인력으로 체계화된 프로세스를 정착시키고 싶은 팀.",
			],
			outcomeList: ["product", "deisgn system guide", "technical guideline"],
		},
	];

	// const relatedProjectList = [
	// 	{
	// 		title: projectList[1].title,
	// 		tags: projectList[1].tags,
	// 		img: projectList[1].imgSquare,
	// 	},
	// 	{
	// 		title: projectList[2].title,
	// 		tags: projectList[2].tags,
	// 		img: projectList[2].imgSquare,
	// 	},
	// 	{
	// 		title: projectList[5].title,
	// 		tags: projectList[5].tags,
	// 		img: projectList[5].imgSquare,
	// 	},
	// 	{
	// 		title: projectList[3].title,
	// 		tags: projectList[3].tags,
	// 		img: projectList[3].imgSquare,
	// 	},
	// ];

	const { breakPoint, getResponsiveValue, isLoaded } = useBreakPoint();
	const titleOrder = {
		xs: 4,
		sm: 4,
		md: 2,
		lg: 2,
		xl: 2,
	};

	const title2Order = {
		xs: 5,
		sm: 5,
		md: 4,
		lg: 4,
		xl: 4,
	};

	return (
		<Paper
			shadow="lg"
			w={"100%"}
			bg={"datumWhite.0"}
			style={{ borderRadius: "8px", overflow: "hidden" }}
		>
			<Flex w={"100%"} direction={{ base: "column", md: "row" }} gap={12}>
				<Image
					w={{ base: "100%", md: "576px" }}
					h={"fit-content"}
					src={contentList[order].img}
					alt={""}
					fit="cover"
					visibleFrom="md"
				/>
				<Flex
					p={{ base: "24", md: 72 }}
					gap={{ base: 16, md: 32 }}
					direction={"column"}
					justify={"center"}
					align={"start"}
					bg={"white"}
					style={{ flexGrow: 1 }}
					w={"100%"}
				>
					<Flex
						direction={{ base: "column-reverse", md: "column" }}
						gap={8}
						w={"100%"}
						mb={'xl'}
					>
						<Box w={"calc(100% + 24px)"} className="hideScroll">
							<Flex direction={"row"} gap={4} w={"fit-content"}>
								<Badge variant="filled" size="lg">
									{`track-${order + 1}`}
								</Badge>
								{contentList[order].tags.map((tag, i) => (
									<Badge color={"gray"} variant="outline" size="lg" key={i}>
										{tag}
									</Badge>
								))}
							</Flex>
						</Box>
						<Title
							align={"left"}
							order={getResponsiveValue(titleOrder, breakPoint)}
						>
							{contentList[order].title}
						</Title>
					</Flex>
					<Stack gap={8} w={"100%"}>
						<Title
							align={"left"}
							order={getResponsiveValue(title2Order, breakPoint)}
						>
							TRACK CUSTOMER
						</Title>
						<List
							icon={<IconCircleCheckFilled size={20} fill="primary" />}
							style={{ wordBreak: "keep-all" }}
						>
							{contentList[order].customerList.map((customer, i) => (
								<List.Item>{customer}</List.Item>
							))}
						</List>
					</Stack>
					<Stack gap={8} w={"100%"}>
						<Title
							align={"left"}
							order={getResponsiveValue(title2Order, breakPoint)}
						>
							TRACK OUTCOMES
						</Title>
						{/* <Flex direction={"row"} gap={8}>
							{contentList[order].outcomeList.map((outcome, i) => (
								<Badge
									variant="filled"
									color={"ultramarine.6"}
									size="xl"
									key={i}
								>
									{outcome}
								</Badge>
							))}
						</Flex> */}
						<List
							icon={<IconCircleCheckFilled size={20} fill="primary" />}
							style={{ wordBreak: "keep-all" }}
						>
							{contentList[order].outcomeList.map((outcome, i) => (
								<List.Item>{outcome}</List.Item>
							))}
						</List>
					</Stack>
					{/* <CardProjectPreview
						title={relatedProjectList[order].title}
						tags={relatedProjectList[order].tags}
						img={relatedProjectList[order].img}
					/> */}
				</Flex>
			</Flex>
		</Paper>
	);
};
