import { useState, useRef } from "react";
import { Box, Flex, Stack, Image } from "@mantine/core";
import { Title } from "@mantine/core";
import { Chip } from "@mantine/core";
import { Text } from "@mantine/core";
import ic_brunch from "../../../assets/icon/social/icon_social_brunch_black.svg";
import { useBreakPoint } from "../../../hook/useBreakPoint";
import ViewportWrapper from "../../triggerWrapper/ViewportWrapper";
import { DatumTransitionSpace } from "../../space/DatumTransitionSpace";
import { FadeIn } from "../../triggerWrapper/FadeIn";
import { Link } from "react-router-dom";

export const CardStory = ({
	title,
	tags,
	img,
	summary,
	delay,
	color,
	id,
	isCompleted,
	to
}) => {
	const { breakPoint, getResponsiveValue } = useBreakPoint();

	let lineClamp = {
		xs: 3,
		sm: 4,
		md: 4,
		lg: 5,
		xl: 5,
	};
	let imgRef = useRef(null);
	const [isTrigger, setIsTrigger] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<ViewportWrapper
			onEnterViewport={() => {
				setTimeout(() => {
					setIsTrigger(true);
				}, delay);
			}}
		>
			<Link to={to} target="_blank">
				<Flex direction={"column"} align={"start"} gap={8} bg={"white"}>
					<Box style={{ position: "relative", zIndex: 9 }}>
						<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.3}s`}>
							{!isCompleted && (
								<Box
									bg={color}
									c={"white"}
									p={8}
									style={{
										position: "absolute",
										bottom: 0,
										left: 0,
										backgroundColor: color,
										opacity: 0.75,
									}}
								>
									<Text size={"lg"} style={{ letterSpacing: 1 }} fw={700}>
										comming soon
									</Text>
								</Box>
							)}
							<Image
								ref={imgRef}
								w={"100%"}
								h={"fit-content"}
								src={img}
								alt={""}
								onLoad={() => {
									setIsLoaded(true);
								}}
							/>
						</FadeIn>
						<Box
							w={imgRef.current?.offsetWidth}
							h={imgRef.current?.offsetHeight}
							style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
						>
							{isLoaded && (
								<DatumTransitionSpace
									width={imgRef.current?.offsetWidth}
									height={imgRef.current?.offsetHeight}
									x={24}
									y={20}
									isTrigger={isTrigger}
									color={color}
									id={id}
									delay={200}
								/>
							)}
						</Box>
					</Box>

					<Stack p={16}>
						<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.4}s`}>
							<Flex direction={"row"} justify={"space-between"}>
								<Flex direction={"row"} gap={4}>
									{tags.map((tag, i) => (
										<Chip variant="outline" size="sm" key={i}>
											{tag}
										</Chip>
									))}
								</Flex>
								<img src={ic_brunch} alt="" />
							</Flex>
						</FadeIn>
						<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.5}s`}>
							<Title align={"left"} order={4}>
								{title}
							</Title>
						</FadeIn>
						<FadeIn isTrigger={isTrigger} delay={`${delay / 1000 + 0.5}s`}>
							<Text
								align={"left"}
								lineClamp={getResponsiveValue(lineClamp, breakPoint)}
							>
								{summary}
							</Text>
						</FadeIn>
					</Stack>
				</Flex>
			</Link>
		</ViewportWrapper>
	);
};
