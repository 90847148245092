// Hook
import { useEffect, useState, useRef } from "react";
import { useWindowScroll } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { useBreakPoint } from "../../hook/useBreakPoint";

// Basic UI
import { Box, Stack, Flex, Image } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { TextInput, Textarea, Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { Paper } from "@mantine/core";

// Custom UI
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { ContentStack } from "../../components/layout/stack/ContentStack";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { SetHeaderContext } from "../../context/headerState/SetHeaderContext";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";
import { InquiryForm } from "./InquiryForm";
import { ConsultForm } from "./ConsultForm";

// Assets
import illust_guide_mobile from "../../assets/illust/inquiry/diagram_guide_mobile.svg";
import illust_guide_pc from "../../assets/illust/inquiry/diagram_guide_pc.svg";

export const ProjectInquirySection = ({ themeType = "dark" }) => {
	const tabList = [
		{
			value: "project",
			title: "프로젝트 문의",
		},
		{
			value: "consulting",
			title: "무료 컨설팅 문의",
		},
	];

	const { breakPoint, getResponsiveValue } = useBreakPoint();
	const { setHeaderTheme } = SetHeaderContext();
	const { pathname } = useLocation();

	let titleOrder = {
		xs: 2,
		sm: 2,
		md: 1,
		lg: 1,
		xl: 1,
	};

	let borderWidth = {
		xs: 0,
		sm: 0,
		md: 1,
		lg: 1,
		xl: 1,
	}

	const targetRef = useRef(null);
	const [position, setPosition] = useState({ top: 0, bottom: 0 });
	const [scroll, scrollTo] = useWindowScroll();

	useEffect(() => {
		let y = targetRef.current?.offsetTop;
		let height = targetRef.current?.clientHeight;
		if (-height < y - scroll.y && y - scroll.y < 24) {
			setHeaderTheme("dark");
		} else {
			if (pathname !== "/projects") {
				setHeaderTheme("light");
			}
		}
	}, [scroll]);

	return (
		<Box ref={targetRef}>
			<ViewportWrapper>
				<FullPageContainer bg={"gray.9"} scale={1.2}>
					<ContentColumn style={{ padding: "120px 0px" }}>
						<ContentStack>
							{/* <Title order={getResponsiveValue(titleOrder, breakPoint)} c={"white"}>
						Always Open To
						<br />
						Any Inquiry
					</Title> */}
							<SectionTitle
								c={"white"}
								title={`Always Open To\nAny Inquiry`}
								isBorder={false}
							/>
							{/* Grid */}
							<TransitionWrapper delay={"0s"}>
								<Paper
									radius={"lg"}
									shadow="xl"
									bg={themeType === "dark" ? "gray.9" : "white"}
									c={themeType === "dark" ? "white" : "gray.9"}
									style={{
										overflow: "hidden",
										borderWidth: getResponsiveValue(borderWidth, breakPoint),
										borderColor: "white",
										borderStyle: "solid",
									}}
									ml={{ base: -24, md: 0 }}
									w={{ base: "calc(100% + 48px)", md: "100%" }}
								>
									<Flex direction={{ base: "column-reverse", md: "row" }}>
										{/* Left / Top */}
										<Box
											w={{ base: "100%", md: "50%" }}
											align={"left"}
											p={{ base: "16 24", md: "24 48" }}
										>
											<Tabs
												defaultValue={tabList[0].value}
												color={"secondary"}
												variant={themeType === "dark" ? "default" : "default"}
											>
												<Tabs.List>
													{tabList.map((tab, i) => (
														<Tabs.Tab
															value={tab.value}
															size={"xl"}
															key={i}
															w={"50%"}
														>
															{tab.title}
														</Tabs.Tab>
													))}
												</Tabs.List>
												{/* inquiry Form */}
												<Tabs.Panel value={tabList[0].value}>
													<InquiryForm themeType={themeType} />
												</Tabs.Panel>
												{/* consulting Form */}
												<Tabs.Panel value={tabList[1].value}>
													<ConsultForm themeType={themeType} />
												</Tabs.Panel>
											</Tabs>
										</Box>
										{/* Right Bottom */}
										<Box
											w={{ base: "100%", md: "50%" }}
											align={"left"}
											p={{ base: "16 24", md: "24 48" }}
											bg={"secondary"}
										>
											<Flex
												direction={"column"}
												align={"center"}
												justify={"center"}
												p={0}
												h={{ base: "auto", sm: "100%" }}
											>
												<Image
													hiddenFrom="md"
													src={illust_guide_mobile}
													w={"100%"}
													h={"auto"}
													alt=""
												/>
												<Image
													visibleFrom="md"
													src={illust_guide_pc}
													w={"100%"}
													h={"auto"}
													alt=""
												/>
											</Flex>
										</Box>
									</Flex>
								</Paper>
							</TransitionWrapper>
						</ContentStack>
					</ContentColumn>
				</FullPageContainer>
			</ViewportWrapper>
		</Box>
	);
};
