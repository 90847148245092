// Brand Visual Langauge
export const path_t = `M24 3L48 45H0L24 3Z`;
export const path_s = `M0 0H48V48H0V0Z`;
export const path_c = `M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z`;
export const path_d = `M47.8533 21.3333C47.65 19.4833 47.2333 17.6967 46.6333 16C45.9633 14.1 45.06 12.31 43.9567 10.6667C43.5433 10.0467 43.1033 9.45 42.6333 8.87667C41.5767 7.57667 40.3867 6.38667 39.0833 5.33333C38.5133 4.87 37.9167 4.43333 37.3033 4.02333C35.66 2.92667 33.8733 2.02667 31.9733 1.36C30.2767 0.76 28.4933 0.346667 26.6467 0.146667C25.7767 0.05 24.8933 0 24 0H0V48H24C24.8933 48 25.7767 47.95 26.6467 47.8533C28.4933 47.6533 30.2767 47.24 31.9733 46.6433C33.8733 45.9733 35.66 45.0733 37.3033 43.9767C37.9167 43.5667 38.5133 43.13 39.0833 42.6667C40.3867 41.6133 41.5767 40.4267 42.6333 39.1267C43.1 38.55 43.5433 37.9533 43.9567 37.3333C45.06 35.69 45.9633 33.9 46.6333 32C47.2333 30.3033 47.65 28.5167 47.8533 26.6667C47.95 25.79 48 24.9 48 24C48 23.1 47.95 22.21 47.8533 21.3333Z`;

// Main Top Section BG Pattern
export const loopCycle1 = `M0 60H360C459.411 60 540 140.589 540 240C540 339.411 620.589 420 720 420C819.411 420 900 339.411 900 240C900 140.589 980.589 60 1080 60H1440`;
export const loopCycle2 = `M1440 420H1080C980.589 420 900 339.411 900 240C900 140.589 819.411 60 720 60C620.589 60 540 140.589 540 240C540 339.411 459.411 420 360 420H0`;

// About Top Section BG pattern
export const topCirclePath = `M348 196C348 288.784 272.784 364 180 364C87.2162 364 12 288.784 12 196C12 103.216 87.2162 28 180 28C272.784 28 348 103.216 348 196Z`;
export const bottomCirclePath = `M348 380C348 472.784 272.784 548 180 548C87.2162 548 12 472.784 12 380C12 287.216 87.2162 212 180 212C272.784 212 348 287.216 348 380Z`;

// Vision Path
export const eye_path = `M39.4069 288C69.4188 242.229 121.179 212 180 212C238.821 212 290.581 242.229 320.593 288C290.581 333.771 238.821 364 180 364C121.179 364 69.4188 333.771 39.4069 288Z`;
export const pupil_path = `M200 288C200 299.046 191.046 308 180 308C168.954 308 160 299.046 160 288C160 276.954 168.954 268 180 268C191.046 268 200 276.954 200 288Z`;

// Cycle Line Path
export const cycleLinePath = `M121.1,16h454.8c41.5,0,75.1,33.8,75.1,75.5c0,41.7-33.6,75.5-75.1,75.5H239.7H91.1C49.6,167,16,133.2,16,91.5
C16,49.8,49.6,16,91.1,16h8.3`;

// Bulb Path
export const bulbBodyPath = `M114,78c0,19.9-16.1,36-36,36S42,97.9,42,78s16.1-36,36-36S114,58.1,114,78z`;
export const bulbBottomPath = `M60 114H96V132C96 141.941 87.9411 150 78 150C68.0589 150 60 141.941 60 132V114Z`;
export const bulbLight1 = `M6,78h18`;
export const bulbLight2 = `M26.5,27.8l12.7,12.7`;
export const bulbLight3 = `M132,78h18`;
export const bulbLight4 = `M116.8,40.5l12.7-12.7`;
export const bulbLight5 = `M79,24V6`;
