import React from "react";
import { Box, Flex } from "@mantine/core";
import styled from "@emotion/styled";

const Container = styled(Flex)`
	span {
		font-weight: 900;
	}
`;

export const Display = ({ children, order, c, bg, p = 0, fw, style }) => (
	<Container
		c={c}
		bg={bg}
		p={p}
		// w={'100%'}
		style={(theme) => {
			let font = {
				fontSize: null,
				fontWeight: null,
				lineHeight: null,
			};
			switch (order) {
				case 1:
					font.fontSize = theme.other.displayHeadings.d1.fontSize;
					font.fontWeight = theme.other.displayHeadings.d1.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d1.lineHeight;
					break;
				case 2:
					font.fontSize = theme.other.displayHeadings.d2.fontSize;
					font.fontWeight = theme.other.displayHeadings.d2.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d2.lineHeight;
					break;
				case 3:
					font.fontSize = theme.other.displayHeadings.d3.fontSize;
					font.fontWeight = theme.other.displayHeadings.d3.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d3.lineHeight;
					break;
				case 4:
					font.fontSize = theme.other.displayHeadings.d4.fontSize;
					font.fontWeight = theme.other.displayHeadings.d4.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d4.lineHeight;
					break;
				case 5:
					font.fontSize = theme.other.displayHeadings.d5.fontSize;
					font.fontWeight = theme.other.displayHeadings.d5.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d5.lineHeight;
					break;
				default:
					font.fontSize = theme.other.displayHeadings.d1.fontSize;
					font.fontWeight = theme.other.displayHeadings.d1.fontWeight;
					font.lineHeight = theme.other.displayHeadings.d1.lineHeight;
					break;
			}
			return {
				fontFamily: theme.other.displayHeadings.fontFamily,
				fontSize: font.fontSize,
				fontWeight: fw ? fw : font.fontWeight,
				lineHeight: font.lineHeight,
				textAlign: "left",
				textTransform: "capitalize",
				width: "fit-content",
				...style,
			};
		}}
	>
		{children}
	</Container>
);

Display.defaultProps = {
	order: 1,
};
