const img_thumb =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Faegis-network-monitoring-system%2Fthumb_aegis.png?alt=media&token=cd60ebcd-8f5a-425f-9535-9962b91cc946";
const img_thumb_square =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Faegis-network-monitoring-system%2Fthumb_aegis_square.png?alt=media&token=2caf931f-4942-4e1a-b494-75635dca16c2";
const img_title = "";
const img_ = "";

export const data_aegis = {
	isCompleted: false,
	id: "aegis-monitoring-system",
	summary: {
		color: "#002035",
		order: 2,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: `Aegis Connect - Monitoring Solution Prototyping`,
		tags: ["Monitoring System", "Data Visualization", "Prototyping"],
		industry: [""],
		outcomes: [""],
		tasks: [""],
		titleImg: img_title,
		summaryTitle: ``,
		summaryExp: ` `,
		link: ``,
	},
	sectionList: [
		{
			title: "",
			contentList: [
				{
					type: "text",
					content: "",
				},
			],
		},
	],
};
