import { LogoTypeUnitSingle } from "./LogoTypeUnitSingle";
import { LogoTypeUnitDouble } from "./LogoTypeUnitDouble";
import {
	path_D,
	path_D_reverse,
	path_a,
	path_t_1,
	path_t_2,
	path_t,
	path_r,
	path_i,
	path_v,
	path_e,
	path_g,
	path_n,
	path_s,
	path_o,
	path_2,
	path_u,
	path_u_2,
	path_o_filled,
	path_u_serif,
} from "../../../data/logoTypePathData";

export const D = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_D_reverse}
			strokeWidth={72}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			pathClassName={pathClassName}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const A = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_a}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const T = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_t}
			width={96}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const TS = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_t}
			width={80}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const R = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_r}
			delay={delay}
			startDelay={startDelay}
			width={80}
			strokeWidth={48}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const I = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_i}
			width={48}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const V = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_v}
			width={144}
			strokeWidth={44}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const E = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_e}
			width={144}
			strokeWidth={40}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const G = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_g}
			width={144}
			strokeWidth={40}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const N = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_n}
			width={144}
			strokeWidth={56}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};
export const S = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_s}
			width={144}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			pathClassName={pathClassName}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const O = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_o}
			width={144}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			pathClassName={pathClassName}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const O2 = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_o_filled}
			width={144}
			strokeWidth={72}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			pathClassName={pathClassName}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const U = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_u_serif}
			width={144}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			pathClassName={pathClassName}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};

export const U2 = ({
	startDelay = 0,
	delay = 0,
	scale = 1,
	isReverse,
	pathClassName,
	color,
	duration = 600,
	ease,
	isTrigger = true,
}) => {
	return (
		<LogoTypeUnitSingle
			data={path_u_2}
			width={144}
			strokeWidth={48}
			delay={delay}
			startDelay={startDelay}
			scale={scale}
			isReverse={isReverse}
			startWithReserve
			pathClassName={pathClassName}
			color={color}
			isTrigger={isTrigger}
			duration={duration}
			ease={ease}
		/>
	);
};
