import * as d3 from "d3";
import { useRef, useEffect } from "react";

export const PathTypeUnitDouble = ({
	data1,
	data2,
	width,
	height,
	strokeWidth,
	strokeWidth2 = strokeWidth,
	strokeColor,
	delay1 = 0,
	delay2 = 300,
	scale = 1,
	duration = 900,
}) => {
	let svgRef = useRef(null);

	// initialize SVG
	useEffect(() => {
		const svg = d3.select(svgRef.current);
		const g = svg
			.attr("width", width * scale)
			.attr("height", height * scale)
			.append("g")
			.attr("transform", `translate(0,0) scale(${scale})`);

		const path1 = g
			.append("path")
			.attr("d", data1)
			.attr("fill", "none")
			.attr("stroke-width", strokeWidth)
			.attr("stroke", strokeColor);

		const length1 = path1.node().getTotalLength();

		const path2 = g
			.append("path")
			.attr("d", data2)
			.attr("fill", "none")
			.attr("stroke-width", strokeWidth2)
			.attr("stroke", strokeColor);

		const length2 = path1.node().getTotalLength();
		repeat();

		function repeat() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			path1
				.attr("stroke-dasharray", length1 + " " + length1)
				.attr("stroke-dashoffset", length1)
				.transition()
				.delay(delay1)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", 0)
				.duration(duration);

			path2
				.attr("stroke-dasharray", length2 + " " + length2)
				.attr("stroke-dashoffset", length2)
				.transition()
				.delay(delay1 + delay2)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", 0)
				.duration(duration);
		}
	}, []);

	return <svg ref={svgRef} />;
};
