import { Container } from "@mantine/core";
import { Stack } from "@mantine/core";
import { TopSectionAbout } from "../../template/pageTopSection/TopSectionAbout";
import { TrackSection } from "../../template/track/TrackSection";
import { AboutFnQSection } from "../../template/about/AboutFnQSection";
import { ProjectInquirySection } from "../../template/contact/ProjectInquirySection";
import { Footer } from "../../components/navigation/Footer";
import { SectionStack } from "../../components/layout/stack/SectionStack";

export const PageAbout = ({}) => {
	return (
		<Container w={"100%"}>
			<SectionStack>
				<Stack gap={0}>
					<TopSectionAbout />
					<TrackSection />
				</Stack>
				<AboutFnQSection />
				<ProjectInquirySection />
			</SectionStack>
			<Footer />
		</Container>
	);
};
