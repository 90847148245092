import { dbService } from "../firebaseInstance";
import { useEffect, useState } from "react";

export const FetchProjectList = () => {
  const [projectList, setProjectList] = useState([]);
  
  const fetchData = () => {
		try {
			dbService.collection("projectList").onSnapshot((snapshot) => {
				let tempList = [];
				snapshot.docs.forEach((doc) => {
					// project data formatting
					let project = doc.data();
					// project.id = doc.data().id;
          // project.isCompleted = doc.data().isCompleted;
					tempList.push(project);
				});
				// console.log(tempList);
				let list = tempList.sort((a, b) => {
					return b.summary.order - a.summary.order;
				});
				console.log(list)
				setProjectList(list);
			});
		} catch {
		} finally {
			// console.log('finally');
		}
	};

  useEffect(() => {
    fetchData();
  },[])

  return {projectList};
}