module.exports = {
	displayHeadings: {
		// Title With Brand Fonts (only English)
		fontFamily: `"poppins", "Pretendard Variable", sans-serif`,
		d1: {
			fontWeight: 200,
			fontSize: "4.75rem",
			lineHeight: 1.32,
		},
		d2: {
			fontWeight: 200,
			fontSize: "3.75rem",
			lineHeight: 1.32,
		},
		d3: {
			fontWeight: 200,
			fontSize: "3rem",
			lineHeight: 1.32,
		},
		d4: {
			fontWeight: 200,
			fontSize: "2rem",
			lineHeight: 1.32,
		},
		d5: {
			fontWeight: 200,
			fontSize: "1.75rem",
			lineHeight: 1.28,
		},
	},
	size: {
		xs: "30rem",
		sm: "48rem",
		md: "64rem",
		lg: "74rem",
		xl: "90rem",
		topSectionHeight: "36rem"
	}
};