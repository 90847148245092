import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { ContentStack } from "../../components/layout/stack/ContentStack";
import { CardProject } from "../../components/card/project/CardProject";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Display } from "../../components/customTypo/Display";
import { Box, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import other from "../../designTokens/other";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { SectionTitleProject } from "../pageTopSection/SectionTitleProject";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";

export const ProjectListSection = ({
	projectList,
	isDark = false,
	isTitle = true,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${other.size.sm})`);

	return (
		<ContentColumn>
			<ContentStack>
				{isTitle && <SectionTitleProject themeType={"light"} />}
				<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
					<Masonry gutter={isMobile ? "48px" : "64px"}>
						{projectList.map((project, i) => (
							<>
								{i === 1 && !isMobile && <Box p={0} h={0} key={`${i}_p`} />}
									<CardProject
										isDark={isDark}
										key={i}
										title={project.summary.title}
										img={project.summary.thumb}
										tags={project.summary.tags}
										to={project.isCompleted?`/project/${project.id}`:'#'}
										id={i}
										delay={isMobile?0:(i%3)*300 + 200}
										color={project.color}
										isCompleted={project.isCompleted}
									/>
							</>
						))}
					</Masonry>
				</ResponsiveMasonry>
			</ContentStack>
		</ContentColumn>
	);
};
