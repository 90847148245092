const img_thumb =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fpenellagen%2Fthumb_penellagen.png?alt=media&token=4b427e2f-bb0e-45ce-8194-602cc87f34ab";
const img_thumb_square =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fpenellagen%2Fthumb_penellagen_square.png?alt=media&token=556c203f-7fd6-4af0-a941-81bdeed6c794";
const img_title = "";
const img_ = "";

export const data_penellagen = {
	isCompleted: false,
	id: "penellagen-interactive",
	summary: {
		color: "#b7bcc1",
		order: 1,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: `Penelagen Interactive Brand Website`,
		tags: ["Interactive Web", "Design System", "Brand Storytelling"],
		industry: [""],
		outcomes: [""],
		tasks: [""],
		titleImg: img_title,
		summaryTitle: ``,
		summaryExp: ` `,
		link: ``,
	},
	sectionList: [
		{
			title: "",
			contentList: [
				{
					type: "text",
					content: "",
				},
			],
		},
	],
};
