import { LogoSymbol } from "../logoType/logo/LogoSymbol";
import { IdeaBulb } from "../../stories/components/visualLangauge/IdeaBulb";
import ic_brunch from "../../assets/icon/social/icon_social_brunch_black.svg";
import { Container } from "@mantine/core";
import { Display } from "../customTypo/Display";
import { Stack, Flex, Image, Box } from "@mantine/core";
import colorsVariants from "../../designTokens/colorsVariants";
import { GridBG } from "../../visualization/grid/GridBG";
import { useBreakPoint } from "../../hook/useBreakPoint";

export const BannerBrunch = () => {
	const { breakPoint, getResponsiveValue, isLoaded } = useBreakPoint();
	const titleOrder = {
		xs: 4,
		sm: 4,
		md: 3,
		lg: 3,
		xl: 3,
	};
	const scale = {
		xs: 0.5,
		sm: 0.5,
		md: 1,
		lg: 1,
		xl: 1,
	};

	const scaleBulb = {
		xs: 1.1,
		sm: 1.1,
		md: 1.5,
		lg: 1.5,
		xl: 1.5,
	};

	return (
		<Container
			w={"100%"}
			p={48}
			bg={"secondary"}
			style={{ borderRadius: "48px", position: "relative", overflow: "hidden" }}
		>
			{isLoaded && (
				<>
					<Box style={{ position: "absolute", top: 0, left: 0, opacity: 0.5 }}>
						<GridBG x={30} y={8} unit={48} fill={colorsVariants.highlight[0]} />
					</Box>
					<Flex
						direction={{ base: "column", md: "row" }}
						w={"100%"}
						justify={"space-between"}
						style={{ position: "relative", zIndex: 9 }}
					>
						<Stack>
							<Display
								order={getResponsiveValue(titleOrder, breakPoint)}
								c={"white"}
							>
								Check Out More Ideas On Brunch
							</Display>
							<Flex gap={4}>
								<LogoSymbol
									color={"#000000"}
									delay={800}
									scale={getResponsiveValue(scale, breakPoint)}
								/>
								<Image
									bg={"white"}
									radius={144 * getResponsiveValue(scale, breakPoint)}
									w={"auto"}
									h={144 * getResponsiveValue(scale, breakPoint)}
									src={ic_brunch}
									alt=""
								/>
							</Flex>
						</Stack>
						<Box mt={{ base: 16, md: 0 }}>
							<IdeaBulb
								strokeColor={colorsVariants.highlight[0]}
								scale={getResponsiveValue(scaleBulb, breakPoint)}
							/>
						</Box>
					</Flex>
				</>
			)}
		</Container>
	);
};
