import { useEffect, useState } from "react";
import { storyDataList } from '../../data/dummy/storyList';
import { dbService } from "../../firebaseInstance";
import { Container, Button } from "@mantine/core";
import { SectionStack } from "../../components/layout/stack/SectionStack";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { Select } from "@mantine/core";


export const PageStoryUpload = ({}) => {
	const list = [];
	const [storyList, setStoryList] = useState([]);
	const [selectedStory, setSelectedStory] = useState({});
	useEffect(() => {
		storyDataList.forEach((data, i) => {
			list.push(data.id);
		});

		setStoryList(list);
	}, []);

	const onSelectStory = (id) => {
		let story = storyDataList.filter((p) => {
			return p.id === id;
		})[0];

		setSelectedStory(story);
    console.log(story);
	};

	const submitProject = (body) => {
		dbService
			.collection("storyList")
			.doc(body.id)
			.set(body)
			.then(async () => {
				window.alert("스토리가 업로드 되었습니다");
			});
	};

	return (
		<Container w={"100%"} pt={200}>
			<ContentColumn>
				<SectionStack>
					<Select
						label="story list"
						data={storyList}
						onSelect={(e) => {
							onSelectStory(e.target.value);
						}}
					/>
					<Button
						size={"xl"}
						onClick={() => {
							submitProject(selectedStory);
						}}
					>
						스토리 업로드
					</Button>
					{selectedStory?.id}
				</SectionStack>
			</ContentColumn>
		</Container>
	);
};
