import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { CardStory } from "../../components/card/story/CardStory";
import { ContentStack } from "../../components/layout/stack/ContentStack";
import { useMediaQuery } from "@mantine/hooks";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { Grid, Paper } from "@mantine/core";
import { useBreakPoint } from "../../hook/useBreakPoint";
import colors from "../../designTokens/colors";
import other from "../../designTokens/other";

export const StoryListSection = ({
	storyList,
	bg = "gray.1",
	isBorder = false,
}) => {
	const { breakPoint, getResponsiveValue } = useBreakPoint();
	const isMobile = useMediaQuery(`(max-width: ${other.size.sm})`);
	return (
		<ContentColumn
			// bg={bg}
			style={{ paddingTop: "48px", paddingBottom: "64px" }}
		>
			<ContentStack>
				<SectionTitle
					title={"Knowledge, case study, experiment"}
					isBorder={true}
				/>
				<Grid gutter={32}>
					{storyList.length !== 0 &&
						storyList.map((story, i) => (
							<Grid.Col
								span={{ base: 12, xs: 12, sm: 6, md: 4, lg: 4 }}
								key={i}
							>
								<Paper
									shadow="lg"
									style={{ borderRadius: "16px", overflow: "hidden" }}
								>
									<CardStory
										title={story.title}
										tags={story.tags}
										img={story.img}
										summary={story.summary}
										delay={isMobile ? 0 : (i % 3) * 300 + 200}
										id={`${i}_story`}
										color={colors.blue[6]}
										isCompleted={story.isCompleted}
										to={story.isCompleted?story.link:'#'}
									/>
								</Paper>
							</Grid.Col>
						))}
				</Grid>
				{/* <BannerBrunch /> */}
			</ContentStack>
		</ContentColumn>
	);
};
