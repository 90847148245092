import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { Stack, Box, Text, Flex } from "@mantine/core";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { TrackChart } from "./TrackChart";
import { TrackDetailTabs } from "./TrackDetailTabs";
import { HighlightedP } from "../../components/customTypo/HighlightedP";
import { ContentStack } from "../../components/layout/stack/ContentStack";

export const TrackSection = ({}) => {
	const expList = [
		"모든 문제해결 과정에는 방향성이 존재합니다. ‘프로젝트 전, 중, 후 에 걸쳐 펼쳐지는 변화의 연속성이 충분히 구체화되었는가’ 라는 질문에 자신있게 답변할 수 있으신가요? DDD는 많은 초기 스타트업 혹은 전문 도메인 및 리서치의 자문이 필요한 팀들이 겪고있는 문제점을 크게 4가지 트랙으로 분류하였습니다.",
		"조직의 규모와 특성, 시장의 변화, 도메인의 특성 등 문제해결 과정에 미치는 요소들은 정말 많습니다. 하지만 신기하게도 여러분이 겪고있는 장벽들에는 몇가지 패턴들이 존재합니다. 다양한 클라이언트 조직에 깊게 스며들어 프로젝트를 성공적으로 완료한 DDD의 경험을 통해 여러분의 vision을 좀더 구체화해보세요.",
	];

	const firstLetterStyle = {
		fontSize: "2rem",
		fontWeight: 800,
		color: "blue.6",
	};
	
	return (
		<>
			<ContentColumn>
				<ContentStack>
					<SectionTitle title={"Before Start Your Project"} />
					<Flex direction={{ base: "column", md: "row" }} gap={48}>
						<Box>
							<Text>
								<HighlightedP style={firstLetterStyle}>
									{expList[0]}
								</HighlightedP>
							</Text>
						</Box>
						<Box>
							<Text>
								<HighlightedP>{expList[1]}</HighlightedP>
							</Text>
						</Box>
					</Flex>
					<TrackChart />
				</ContentStack>
			</ContentColumn>
			<TrackDetailTabs />
		</>
	);
};
