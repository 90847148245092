import { Flex, Box } from "@mantine/core";
import { Title, Text, Image } from "@mantine/core";

import illust_insight from "../../../assets/icon/dataValue/insight.svg";
import illust_system from "../../../assets/icon/dataValue/system.svg";
import illust_creative from "../../../assets/icon/dataValue/creativity.svg";

export const CardDataValue = ({ order }) => {
	const contentList = [
		{
			title: "INSIGHT",
			exp: `추측이 아닌 분석 기반 개선점 도출`,
			img: illust_insight,
		},
		{
			title: "SYSTEM",
			exp: `데이터로 구조화된 지속가능한 시스템`,
			img: illust_system,
		},
		{
			title: "CREATIVITY",
			exp: `데이터 중심의 R&D로 신규 분야 개척`,
			img: illust_creative,
		},
	];
	return (
		<Flex gap={12}>
			<Image
				src={contentList[order].img}
				w={{ base: "48px", md: "80px" }}
				h={'fit-content'}
				alt={""}
			/>
			<Flex flex={1} pt={4} gap={0} direction={"column"} align={"start"} justify={'center'}>
				<Box hiddenFrom="sm" pt={0}>
					<Title order={5}>{contentList[order].title}</Title>
					<Text size={"md"} align="left">
						{contentList[order].exp}
					</Text>
				</Box>
				<Box visibleFrom="sm">
					<Title order={3}>{contentList[order].title}</Title>
					<Text size={'xl'} fw={400} align="left">
						{contentList[order].exp}
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
};