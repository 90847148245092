import styled from "@emotion/styled";

const TextBlock = styled.p`
	padding: inherit;
	margin: inherit;
	::first-letter {
		initial-letter: 2;
		font-weight: 800;
		margin-right: 0.5rem;
		color: ${({ theme }) => theme.colors.blue[5]};
	}
`;

const FirstLetter = ({ children, style }) => {
	return <TextBlock style={style}>{children}</TextBlock>;
};

const FirstWord = ({ style, text }) => {
	const words = text.split(" ");
	const firstWord = words[0];

	// Extract the rest of the words and join them back into a string
	const restOfWords = words.slice(1).join(" ");

	return (
		<p>
			<span className="first-word" style={style}>
				{firstWord}
			</span>
			{restOfWords}
		</p>
	);
};

export const HighlightedP = ({ children, type = "letter", style }) => {
	return (
		<>
			{type === "word" ? (
				<FirstWord style={style} text={children} />
			) : (
				<FirstLetter>{children}</FirstLetter>
			)}
		</>
	);
};
