import { SET_IS_SCROLLED } from "../../context/headerState/action";
import { Container, Flex, Box } from "@mantine/core";
import { LogoHorizonInteractive } from "../logoType/logo/LogoHorizonInteractive";
import { LogoHorizonSimpleInteractive } from "../logoType/logo/LogoHorizonSimpleInteractive";
import { NavLinkGNB } from "./NavLinkGNB";
import { Button } from "@mantine/core";
import { Burger } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import styled from "styled-components";
import colorsVariants from "../../designTokens/colorsVariants";
import colors from "../../designTokens/colors";
import { LogoSymbol } from "../logoType/logo/LogoSymbol";
import {
	useHeaderState,
	useHeaderDispatch,
} from "../../context/headerState/headerContext";
import { useEffect } from "react";
import { useWindowScroll } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import other from "../../designTokens/other";

const HeaderContainer = styled(Container)`
	position: fixed;
	z-index: 99999;
	top: ${(props) => (props.$isHide ? "-80px" : "0")};
	left: ${(props) =>
		props.$isScrolled ? `calc((100% - ${other.size.lg})/2)` : "0"};
	background-color: ${(props) =>
		props.$isScrolled
			? props.$themeType === "light"
				? `${colorsVariants.datumWhite[0]}C0`
				: `${colorsVariants.datumBlack[0]}C0`
			: props.$themeType === "light"
			? `${colorsVariants.datumWhite[0]}00`
			: `${colorsVariants.datumBlack[0]}00`};
	color: ${(props) =>
		props.$themeType === "light"
			? colorsVariants.datumBlack[0]
			: colorsVariants.datumWhite[0]};
	padding: ${(props) =>
		props.$isScrolled ? "0px 16px 0px 24px !important" : "0px 0px"};
	width: ${(props) => (props.$isScrolled ? other.size.lg : "calc(100%)")};
	height: ${(props) => (props.$isScrolled ? "64px" : "80px")};
	margin-top: ${(props) => (props.$isScrolled ? "8px" : "0px")};
	border-radius: ${(props) => (props.$isScrolled ? "32px" : "0px")};
	border: 1px solid ${colors.gray[4]};
	border-width: ${(props) => (props.$isScrolled ? "1px" : "0px")};
	transition: all 0.3s;
	backdrop-filter: ${(props) =>
		props.$isScrolled ? "blur(64px)" : "blur(0px)"};
	@media (max-width: 48em) {
		width: ${(props) =>
			!props.$isScrolled || props.$isMenuOpen
				? "calc(100%)"
				: "calc(100% - 40px)"};
		left: ${(props) =>
			!props.$isScrolled || props.$isMenuOpen ? "0px" : "20px"};
		top: ${(props) =>
			props.$isHide
				? "-64px"
				: !props.$isScrolled || props.$isMenuOpen
				? "0px"
				: "6px"};
		height: 64px;
		padding: 0px 24px !important;
		margin-top: 0px;
		border-width: 0px;
		border-radius: 32px;
		overflow: hidden;
		border-color: ${(props) =>
			!props.$isScrolled || (props.$isMenuOpen && props.$isScrolled)
				? `${colors.gray[4]}00`
				: `${colors.gray[4]}ff`};
	}
`;

export const Header = ({ onClickMenu, isMenuOpen }) => {
	const { isScrolled, themeType, isHide } = useHeaderState();
	const headerDispatch = useHeaderDispatch();
	const [scroll] = useWindowScroll();
	const { pathname } = useLocation();

	useEffect(() => {
		if (scroll.y === 0) {
			let body = {
				type: SET_IS_SCROLLED,
				value: false,
			};
			headerDispatch(body);
		} else {
			let body = {
				type: SET_IS_SCROLLED,
				value: true,
			};
			headerDispatch(body);
		}
	}, [scroll, pathname]);

	return (
		<HeaderContainer
			$isScrolled={isScrolled}
			$isHide={isHide}
			$themeType={themeType}
			$isMenuOpen={isMenuOpen}
		>
			<Container size={"xl"} p={0} style={{ height: "100%" }}>
				<Flex
					size={"100%"}
					justify={"space-between"}
					align={"center"}
					style={{ height: "100%" }}
				>
					{/* PC */}
					<Box visibleFrom="sm">
						<NavLinkGNB to={"/"}>
							<LogoHorizonSimpleInteractive
								startWithReserve={true}
								color={
									themeType === "light"
										? colorsVariants.primary[5]
										: colorsVariants.datumWhite[0]
								}
								scale={1 / 7}
								isReverse={!isScrolled}
							/>
						</NavLinkGNB>
					</Box>
					<Flex visibleFrom="sm" gap={24} align={"center"}>
						<NavLinkGNB to={"/"}>Home</NavLinkGNB>
						<NavLinkGNB to={"/projects"}>Projects</NavLinkGNB>
						<NavLinkGNB to={"/about"}>About</NavLinkGNB>
						<NavLinkGNB to={"/ideas"}>Ideas</NavLinkGNB>
						<Button
							ml={"xl"}
							rightSection={<IconWorld size={24} />}
							size="sm"
							variant="subtle"
							color={themeType === "light" ? "inform" : "contrast"}
							radius={"xl"}
						>
							English
						</Button>
					</Flex>
					{/* Mobile */}
					<Flex hiddenFrom="sm">
						<NavLinkGNB to={"/"}>
							{/* <LogoSymbol
								color={themeType === "dark" ? colors.gray[0] : colors.blue[5]}
								scale={1 / 7}
							/> */}
							<LogoHorizonSimpleInteractive
								startWithReserve={true}
								color={
									themeType === "light"
										? colorsVariants.primary[5]
										: colorsVariants.datumWhite[0]
								}
								scale={1 / 7}
								isReverse={!isScrolled}
							/>
						</NavLinkGNB>
					</Flex>
					<Burger
						color={themeType === "dark" ? "white" : "inform"}
						hiddenFrom="sm"
						opened={isMenuOpen}
						onClick={onClickMenu}
					/>
				</Flex>
			</Container>
		</HeaderContainer>
	);
};
