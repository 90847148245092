import { SET_IS_SCROLLED, SET_THEME_TYPE, SET_IS_HIDE } from "./action";
import { useHeaderDispatch } from "./headerContext";

export const SetHeaderContext = () => {
	const headerDispatch = useHeaderDispatch();

  const setHeaderPosition = (value) => {
		let body = {
			type: SET_IS_HIDE,
			value: value,
		};
		headerDispatch(body);
	};

  const setHeaderScrolled = (value) => {
    let body = {
			type: SET_IS_SCROLLED,
			value: value,
		};
		headerDispatch(body);
  }

  const setHeaderTheme = (value) => {
    let body = {
			type: SET_THEME_TYPE,
			value: value,
		};
		headerDispatch(body);
  }

  return {setHeaderPosition, setHeaderScrolled, setHeaderTheme};
};
