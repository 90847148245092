import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const PathUnitSingleEndMarker = ({
	data,
	width,
	height,
	strokeWidth,
	strokeColor,
	delay = 0,
	scale = 1,
	pathClassName = "cycle",
	isReverse = false,
	duration = 600,
	isTrigger = true,
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// initialize SVG @ 1st Load
		let length;
		let path;
		let arrow;
		const svg = d3.select(svgRef.current);
		if (!isLoaded) {
			const g = svg
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g")
				.attr("class", `g_${pathClassName}`)
				.attr("transform", `translate(0,0) scale(${scale})`);

			// g.append("defs")
			// 	.append("marker")
			// 	.attr("id", "arrow")
			// 	.attr("viewBox", "0 -5 10 10")
			// 	.attr("refX", 10) // Arrow head position
			// 	.attr("refY", 0)
			// 	.attr("markerWidth", 6)
			// 	.attr("markerHeight", 6)
			// 	.attr("orient", "auto")
			// 	.append("path")
			// 	.attr("d", "M0,-5L10,0L0,5")
			// 	.style("fill", "#000");

			path = g
				.append("path")
				.attr("class", `path_${pathClassName}`)
				.attr("d", data)
				.attr("fill", "none")
				.attr("stroke-width", strokeWidth)
				.attr("stroke", strokeColor);

			length = path.node().getTotalLength();

			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", length);

			// arrow = g.append("path").attr("d", d3.symbol(d3.symbolCross));

			setIsLoaded(true);
		}
		// initialize SVG @ UseEffect Cycle
		else {
			path = svg.select("g").select(`.path_${pathClassName}`);
			length = path.node().getTotalLength();
		}
		if (isTrigger) {
			if (!isReverse) {
				forward();

				// arrow.transition().duration(2000).ease("linear")
				// .attrTween("transform", translateAlong(path));
			}
			// reverse direction transition
			else {
				rewind();
			}
		}
		// forwad direction transition

		function translateAlong(path) {
			var l = path.getTotalLength();
			var ps = path.getPointAtLength(0);
			var pe = path.getPointAtLength(l);
			var angl = Math.atan2(pe.y - ps.y, pe.x - ps.x) * (180 / Math.PI) - 90;
			var rot_tran = "rotate(" + angl + ")";
			return function (d, i, a) {
				console.log(d);

				return function (t) {
					var p = path.getPointAtLength(t * l);
					return "translate(" + p.x + "," + p.y + ") " + rot_tran;
				};
			};
		}

		function forward() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", length)
				.transition()
				.delay(delay)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", 0)
				.duration(duration);
		}

		function rewind() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			path
				.attr("stroke-dasharray", length + " " + length)
				.attr("stroke-dashoffset", 0)
				.transition()
				// .delay(delay)
				.ease(d3.easeQuadOut)
				.attr("stroke-dashoffset", length)
				.duration(600);
		}
	}, [isReverse, isTrigger]);

	return (
		<div>
			<svg ref={svgRef} />
		</div>
	);
};
