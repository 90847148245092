import { Tabs, Stack } from "@mantine/core";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { CardTrackDetail } from "../../components/card/contentsExp/CardTrackDetail";
import { Container } from "@mantine/core";
import { ContentStack } from "../../components/layout/stack/ContentStack";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { useBreakPoint } from "../../hook/useBreakPoint";

export const TrackDetailTabs = ({}) => {
	const tabList = [
		"track1",
		"track2",
		"track3",
		"track4",
	];

	const { breakPoint, getResponsiveValue, isLoaded } = useBreakPoint();

	const tabSize = {
		xs: 'xs',
		sm: 'xs',
		md: 'xl',
		lg: 'xl',
		xl: 'xl'
	};

	return (
		<Tabs defaultValue={tabList[0]}>
			<ContentStack>
			<ContentColumn style={{paddingBottom:'64px'}}>
				<SectionTitle title={"More Details Of The Tracks"} />
			</ContentColumn>
			<ContentColumn p={0}>
				<Tabs.List>
					{tabList.map((tab, i) => (
						<Tabs.Tab w={{base:'25%', md: 'fit-content'}} value={tab} size={getResponsiveValue(tabSize, breakPoint)} key={i}>
							{tab}
						</Tabs.Tab>
					))}
				</Tabs.List>
			</ContentColumn>
			<ContentColumn bg={"gray.1"} p={0}>
				<Container w={"100%"} pt={{base:24, md:80}} pb={{base:24, md:80}}>
					<Tabs.Panel value={tabList[0]}>
						<CardTrackDetail order={0} />
					</Tabs.Panel>
					<Tabs.Panel value={tabList[1]}>
						<CardTrackDetail order={1} />
					</Tabs.Panel>
					<Tabs.Panel value={tabList[2]}>
						<CardTrackDetail order={2} />
					</Tabs.Panel>
					<Tabs.Panel value={tabList[3]}>
						<CardTrackDetail order={3} />
					</Tabs.Panel>
				</Container>
			</ContentColumn>
			</ContentStack>
		</Tabs>
	);
};
