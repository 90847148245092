import { useEffect } from "react";
import { Container, Stack } from "@mantine/core";
import { TopSectionProject } from "../../template/pageTopSection/TopSectionProject";
import { ProjectListSection } from "../../template/project/ProjectListSection";
import { ProjectInquirySection } from "../../template/contact/ProjectInquirySection";
import { Footer } from "../../components/navigation/Footer";
import { SetHeaderContext } from "../../context/headerState/SetHeaderContext";
import { SectionTitle } from "../../components/customTypo/SectionTitle";
import { ContentColumn } from "../../components/layout/column/ContentColumn";

export const PageProject = ({ projectList }) => {
	const { setHeaderTheme } = SetHeaderContext();

	useEffect(() => {
		setHeaderTheme("dark");
		return () => setHeaderTheme("light");
	}, []);
	return (
		<Container w={"100%"} bg={"gray.9"} style={{ position: "relative" }}>
				<TopSectionProject />
				<ContentColumn>
					<SectionTitle c={"white"} title={`Our Projects\nCan Be Best Reference\nFor Your Next One`} />
				</ContentColumn>
				<ProjectListSection
					projectList={projectList}
					isDark={true}
					isTitle={false}
				/>
			<ProjectInquirySection />
			<Footer />
		</Container>
	);
};
