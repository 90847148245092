import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const DatumTransitionSpace = ({
	width,
	height,
	x,
	y,
	color,
	isTrigger,
	id,
	delay,
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const w = width / x;
	const h = height / y;

	const data = [];
	for (let i = 0; i < y; i++) {
		for (let j = 0; j < x; j++) {
			let coord = { x: j, y: i };
			data.push(coord);
		}
	}

	useEffect(() => {
		if (!isLoaded) {
			const svg = d3
				.select(svgRef.current)
				// .attr("width", width)
				// .attr("height", height);
				.attr("width", "100%")
				.attr("height", "100%");

			const g = svg
				.append("g")
				.attr("class", `datum_group_${id}`)
				.attr("transform", "translate(0,0)");

			d3.select(`.datum_group_${id}`)
				.selectAll(".datum")
				.data(data)
				.enter()
				.append("rect")
				.attr("class", "datum")
				.attr("x", (d) => d.x * w - Math.random() * width)
				.attr("y", (d) => d.y * h)
				.attr("width", 0)
				.attr("height", h + 1)
				.attr("opacity", 1)
				.attr("fill", color);

			setIsLoaded(true);
		}
		if (isTrigger) {
			let datumSet = d3.select(`.datum_group_${id}`).selectAll(".datum");

			datumSet
				.transition()
				.ease(d3.easeQuadInOut)
				.delay(() => Math.random() * delay * 4)
				.duration(400)
				.attr("width", w * 2)
				.attr("x", (d) => d.x * w - Math.random() * w * 1)
				.attr("opacity", 1)
				.attr("fill", color);
		}
	}, [isLoaded, isTrigger]);
	return <svg ref={svgRef} />;
};
