import { useState } from "react";
import { Display } from "../../components/customTypo/Display";
import { Box, Flex, Space } from "@mantine/core";
import colors from "../../designTokens/colors";
import { CycleLine } from "../../stories/components/visualLangauge/CycleLine";
import styled from "@emotion/styled";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";

const LogoBox = styled(Box)`
	position: absolute;
	top: 16px;
	left: 382px;
`;

const Column = styled(Flex)`
	flex-direction: column;
	position: relative;
`;

const LogoBox2 = styled(Box)`
	position: absolute;
	top: 56px;
	left: -20px;
`;

export const SectionTitleProject = ({ themeType }) => {
	const [isTrigger, setIsTrigger] = useState(false);
	return (
		<Box
			pt={32}
			pb={48}
			style={(theme) => ({
				borderStyle: "solid",
				borderWidth: "1px 0 0 0",
				borderColor: theme.colors.supportive[6],
			})}
		>
			<Column visibleFrom="md" c={themeType === "dark" ? "white" : "gray.9"}>
				<ViewportWrapper
					onEnterViewport={() => {
						setIsTrigger(true);
					}}
				>
					<Display
						order={3}
						align={"left"}
						bg={themeType === "dark" ? "gray.9" : "none"}
					>
						We&nbsp;&nbsp;Don't&nbsp;&nbsp;Design&nbsp;&nbsp;&nbsp;&nbsp;Just
						Product
					</Display>
					<Display
						order={3}
						align={"left"}
						bg={themeType === "dark" ? "gray.9" : "none"}
					>
						We&nbsp;&nbsp;Design&nbsp;&nbsp;Your
						<strong style={{ color: colors.blue[5] }}>
							&nbsp;&nbsp;&nbsp;&nbsp;Product Life Cycle
						</strong>
					</Display>
					<LogoBox>
						<CycleLine
							id={"pc"}
							color={themeType === "dark" ? colors.gray[0] : colors.gray[9]}
							isTrigger={isTrigger}
							scale={0.8}
						/>
					</LogoBox>
				</ViewportWrapper>
			</Column>
			<Column hiddenFrom="md" c={themeType === "dark" ? "white" : "gray.9"}>
			<ViewportWrapper
					onEnterViewport={() => {
						setIsTrigger(true);
					}}
				>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					We Don't Design
				</Display>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					<Space w={48} />
					Just Product,
					<Space w={12} />
					But
				</Display>
				<Display
					order={4}
					align={"left"}
					bg={themeType === "dark" ? "gray.9" : "none"}
				>
					<strong style={{ color: colors.blue[5] }}>Product Life Cycle</strong>
				</Display>
				<LogoBox2>
					<CycleLine
						color={themeType === "dark" ? colors.gray[0] : colors.gray[9]}
						scale={0.5}
						id={"mobile"}
						isTrigger={isTrigger}
					/>
				</LogoBox2>
				</ViewportWrapper>
			</Column>
		</Box>
	);
};
