import { useState } from "react";
import { ContentColumn } from "../../components/layout/column/ContentColumn";
import { useViewportSize } from "@mantine/hooks";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { Flex, Box, Stack } from "@mantine/core";
import { Display } from "../../components/customTypo/Display";
import { LogoHorizon } from "../../components/logoType/logo/LogoHorizon";
import { CardDataValueBig } from "../../components/card/contentsExp/CardDataValueBig";
import { MainTopParticleLoop } from "../../visualization/topSectionBG/MainTopParticleLoop";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mantine/hooks";
import other from "../../designTokens/other";
import ViewportWrapper from "../../components/triggerWrapper/ViewportWrapper";
import { TypeAnimation } from "react-type-animation";
import { Transition } from "@mantine/core";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";

const Column = styled(Flex)`
	position: relative;
	margin-top: -204px;
`;

const LogoBox = styled(Box)`
	position: absolute;
	top: 316px;
	left: 180px;
	background-color: #ffffffa0;
	padding: 8px;
`;

const LogoBoxMobile = styled(Box)`
	position: absolute;
	top: 232px;
	left: 0px;
`;

const BgLineLeft = styled.div`
	position: absolute;
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	background-image: ${(props) =>
		`linear-gradient(90deg, #F9FAFB00 0%, #F9FAFBFF 25%, #F9FAFBFF 50%, #F9FAFB00 100%)`};
	height: 120px;
	width: 320px;
	z-index: 9;
`;

const BgLineRight = styled.div`
	position: absolute;
	right: ${(props) => props.right};
	top: ${(props) => props.top};
	background-image: ${(props) =>
		`linear-gradient(-90deg,#F9FAFB00 0%, #F9FAFBFF 25%, #F9FAFBFF 50%, #F9FAFB00 100%)`};
	height: 120px;
	width: 320px;
	z-index: 9;
`;

export const BrandAd = ({}) => {
	const isMobile = useMediaQuery(`(max-width: ${other.size.md})`);
	const { height, width } = useViewportSize();
	const [isStop, setIsStop] = useState(false);
	const logoStartDelay = 1000;
	const logoDelay = 80;

	return (
		<FullPageContainer
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				position: "relative",
			}}
		>
			<ViewportWrapper
				onEnterViewport={() => {
					setIsStop(false);
				}}
				onLeaveViewport={() => {
					setIsStop(true);
				}}
			>
				<ContentColumn>
					{/* // PC */}
					<Flex
						direction={"column"}
						justify={"center"}
						w={"100%"}
						visibleFrom="md"
						h={height}
						style={{ position: "relative" }}
					>
						<Column
							direction={"column"}
							align={"start"}
							gap={320}
							justify={"center"}
							style={{ position: "relative" }}
							mt={-440}
						>
							<Box mt={212}>
								<TransitionWrapper delay={"0.3s"}>
									<Display order={1} align={"left"} bg={"#ffffffa0"}>
										Make Your Business Sustainable
									</Display>
								</TransitionWrapper>
								<TransitionWrapper delay={"0.8s"}>
									<Display order={1} align={"left"} bg={"#ffffffa0"}>
										With
									</Display>
								</TransitionWrapper>
							</Box>
							<LogoBox>
								<LogoHorizon
									scale={0.45}
									duration={800}
									startDelay={logoStartDelay}
									delay={logoDelay}
								/>
							</LogoBox>
							<Box
								w={"100%"}
								style={{
									position: "absolute",
									top: 320,
									left: 0,
									zIndex: -1,
									// overflow: "hidden",
								}}
							>
								<BgLineLeft
									top={"8px"}
									left={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineLeft
									top={"368px"}
									left={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineRight
									top={"8px"}
									right={"calc((100% - 1440px)/2 - 160px)"}
								/>
								<BgLineRight
									top={"368px"}
									right={"calc((100% - 1440px)/2 - 160px)"}
								/>
								{!isMobile && (
									<Box style={{transform:'scale(1.75) translate(0,10px)'}}>
										<MainTopParticleLoop numParticle={50} isStop={isStop} />
									</Box>
								)}
							</Box>
						</Column>
						<Flex
							gap={0}
							w={"100%"}
							wrap={"wrap"}
							direction={"row"}
							align={"center"}
							justify={"center"}
							style={{ position: "absolute", left: 0, bottom: 24 }}
						>
							{/* <Box w={"33%"}>
								<TransitionWrapper delay={"2.3s"}>
									<CardDataValueBig order={0} />
								</TransitionWrapper>
							</Box>
							<Box w={"33%"}>
								<TransitionWrapper delay={"2.4s"}>
									<CardDataValueBig order={1} />
								</TransitionWrapper>
							</Box>
							<Box w={"33%"}>
								<TransitionWrapper delay={"2.5s"}>
									<CardDataValueBig order={2} />
								</TransitionWrapper>
							</Box> */}
						</Flex>
					</Flex>
					{/* Mobile Tabelt */}
					<Box hiddenFrom="md" h={height} style={{ position: "relative" }}>
						<Stack pt={96} gap={4} align="flex-start">
							<Display order={5} align={"left"} bg={"#fffffff0"} p={4}>
								Make Your Business
							</Display>
							{/* <Display order={5} align={"left"} bg={"#fffffff0"} p={4}>
								Business
							</Display> */}
							<Display order={5} align={"left"} bg={"#fffffff0"} p={4}>
								Sustainable With
							</Display>
							<Box bg={"#fffffff0"} p={"12 6 12 6"}>
								<LogoHorizon delay={logoDelay} scale={0.14} />
							</Box>
						</Stack>
						<Flex
							direction={{ base: "column", md: "row" }}
							w={"100%"}
							p={16}
							gap={16}
							bg={"#fffffffA"}
							style={{
								position: "absolute",
								bottom: 24,
								left: 0,
								zIndex: 0,
								borderRadius: "4px",
								flexWrap: "wrap",
							}}
						>
							<CardDataValueBig order={0} />
							<CardDataValueBig order={1} />
							<CardDataValueBig order={2} />
						</Flex>
					</Box>
					<Box
						w={"100%"}
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -9,
						}}
					>
						{isMobile && (
							<MainTopParticleLoop
								scale={0.4}
								numParticle={50}
								isStop={isStop}
							/>
						)}
					</Box>
				</ContentColumn>
			</ViewportWrapper>
		</FullPageContainer>
	);
};
