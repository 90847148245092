import styled from "@emotion/styled";
import { NavLink, Link } from "react-router-dom";
import { Flex } from "@mantine/core";
import { UnstyledButton } from "@mantine/core";

const LinkCustom = styled(Link)`
	height: ${(props) => props.theme.other.size.topSectionHeight};
	font-size: ${(props) => props.theme.fontSizes.xl};
	font-weight: 400;
	height: ${(props) => props.theme.spacing["09"]};
	@media (max-width: 48em) {
		height: ${(props) => props.theme.spacing["08"]};
		font-weight: 600;
		padding-left: 0px;
	}
`;

export const NavLinkGNB = ({ children, to, onClickLink }) => {
	return (
		<UnstyledButton onClick={onClickLink}>
			<LinkCustom to={to}>
				<Flex align={"center"} style={{ height: "100%" }}>
					{children}
				</Flex>
			</LinkCustom>
		</UnstyledButton>
	);
};
