import { Display } from "./Display";
import { Stack } from "@mantine/core";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { TransitionWrapper } from "../triggerWrapper/TransitionWrapper";

export const SectionTitle = ({
	children = null,
	title = null,
	isBorder = true,
	isQuestion = false,
	c = "gray.9",
}) => {
	const { breakPoint, getResponsiveValue } = useBreakPoint();
	const titleOrder = {
		xs: 5,
		sm: 5,
		md: 3,
		lg: 3,
		xl: 3,
	};

	return (
		<TransitionWrapper>
			<Stack
				c={c}
				pt={{base:24,md:32}}
				pb={{base:0,md:16}}
				style={(theme) => ({
					borderStyle: "solid",
					borderWidth: isBorder ? "1px 0 0 0" : "0",
					borderColor: theme.colors.supportive[6],
					wordBreak: 'keep-all',
					whiteSpace: 'break-spaces'
				})}
			>
				{title && (
					<Display order={getResponsiveValue(titleOrder, breakPoint)}>
						{isQuestion && <strong>Q </strong>}
						{title}
					</Display>
				)}
				{children}
			</Stack>
		</TransitionWrapper>
	);
};
