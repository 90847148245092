import { createTheme, Button, Input, SegmentedControl } from "@mantine/core";
import buttonClasses from "../customStyle/button/button.module.css";
import inputClasses from "../customStyle/input/input.module.css";
import tabClasses from "../customStyle/tab/tab.module.css";
import segmentedControlClasses from "../customStyle/input/segmentedControl.module.css";
import notificationClasses from "../customStyle/notification/notification.module.css";
// import containerClasses from "../customStyle/layout/container.module.css";
import chipClasses from "../customStyle/chip/chip.module.css";
import { Chip, Text, Title } from "@mantine/core";
import { Flex, Tabs, Container } from "@mantine/core";
import { Notification } from "@mantine/core";

// design tokens
const colors = require("../designTokens/colors");
const colorsVariants = require("../designTokens/colorsVariants");
const types = require("../designTokens/type");
const other = require("../designTokens/other");

export const theme = createTheme({
	colors: {
		...colors,
		...colorsVariants,
	},
	primaryColor: "blue",
	primaryShade: { light: 5, dark: 8 },
	fontFamily: "Pretendard Variable",
	...types,
	other: other,
	spacing: {
		// "xs": "0.625rem",
		// "sm": "0.75rem",
		// "md": "1rem",
		// "lg": "1.25rem",
		// "xl": "1.5rem",
		// "2xl": "2rem",

		"01": "0.125rem",
		"02": "0.25rem",
		//xs
		xs: "0.5rem",
		"03": "0.5rem",
		//sm
		sm: "0.75rem",
		"04": "0.75rem",
		//md
		md: "1rem",
		"05": "1rem",
		//lg
		lg: "1.5rem",
		"06": "1.5rem",
		//xl
		xl: "2rem",
		"07": "2rem",
		"08": "2.5rem",
		"09": "3rem",
		//2xl
		"2xl": "4rem",
		10: "4rem",
		11: "5rem",
		12: "6rem",
		13: "10rem",
		cxs: other.size.xs,
		csm: other.size.sm,
		cmd: other.size.md,
		clg: other.size.lg,
		cxl: other.size.xl,
	},
	breakpoints: {
		xs: other.size.xs,
		sm: other.size.sm,
		md: other.size.md,
		lg: other.size.lg,
		xl: other.size.xl,
	},
	components: {
		Container: Container.extend({
			defaultProps: {
				size: "100%",
				p: 0,
			},
			styles: {},
			vars: (theme, props) => {
				if ((props.size = "xl")) {
					return {
						root: {
							"--container-size-xl": other.size.xl,
						},
					};
				}
				if ((props.size = "lg")) {
					return {
						root: {
							"--container-size-xl": other.size.lg,
						},
					};
				}
				if ((props.size = "md")) {
					return {
						root: {
							"--container-size-xl": other.size.md,
						},
					};
				}
				if ((props.size = "sm")) {
					return {
						root: {
							"--container-size-xl": other.size.sm,
						},
					};
				}
				if ((props.size = "xs")) {
					return {
						root: {
							"--container-size-xl": other.size.xs,
						},
					};
				}
			},
		}),
		Button: Button.extend({
			// Add Custom Variant
			classNames: buttonClasses,
		}),
		Input: Input.extend({
			// Add Underline Variants
			classNames: inputClasses,
		}),
		TextInput: {
			classNames: inputClasses,
			defaultProps: {
				inputWrapperOrder: ["label", "input", "description", "error"],
			},
		},
		Textarea: {
			classNames: inputClasses,
			defaultProps: {
				inputWrapperOrder: ["label", "input", "description", "error"],
			},
		},
		SegmentedControl: SegmentedControl.extend({
			classNames: segmentedControlClasses,
			styles: {
				root: {
					backgroundColor: colors.gray[0],
				},
				control: {
					// backgroundColor: colors.gray[1],
				},
			},
		}),
		Chip: Chip.extend({
			classNames: chipClasses,
			styles: {
				root: {},
				label: {
					textTransform: "capitalize",
					fontWeight: 600,
				},
			},
		}),
		Text: Text.extend({
			defaultProps: {
				size: "lg",
				align: "left",
			},
			styles: {
				root: {
					wordBreak: "keep-all",
					whiteSpace: "pre-line",
				},
			},
		}),
		Title: Title.extend({
			defaultProps: {
				align: "left",
			},
			styles: {
				root: {
					wordBreak: "keep-all",
				},
			},
		}),
		Flex: Flex.extend({
			styles: {
				root: {
					justifyItems: "flex-start",
				},
			},
		}),
		Tabs: Tabs.extend({
			classNames: tabClasses,
			styles: {
				tab: {
					// padding: "24px 24px",
				},
				tabLabel: {
					fontSize: types.fontSizes.xl,
					fontWeight: 700,
				},
			},
		}),
		List: {
			defaultProps: {
				size: "lg",
			},
			styles: {
				itemIcon: {
					display: "grid",
					paddingTop: "3px",
					marginRight: "8px",
				},
				itemWrapper: {
					alignItems: "start",
				},
			},
		},
		Notification: Notification.extend({
			classNames: notificationClasses
		}),
	},
});
