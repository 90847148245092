import { PageMain } from "./PageMain";
import { FetchProjectList } from "../../dataFetch/FetchProjectList";
import { FetchStoryList } from "../../dataFetch/FetchStoryList";

export const ContainerMain = () => {
	const { projectList } = FetchProjectList();
	const { storyList } = FetchStoryList();

	return <PageMain projectList={projectList} storyList={storyList} />;
};
