import { PathTypeUnitDouble } from "../../../components/logoType/unit/PathUnitDouble";
import { eye_path, pupil_path } from "../../../data/visualLangaugePathData";
import colors from "../../../designTokens/colors";

export const VisionPattern = ({delay1, delay2, scale}) => {
	return (
		<PathTypeUnitDouble
			data1={eye_path}
			data2={pupil_path}
			width={360}
			height={576}
			strokeWidth={24}
			strokeWidth2={40}
      delay1={delay1}
      delay2={delay2}
      strokeColor={colors.blue[5]}
			duration={1200}
			scale={scale}
		/>
	);
};
