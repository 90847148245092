// project Data
import { data_gazilab } from "./data_gazilab";
import { data_plastic_literacy } from "./data_plastic_literacy";
import { data_digital_mayor } from "./data_digital_mayor";
import { data_aegis } from "./data_ageis";
import { data_penellagen } from "./data_penellagen";
import { data_project_syria } from "./data_project_syria";

export const projectDataList = [
	data_gazilab,
	data_plastic_literacy,
	data_digital_mayor,
	data_aegis,
	data_penellagen,
	data_project_syria
];