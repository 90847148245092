import { TopSectionContainer } from "../../components/layout/container/TopSectionContainer";
import { Flex, Box, Space } from "@mantine/core";
import { Display } from "../../components/customTypo/Display";
import { CycleLine } from "../../stories/components/visualLangauge/CycleLine";
import colors from "../../designTokens/colors";
import styled from "@emotion/styled";
import { PageTitleProject } from "./PageTitleProject";

const Column = styled(Flex)`
	flex-direction: column;
	position: relative;
`;

const LogoBox = styled(Box)`
	position: absolute;
	top: 24px;
	left: 483px;
`;

const LogoBox2 = styled(Box)`
	position: absolute;
	top: 56px;
	left: -20px;
`;

export const TopSectionProject = () => {
	return (
		<TopSectionContainer bg={"gray.9"}>
			<PageTitleProject themeType={'dark'} />
		</TopSectionContainer>
	);
};
