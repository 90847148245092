import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const PathUnitMultiple = ({
	dataList,
	width,
	height,
	strokeWidth,
	strokeColor,
	delay = 0,
	startDelay = 900,
	scale = 1,
	duration = 900,
	isReverse = false,
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	// initialize SVG
	useEffect(() => {
		const svg = d3.select(svgRef.current);
		const pathDataList = [];
		if (!isLoaded) {
			const g = svg
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g")
				.attr("transform", `translate(0,0) scale(${scale})`);

			dataList.forEach((data, i) => {
				let path;
				let length;
				path = g
					.append("path")
					.attr("d", data)
					.attr("class", `path_${i}`)
					.attr("fill", "none")
					.attr("stroke-width", strokeWidth)
					.attr("stroke", strokeColor);

				length = path.node().getTotalLength();

				path
					.attr("stroke-dasharray", length + " " + length)
					.attr("stroke-dashoffset", length);

				let pathData = {
					path: path,
					length: length,
				};
				pathDataList.push(pathData);
			});
			setIsLoaded(true);
			forward();
		} else if (!isReverse) {
			forward();
		} else {
			rewind();
		}

		function forward() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			pathDataList.forEach((pathData, i) => {
				pathData.path
					.attr("stroke-dasharray", pathData.length + " " + pathData.length)
					.attr("stroke-dashoffset", pathData.length)
					.transition()
					.delay(delay * i + startDelay)
					.ease(d3.easeQuadOut)
					.attr("stroke-dashoffset", 0)
					.duration(duration);
			});
		}

		function rewind() {
			// Animate the path by setting the initial offset and dasharray and then transition the offset to 0
			pathDataList.forEach((pathData, i) => {
				pathData.path
					.attr("stroke-dasharray", pathData.length + " " + pathData.length)
					.attr("stroke-dashoffset", 0)
					.transition()
					.delay(delay * i)
					.ease(d3.easeQuadOut)
					.attr("stroke-dashoffset", pathData.length)
					.duration(duration);
			});
		}
	}, [isReverse]);

	return (
		<div>
			<svg ref={svgRef} />
		</div>
	);
};
