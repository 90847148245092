import { TopSectionContainer } from "../../components/layout/container/TopSectionContainer";
import { OurIdeas } from "../../components/customTypo/interactiveType/OurIdeas";
import { Flex } from "@mantine/core";
import { Display } from "../../components/customTypo/Display";
import bg_grid from "../../assets/visualLangauge/grid_dot.svg";
import { IdeaBulb } from "../../stories/components/visualLangauge/IdeaBulb";
import styled from "@emotion/styled";
import { Container, Box } from "@mantine/core";
import { GridBG } from "../../visualization/grid/GridBG";
import colors from "../../designTokens/colors";
import { useViewportSize } from "@mantine/hooks";
import { useViewPortLoaded } from "../../hook/useViewPortLoaded";
import { TransitionWrapper } from "../../components/triggerWrapper/TransitionWrapper";

const Column = styled(Flex)`
	position: relative;
`;

// const BG = styled(Flex)`
// 	width: 100%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	z-index: -9;
// 	height: ${(props) => props.theme.other.size.topSectionHeight};
// 	background-color: white;
// 	text-align: center;
// 	overflow: hidden;
// `;

const BG = ({ children }) => (
	<Flex
		w={"100%"}
		style={{
			position: "absolute",
			top: 0,
			left: 0,
			zIndex: -9,
			overflow: "hidden",
		}}
		h={(theme) => ({
			base: "100%",
			md: theme.other.size.topSectionHeight,
		})}
	>
		{children}
	</Flex>
);
const FloatingBox = styled(Flex)`
	width: 144px;
	height: 144px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 216px;
	left: 1080px;
`;

export const TopSectionIdeas = ({}) => {
	const { width, height, isLoaded } = useViewPortLoaded();
	const unitMobile = 48;
	return (
		<TopSectionContainer>
			{/* PC */}
			<Box visibleFrom="md" w={"100%"}>
				<BG>
					<GridBG unit={72} x={40} y={16} fill={colors.gray[2]} />
				</BG>
				<Flex justify={"space-between"} align={"center"}>
					<Column direction={"column"} gap={8}>
						<OurIdeas/>
						<TransitionWrapper delay={'0.5s'}> 
							<Display order={2} align={"left"} bg={"#ffffffa0"}>
								expending your point of view
							</Display>
						</TransitionWrapper>
					</Column>
					<IdeaBulb />
				</Flex>
			</Box>
			{/* Moble Tabelt */}
			<Box hiddenFrom="md" w={"100%"} pb={width / 2}>
				{isLoaded && (
					<>
						<BG>
							<GridBG
								unit={unitMobile}
								x={parseInt(width / unitMobile)}
								y={parseInt(height / unitMobile)}
								fill={colors.gray[2]}
							/>
						</BG>
						<Column direction={"column"} gap={4}>
							<OurIdeas scale={1 / 5} />
							<Display order={4} align={"left"} bg={"#ffffffa0"}>
								expending your
							</Display>
							<Display order={4} align={"left"} bg={"#ffffffa0"}>
								point of view
							</Display>
						</Column>
						<Box mt={2 * unitMobile} mb={-2 * unitMobile}>
							<IdeaBulb scale={1} />
						</Box>
					</>
				)}
			</Box>
		</TopSectionContainer>
	);
};
