import { useEffect, useState } from "react";
import { PageProjectDetail } from "./PageProjectDetail";
import { dbService } from "../../firebaseInstance";
import { useLocation } from "react-router-dom";

export const ContainerProjectDetail = ({}) => {
	let location = useLocation();

	const id = location.pathname.split("/project/")[1];
	console.log(location);
	console.log(id);
	const [projectData, setProjectData] = useState(null);

	useEffect(() => {
		dbService
			.doc(`projectList/${id}`)
			.get()
			.then((doc) => {
				console.log(doc.data());
				setProjectData(doc.data());
			});
	}, []);

	return (
		<>{projectData && <PageProjectDetail projectDetail={projectData} />}</>
	);
};
