import { Flex, Paper } from "@mantine/core";
import { Title, Badge } from "@mantine/core";
import { Image } from "@mantine/core";
import icon_track1 from "../../../assets/icon/track/track_number_1.svg";
import icon_track2 from "../../../assets/icon/track/track_number_2.svg";
import icon_track3 from "../../../assets/icon/track/track_number_3.svg";
import icon_track4 from "../../../assets/icon/track/track_number_4.svg";

export const CardTrack = ({ order }) => {
	const contentList = [
		{
			title: `MVP Proposition`,
			img: icon_track1,
		},
		{
			title: `UX Enhance + Redesign`,
			img: icon_track2,
		},
		{
			title: `R&D + Prototyping`,
			img: icon_track3,
		},
		{
			title: `Product + Design System`,
			img: icon_track4,
		},
	];
	return (
		<Paper
			shadow="lg"
			w={"fit-content"}
			bg={"datumWhite.0"}
			style={{ borderRadius: "8px", overflow: "hidden" }}
		>
			<Flex
				w={{ base: "132px", md: "360px" }}
				gap={12}
				direction={{ base: "column", md: "row" }}
			>
				<Image
					visibleFrom="md"
					width={"120px"}
					height={"120px"}
					src={contentList[order].img}
					alt={""}
				/>
				<Flex
					p={{ base: '16 8', md: 16 }}
					gap={8}
					direction={"column"}
					justify={"center"}
					align={"start"}
					bg={"white"}
					style={{ flexGrow: 1 }}
				>
					<Badge color={"gray.9"} size="lg" variant="filled">{`TRACK-${
						order + 1
					}`}</Badge>
					<Image
						hiddenFrom="md"
						w={"72px"}
						h={"72px"}
						src={contentList[order].img}
						alt={""}
					/>
					<Title visibleFrom="md" order={4}>
						{contentList[order].title}
					</Title>
					<Title hiddenFrom="md" order={5}>
						{contentList[order].title}
					</Title>
				</Flex>
			</Flex>
		</Paper>
	);
};
