import { TopSectionContainer } from "../../components/layout/container/TopSectionContainer";
import { Vision } from "../../components/customTypo/interactiveType/Vision";
import { Flex, Box } from "@mantine/core";
import { AboutTopCircles } from "../../visualization/topSectionBG/AboutTopCircles";
import { DoubleCirclePattern } from "../../stories/components/visualLangauge/DoubleCirclePattern";
import { VisionPattern } from "../../stories/components/visualLangauge/VisionPattern";
import { Display } from "../../components/customTypo/Display";
import { useViewportSize } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { TransitionWrapper } from '../../components/triggerWrapper/TransitionWrapper';
import styled from "@emotion/styled";

const Column = styled(Flex)`
	position: relative;
	flex-direction: column;
`;

const LogoBox = styled(Box)`
	position: absolute;
	top: 0px;
	left: 516px;
	background-color: #ffffffa0;
	padding: 16px 12px 8px 12px;
`;

const BG = styled(Flex)`
	width: 1440px;
	height: 100%;
	position: absolute;
	top: calc((100% - 576px) / 2);
	left: calc((100% - 1440px) / 2);
	z-index: -9;
	@media (max-width: 1440px) {
		width: 100%;
		left: 0;
	}
`;

const BGMobile = styled(Flex)`
	width: 100%;
	height: 100%;
	position: absolute;
	padding-top: 48px;
	top: 0;
	left: 0;
	z-index: -1;
	overflow: hidden;
	flex-wrap: wrap;
	flex-direction: column;
`;

const VisionPathContainer = styled.div`
	position: absolute;
	top: 0;
	left: 810px;
`;

const ShifLeft = styled.div`
	margin-left: -90px;
`;

const ShifLeftMobile = styled.div`
	margin-left: ${(props) => `${props.shift}px`};
`;

const ShifUpMobile = styled.div`
	margin-top: ${(props) => `${props.shift}px`};
`;

export const TopSectionAbout = () => {
	const delay = 200;
	const { width, height } = useViewportSize();

	const [scale, setScale] = useState(1);
	const [shift, setShift] = useState(1);
	const [isLoaded, setIsLoaded] = useState(false);
	const pattternPathWidth = 360;

	useEffect(() => {
		if (width !== 0) {
			const mobilePatternSize = (width / 18) * 10;
			const patternScaleMobile =
				(mobilePatternSize / width) * (width / pattternPathWidth);
			setScale(patternScaleMobile);
			setShift(mobilePatternSize * 0.2);
			setIsLoaded(true);
		}
	}, [width]);

	return (
		<TopSectionContainer>
			{/* PC */}
			<Box
				visibleFrom="md"
				w={"100%"}
				style={{ overflow: "hidden", textAlign: "center" }}
			>
				<Box style={{ position: "relative" }}>
					<TransitionWrapper delay={"1s"}>
						<Display order={2} align={"left"} bg={"#ffffffa0"}>
							Can You Visualize
						</Display>
					</TransitionWrapper>
					<TransitionWrapper delay={"1.3s"}>
						<Display order={2} align={"left"} bg={"#ffffffa0"}>
							Of Your Business?
						</Display>
					</TransitionWrapper>
					<LogoBox>
						<Vision />
					</LogoBox>
				</Box>
				<BG>
					<AboutTopCircles delay={delay} />
				</BG>
			</Box>
			{/* Mobile Tablet */}
			{isLoaded && (
				<Box hiddenFrom="md" w={"100%"} pb={width / 2}>
					<Display order={4} align={"left"} bg={"#ffffffa0"}>
						Can You Visualize
					</Display>
					<Display order={4} align={"left"} bg={"#ffffffa0"}>
						<Vision scale={1 / 5} />
					</Display>
					<Display order={4} align={"left"} bg={"#ffffffa0"}>
						Of Your Business?
					</Display>
					<BGMobile justify={"center"}>
						<Box style={{ position: "relative" }}>
							<Flex>
								<DoubleCirclePattern
									scale={scale}
									delay1={delay * 0}
									delay2={delay}
								/>
								<ShifLeftMobile shift={-shift} />
								<DoubleCirclePattern
									scale={scale}
									delay1={delay * 0}
									delay2={delay}
								/>
							</Flex>
							<ShifUpMobile shift={-shift * 2.75} />
							<Flex>
								<DoubleCirclePattern
									scale={scale}
									delay1={delay * 0}
									delay2={delay}
								/>
								<ShifLeftMobile shift={-shift} />
								<DoubleCirclePattern
									scale={scale}
									delay1={delay * 0}
									delay2={delay}
								/>
							</Flex>
							<Box
								style={{
									position: "absolute",
									// left: scale * pattternPathWidth * 0.8,
									// top: scale * pattternPathWidth * 1.275,
									right: 0,
									bottom: 0,
								}}
							>
								<VisionPattern
									delay1={delay * 5}
									scale={scale}
									delay2={delay}
								/>
							</Box>
						</Box>
					</BGMobile>
				</Box>
			)}
		</TopSectionContainer>
	);
};
