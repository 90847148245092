import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";

export const PathTweenSingle = ({
	data,
	data2,
	width,
	height,
	delay = 0,
	scale = 1,
	scale2 = 0.5,
	isReverse = false,
	duration = 600,
	pathClassName,
	color,
	isTrigger = true,
}) => {
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// initialize SVG @ 1st Load
		let length;
		let path;
		const svg = d3.select(svgRef.current);
		if (!isLoaded) {
			const g = svg
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g")
				.attr("class", `moving_path_group_${pathClassName}`)
				.attr("transform", `translate(0,0) scale(${scale})`);

			path = g
				.append("path")
				.attr("class", `track_path_${pathClassName}`)
				.attr("d", data)
				.attr("fill", "none")
				.attr("stroke-width", 0);

			// arrow = g.append("path").attr("d", d3.symbol(d3.symbolCross));

			length = path.node().getTotalLength();
			setIsLoaded(true);
		}
		// initialize SVG @ UseEffect Cycle
		else {
			path = svg.select("g").select("path");
			length = path.node().getTotalLength();
		}
		// forwad direction transition
		if (isTrigger) {
			if (!isReverse) {
				// arrow.transition().duration(2000).ease("linear")
				// .attrTween("transform", translateAlong(path));
				movingPath();
			}
		}

		// reverse direction transition
		else {
		}

		function movingPath() {
			let movingPathGroup = d3
				.select(`.moving_path_group_${pathClassName}`)
				.append("g")
				.attr("class", `moving_path_${pathClassName}`)
				.attr("transform", "translate(0,0)");

			movingPathGroup
				.append("path")
				.attr("class", "arrow")
				.attr("d", data2)
				.attr("fill", color)
				.attr("transform", `translate(4,-12) scale(${scale2}) rotate(90)`);

			movingPathGroup
				.transition()
				.delay(delay)
				.duration(duration)
				.ease(d3.easeQuadOut)
				.tween("pathTween", () => {
					let path = svg.select(`.track_path_${pathClassName}`);
					return pathTweenWithGroup(path);
				});
		}

		function pathTweenWithGroup(path) {
			const length = path.node().getTotalLength(); // Get the length of the path
			const r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
			return function (t) {
				const p1 = path.node().getPointAtLength(r(t)); // Get the next point along the path
				const p2 = path.node().getPointAtLength(r(t + 1 / length));
				const deg = calculateAngle(p1.x, p1.y, p2.x, p2.y);
				d3.select(this) // Selectd  the circle
					// .attr("x", point.x + offset.x) // Set the cx
					// .attr("y", point.y + offset.y) // Set the cy
					.attr(
						"transform",
						`translate(${p1.x},${p1.y}) rotate(${deg}, ${0}, ${0})`
					);
			};
		}
	}, [isReverse, isTrigger]);

	function calculateAngle(x1, y1, x2, y2) {
		// Calculate the differences in x and y coordinates
		const dx = x2 - x1;
		const dy = y2 - y1;

		// Calculate the angle in radians
		const radians = Math.atan2(dy, dx);

		// Convert radians to degrees
		let degrees = (radians * 180) / Math.PI;

		// Ensure angle is between 0 and 360 degrees
		if (degrees < 0) {
			degrees += 360;
		}

		return degrees;
	}

	return (
		<div>
			<svg ref={svgRef} />
		</div>
	);
};
