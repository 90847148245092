import { dbService } from "../firebaseInstance";
import { useEffect, useState } from "react";

export const FetchStoryList = () => {
  const [storyList, setStoryList] = useState([]);
  
  const fetchData = () => {
		try {
			dbService.collection("storyList").onSnapshot((snapshot) => {
				let tempList = [];
				snapshot.docs.forEach((doc) => {
					// story data formatting
					let story = doc.data();
					tempList.push(story);
				});
				// console.log(tempList);
				let list = tempList.sort((a, b) => {
					return b.order - a.order;
				});
				setStoryList(list);
			});
		} catch {
		} finally {
			// console.log('finally');
		}
	};

  useEffect(() => {
    fetchData();
  },[])

  return {storyList};
}