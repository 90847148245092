import { useState } from "react";
import ViewportWrapper from "./ViewportWrapper";
import { Transition } from "@mantine/core";

export const TransitionWrapper = ({
	children,
	transition = "fade",
	duration = 800,
	timingFunction = "ease",
	delay = 0,
	style
}) => {
	const [isTrigger, setIsTrigger] = useState(false);

	return (
		<ViewportWrapper
			onEnterViewport={() => {
				setIsTrigger(true);
			}}
			style={{ opacity: isTrigger ? 1 : 0, transition: `all 0.6s ease ${delay}`,...style}}
		>
			{/* <Transition
				mounted={isTrigger}
				transition={transition}
				duration={duration}
				timingFunction={timingFunction}
			>
        {(styles) => <div style={{...styles, transitionDelay: delay}}>{children}</div>}
      </Transition> */}
			{children}
		</ViewportWrapper>
	);
};
