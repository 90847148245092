const img_thumb = `https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2Fthumb_gazilab.png?alt=media&token=8b284a5a-303e-461b-a229-c8977fbbfd0b`;
const img_thumb_square = `https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2Fthumb_gazilab_square.png?alt=media&token=5076a482-88d4-4004-9d6e-078f79707383`;
const img_title =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2Ftitle.jpg?alt=media&token=030b7e88-2d3f-43f2-b695-2ad0a10d17b2";
const img_1_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F1_1.jpg?alt=media&token=16855b51-fcb0-464a-8368-253e3feb74d7";
const img_1_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F1_2.jpg?alt=media&token=981fd7a2-32c9-4eaf-89e1-371ec239b4cd";
const img_2_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F2_1.jpg?alt=media&token=9b6c42a8-aa02-42f0-b723-e042c840e771";
const img_3_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F3_1.jpg?alt=media&token=151365ee-c76f-4957-a0a7-7f18942db193";
const img_3_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F3_2.jpg?alt=media&token=38e2d098-73fa-4016-a88a-05e17ba9220b";
const img_4_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F4_1.png?alt=media&token=0c0b376c-40fa-4b17-b6ca-4c3f5ed30f2c";
const img_4_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F4_2.png?alt=media&token=219c74cb-4cf2-4c2f-9369-e127c1d1a410";
const img_4_3 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F4_3.png?alt=media&token=0dcd2428-c248-43c6-9c4d-002dc25b7219";
const img_4_4 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F4_4.png?alt=media&token=4f7782c3-b1c1-4a5f-bf4c-9f2adc0393a7";
const img_5_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F5_1.png?alt=media&token=a35b578c-b411-42f8-91b2-0c201e430de3";
const img_5_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F5_2.png?alt=media&token=25d25451-fe09-4a8c-8cc3-6564cfba17a2";
const img_6_1 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_1.png?alt=media&token=d3332cae-dc79-4946-b26a-c1dcc683791d";
const img_6_2 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_2.png?alt=media&token=64536215-b8bd-407e-9008-f75c665ae09d";
const img_6_3 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_3.png?alt=media&token=a1997e9f-6fe8-4337-b637-ef29db800163";
const img_6_4 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_4.png?alt=media&token=341d99ac-28cd-42fd-9495-3050cfc818f4";
const img_6_5 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_5.png?alt=media&token=321b318a-3c64-4937-8a6c-2c963b92c5d0";
const img_6_6 =
	"https://firebasestorage.googleapis.com/v0/b/data-driven-design-d2418.appspot.com/o/project_assets%2Fgazilab%2F6_6.png?alt=media&token=bb8b6e54-1f29-4fa8-8d28-1355ce29e2c3";

export const data_gazilab = {
	isCompleted: true,
	id: "gazilab-mvp",
	summary: {
		order: 5,
		thumb: img_thumb,
		thumb_square: img_thumb_square,
		title: "Gazilab MVP Project",
		tags: ["MVP Project", "Research", "Design System"],
		industry: ["헬스케어", "웰니스"],
		outcomes: ["리서치 인사이트", "페르소나 모델링", "웹 서비스"],
		color: "#75088b",
		tasks: ["정성 리서치", "제품 디자인", "디자인 시스템", "front-end 개발"],
		titleImg: img_title,
		summaryTitle: `우리가 건강하지 못한 ‘진짜' 이유에 대한 고찰`,
		summaryExp: `가지랩(gazilab)은 noom korea 출신의 인재들로 구성된 웰니스 스타트업입니다. 웰니스는 헬스케어와 유사한 개념이지만 건강에 미치는 사회적, 커리어, 정신적, 자아적, 신체적 특성들의 관계를 같이 고려한다는 점에서 차이가 있습니다. 우리는 건강에 대한 평가를 1차적인 지표들에 의존하려는 경향이 있습니다. 예를들어 살이찌는 현상에 대해서는 매우 민감하지만 야식 및 불규칙적인 식사를 야기시키는 원인에 대해서는 그냥 그려려니 하죠.

    gazilab은 이러한 우리 사회 전반에 깔린 건강에 대한 인식과 접근방식을 개선시키고자 하는 의지가 확고했습니다. 사람들이 어떻게 건강한 삶을 자기주도적으로 이끌어 나가도록 도와줄 수 있을까라는 질문과함께 MVP project를 시작하였습니다. 약 4개월에 걸친 기간동안 리서치를 통해 현대인들의 건강에 영향을 미치는 생활.개인 요소들 추적하고 이를 바탕으로 온라인 진단서비스를 출시하였습니다.`,
		link: `https://www.gazilab.co/diagnosis`,
	},
	sectionList: [
		{
			title: `웰니스 시장 + 문제 정의하기`,
			contentList: [
				{
					type: "text",
					content:
						"Gazilab으로 부터 처음  의뢰가 들어왔을때는 내부에서 웰니스와 관련된 키워드와 관심사를 빠르게 트래킹할 수 있는 A/B 테스팅용 큐레이션 피드가 기획중이었습니다.  하지만 웰니스 시장의 동향  및 특정 유저집단의 트렌드를 파악하는 것 자체가 목적이라면 해당 프로젝트를 계속 진행하는게 맞지만 현시점에서 정말 가지랩이 고민해야 하는 것이 무엇인지를 같이 짚어보는 것이 좋겠다는 결론을 내렸습니다.",
				},
				{
					type: "img",
					src: img_1_1,
					caption: "wellness의 8가지 구성 요소",
				},
				{
					type: "img",
					src: img_1_2,
					caption:
						"gazilab이 wellness 시장의 game changer가 되기 위한 3가지 조건들",
				},
				{
					type: "text",
					content: `데스크 리서치와 토론 세션을 거치면서 저희가 발견한 2가지 주요 쟁점은 첫째로 웰니스라는 단어가 마치 트렌드한 키워드처럼 소비되고 있다는 점과 사람들의 건강에 대한 관심과 접근은 여전히 제품 및 신체적 지표 중심에 머물러 있다는 점이었습니다. 이는 분명 gazilab이 지향하는 비전과 거리가 있어보였고 아직 어떤 형태의 MVP를 테스트할지 정해지진 않았지만 리서치를 통해 그들만의 건강관을 정립하는것을 선행하기로 결정하였습니다.`,
				},
			],
		},
		{
			title: `건강의 첫번째 단추는 나를 아는 것`,
			contentList: [
				{
					type: "text",
					content: `정말 다양한 형태의 리서치가 있지만 제가 생각하는 리서치의 궁극적인 목적은 자기만의 관점을 수립하는 것입니다. 가지랩의 경우 웰니스라는 개념과 사람들의 건강을 어떤 관점에서 바라볼 것인가가를 스스로 정립해야 했습니다. 이에따라, 내부 헬스코칭 전문가들과 의논끝에 참가자들의 건강에 미치는 영향을 파악하기 위해 '나'에 대한 자기주도성, '환경'의 사회적, 물리적 측면, 그리고 올바른 '지식' 보유 여부를 중점으로 리서치를 설계하였습니다.`,
				},
				{
					type: "img",
					src: img_2_1,
					caption:
						"gazilab wellness framework은 한 개인의 건강상태를 결정짓는 세가지 주요 축들의 역할과 관계를 정의한다.",
				},
			],
		},
		{
			title: `우리가 건강하지 못하는 ‘진짜' 이유`,
			contentList: [
				{
					type: "text",
					content:
						"30명의 20~30대 직장인을 대상으로한 리서치는 크게 3단계로 구성되었습니다. 첫단계에서는 gazilab frameworks에 기반한 in-depth 인터뷰를 진행하였고 인터뷰의 관측치들에서 open-coding과 axial-coding이라는 분류방식을 통해 유의미한 경험 척도들을 도출했습니다. 미자막으로 각 경험척도들을 조합하여 20-30대 직장인들이 건강하지 못하는 가장 대표적이고 상징적인 유형들을 페르소나로 모델링하였습니다.",
				},
				{
					type: "img",
					src: img_3_1,
					caption: "3단계로 설계된 정성 리서치",
				},
				{
					type: "img",
					src: img_3_2,
					caption: "리서치 결과 1차 분류 - open coding",
				},
			],
		},
		{
			title: "리서치 인사이트: 페르소나 모델링",
			contentList: [
				{
					type: "text",
					content: `open-coding과 axial-coding과정을 통해 건강에 영향을 미치는 주요 4가지 측면을 도출하였고 각 특성들은 서로 상반되는 특징들을 양끝 값으로 갖고있습니다. 기존의 건강관이 사람들의 식습관과 같은 신체 지표의 1차 원인들을 묘사했다면 리서치를 통해 얻은 경험척도들은 보다 장기적이고 근원적인 의미에서 발현되는 개인의 특징에 초점을 두었습니다.`,
				},
				{
					type: "img",
					src: img_4_1,
					caption: "axial coding 리뷰",
				},
				{
					type: "text",
					content: `예를들어 20-30대 직장인들이 건강에 가장 직접적이고 큰 영향을 주는 것은 ‘직장'과 ‘커리어'입니다. 건강을 애초에 커리어에 양보하는 대상이라고 보는 사람들이 있고 스트레스를 건강하지 않은 방법으로 푸는 사람들도 결국 그 원인증 상당 부분을 직장에서 받기 때문이죠. 때문에 나를 이해하는 가장 첫번째 단추는 건강에 대한 기회비용이 어디서 가장 많이 발생하고 왜 그런지에 대한 이유를 파악하는 것입니다. `,
				},
				{
					type: "img",
					src: img_4_2,
					caption: "페르소나의 경험 척도",
				},
				{
					type: "text",
					content: `그리고 나머지 경험 척도들은 건강과 관련된 상황을 인지하고 이를 해결해나가는데서 필연적으로 발현되는 개인의 특성들과 관련되어있습니다. 이 네가지 경험척도들이 어떻게 조합되냐에 따라 건강하지 못한 직장인들의  페르소나가 다음과 같이 분류됩니다.`,
				},
				{
					type: "img",
					src: img_4_3,
					caption: "최종 페르소나 도출",
				},
				{
					type: "img",
					src: img_4_4,
					caption: "최종 페르소나 도출",
				},
			],
		},
		{
			title: "MBTI 구조 분석 및 자가 진단 설계",
			contentList: [
				{
					type: "text",
					content: `리서치를 진행하기전 가장 진지하게 염두했던 MVP 서비스의 유형은 정보 큐레이션 서비스였으나 2가지 이유에서 현대인들을 위한 자가 건강 진단 서비스를 출시하자는 새로운 의견을 받아들이게 되었습니다. 첫번째는 인터뷰 참가자들에게 자신의 건강을 새로운 시각으로 돌아볼 수 있었다는 다수의 긍정적인 피드백을 받았다는 점, 두번째로 일방적인 정보 전달 서비스보다는 바이럴이 될만한 여지가 조금이라도 있는 컨텐츠를 다루는 것이 트래픽 유입 측면에서 좋겠다는 판단이었습니다.
          
          그래서 한국 사람들에게 가장 친숙한 테스트중 하나인 MBTI의 방식을 분석하여  너무 심각하지 않은 선에서 자신이 건강하지 못하는 이유를 알려주는 생활건강 진단을 설계하였습니다. 설계 방식은 사실 매우 명료했는데 피진단자가 리서치의 산출물인 페르소나중 어디에 해당되는지 판단하기 위한 특성들을 미리 매핑하고 이를 파악하기 위한 최종 질문을 뽑아내는 것입니다. 이 방식을 좀더 자세히 이해하기 위해 MBTI의 답변을 분석해본 결과는 다음과 같습니다.`,
				},
				{
					type: "img",
					src: img_5_1,
					caption: "mbti 질문의 키워드 및 성향 분포",
				},
				{
					type: "img",
					src: img_5_2,
					caption: "가지랩 자가 진단 질문의 키워드 및 성향 분포",
				},
			],
		},
		{
			title: "Product Deisgn",
			contentList: [
				{
					type: "img",
					src: img_6_1,
				},
				{
					type: "img",
					src: img_6_2,
				},
				{
					type: "img",
					src: img_6_3,
				},
				{
					type: "img",
					src: img_6_4,
				},
				{
					type: "img",
					src: img_6_5,
				},
				{
					type: "img",
					src: img_6_6,
				},
			],
		},
	],
};
