export const path_D =
	"M0,108h72c19.88,0,36-16.12,36-36c0-19.88-16.12-36-36-36H0";
export const path_D_reverse = `M0,36h72c19.88,0,36,16.12,36,36c0,19.88-16.12,36-36,36H0`;
export const path_a =
	"M72,120c-26.51,0-48-21.49-48-48s21.49-48,48-48s48,21.49,48,48v48H72";
export const path_t_1 = "M0,24h48v120";
export const path_t_2 = "M48,24h48";
export const path_t = "M40,24h40H0H40v120";
export const path_r = "M24,144V72c0-26.51,21.49-48,48-48h8";
export const path_i = "M23.89,0v144";
export const path_v = "M126-11L72,97L18-11";
export const path_e = `M20,72h104c0-28.72-23.28-52-52-52S20,43.28,20,72s23.28,52,52,52c12.64,0,24.22-4.51,33.23-12
	c2.86-2.38,5.47-5.06,7.76-8`;
export const path_g = `M72,72h52c0,28.72-23.28,52-52,52s-52-23.28-52-52s23.28-52,52-52c12.64,0,24.22,4.51,33.23,12`;
export const path_n = "M24,144V0l96,144V0";
export const path_s = `M120,48c0-13.25-10.75-24-24-24H48c-13.25,0-24,10.75-24,24s10.75,24,24,24h48c13.25,0,24,10.75,24,24
s-10.75,24-24,24H48c-13.25,0-24-10.75-24-24`;
export const path_o = `M120,72c0,26.5-21.5,48-48,48S24,98.5,24,72s21.5-48,48-48S120,45.5,120,72z`;
export const path_o_filled = `M108,72c0,19.9-16.1,36-36,36S36,91.9,36,72s16.1-36,36-36S108,52.1,108,72z`;
export const path_u = `M120,0v72c0,26.5-21.5,48-48,48S24,98.5,24,72V0`;
export const path_u_2 = `M24,0v72c0,26.5,21.5,48,48,48h48V0`;
export const path_u_serif = `M24,0v72c0,26.5,21.5,48,48,48s48-21.5,48-48V0v144`;
