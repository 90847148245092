import {
	Title,
	Flex,
	Badge,
	Text,
	List,
	Image,
	Grid,
	Stack,
	Box,
	Button,
} from "@mantine/core";
import {
	IconCircleCheckFilled,
	IconArrowNarrowRight,
} from "@tabler/icons-react";
import { ContentStack } from "../../components/layout/stack/ContentStack";

const TitleInfo = ({ title, list }) => (
	<Stack gap={8}>
		<Title order={1} style={{ textTransform: "capitalize" }}>
			{title}
		</Title>
		<Flex gap={4}>
			{list.map((tag, i) => (
				<Badge variant="outline" color={"white"} size="lg" key={i}>
					{tag}
				</Badge>
			))}
		</Flex>
	</Stack>
);

const ListInfo = ({ title, list }) => (
	<Stack gap={0}>
		<Title order={4} fw={700}>
			{title}
		</Title>
		<Flex gap={4} wrap={"wrap"}>
			{list.map((tag, i, arr) => (
				<Text size="xl" key={i} style={{ textTransform: "capitalize" }}>
					{tag}
					{arr.length - 1 !== i && ", "}
				</Text>
			))}
		</Flex>
		{/* <List
			icon={<IconCircleCheckFilled size={20} fill="primary" />}
			style={{ wordBreak: "keep-all", display: "flex", flexWrap: "wrap" }}
		>
			{list.map((customer, i) => (
				<List.Item>{customer}</List.Item>
			))}
		</List> */}
	</Stack>
);

export const ProjectSummary = ({ projectDetail }) => {
	return (
		<ContentStack>
			{/* Title Image */}
			<Image
				style={{ borderRadius: "8px" }}
				src={projectDetail.summary.titleImg}
				alt=""
				w={"100%"}
				h="fit-content"
			/>
			<Grid gutter={{ base: 24, md: 120 }} mt={48}>
				<Grid.Col
					span={{ base: 12, md: 6 }}
					pt={0}
					pb={0}
					style={{ borderStyle: "solid", borderWidth: "0 0.5px 0 0" }}
				>
					{/* Top Summary */}
					{/* Title & Tag */}
					<Box mb={64}>
						<TitleInfo
							title={projectDetail.summary.title}
							list={projectDetail.summary.tags}
						/>
					</Box>
					<Stack gap={24}>
						{/* Industry */}
						<ListInfo
							title={"Industry"}
							list={projectDetail.summary.industry}
						/>
						{/* Outcome */}
						<ListInfo
							title={"Outcomes"}
							list={projectDetail.summary.outcomes}
						/>
						{/* Tasks */}
						<ListInfo title={"Tasks"} list={projectDetail.summary.tasks} />
					</Stack>
				</Grid.Col>
				<Grid.Col span={{ base: 12, md: 6 }} pt={0} pb={0}>
					<Title order={2} mb={48}>
						{projectDetail.summary.summaryTitle}
					</Title>
					<Text size={"xl"} mb={80}>
						{projectDetail.summary.summaryExp}
					</Text>
					{projectDetail.summary.link && (
						<Button
							size={"xl"}
							variant="outline"
							color={"contrast"}
							radius={"xl"}
							fullWidth
							rightSection={<IconArrowNarrowRight size={24} />}
						>
							Link To Project
						</Button>
					)}
				</Grid.Col>
			</Grid>
		</ContentStack>
	);
};
