import { Transition } from "@mantine/core";

export const FadeIn = ({
	isTrigger,
	duration = 600,
	delay,
	children,
	timingFunction = "ease",
}) => {
	return (
		<div
			style={{opacity:isTrigger?1:0,transition: `all 0.4s ${timingFunction} ${delay}` }}
		>
			{children}
		</div>
	);
};
