import styled from "styled-components";
import { O, O2, U, U2, R, I, D, E, A, S } from "../../logoType/unit/UnitSet";
import { Flex } from "@mantine/core";

const LetterSpace = styled.div`
	width: ${(props) => props.space};
`;

export const OurIdeas = ({
	delay = 100,
	startDelay = 0,
	scale = 1 / 3,
	isReverse = false,
}) => {
	return (
		<Flex gap={24}>
			<Flex gap={3}>
				<O2
					delay={0}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<U
					delay={delay * 1}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<R
					delay={delay * 2}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
			</Flex>
			<Flex gap={3}>
				<I
					delay={delay * 3}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<LetterSpace space={"0px"} />
				<D
					delay={delay * 4}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<E
					delay={delay * 5}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<A
					delay={delay * 6}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
				<LetterSpace space={"0px"} />
				<S
					delay={delay * 7}
					startDelay={startDelay}
					scale={scale}
					isReverse={isReverse}
				/>
			</Flex>
		</Flex>
	);
};
